import { PrototypesAPI } from "../../../actions/PrototypesAPI";
import { IReportTestData } from "../../../models/Report";
import { isFigmaBlock, FigmaBlock, ITest } from "../../../models/Test";
import { getFormattedPrototype } from "../../../utils/figma";

const cache = new Map<string, any>();

export async function getFigmaBlockData(block: FigmaBlock) {
  const cacheKey = block.prototypeId || block.prototypeDataUrl;
  if (cache.has(cacheKey!)) {
    const cachedPrototype = cache.get(cacheKey!);
    return {
      ...block,
      ...cachedPrototype,
    };
  }

  try {
    const prototypeData = block?.prototypeId ? await PrototypesAPI.fetchPrototype(block?.prototypeId, {}) : undefined;
    const prototypeDataFormatted = prototypeData ? getFormattedPrototype(prototypeData) : undefined;
    const nativePrototypeData = block?.prototypeDataUrl ? await PrototypesAPI.loadFromUrl(block?.prototypeDataUrl) : undefined;
  
    const prototype = prototypeDataFormatted ?? nativePrototypeData;

    cache.set(cacheKey!, prototype);
    return {
      ...block,
      ...prototype,
    };
  } catch (error) {
    console.error("Error fetching Figma block data:", error);
    throw error; 
  }
}

export async function getTestFigmaBlockData(publishedContent: any[]) {
  const updatedContent = await Promise.all(publishedContent.map(async (block: any) => {
    if (!block || !isFigmaBlock(block)) return block;
    try {
      return await getFigmaBlockData(block);
    } catch (error) {
      console.error("Error processing block:", error);
      return block;
    }
  }));

  return updatedContent;
}
