import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

type SwitcherProps = {
  text?: string | JSX.Element;
  handler: (value: boolean) => void;
  caption?: string;
  isOn: boolean;
  disabled?: boolean;
  danger?: boolean;
  className?: string;
  large?: boolean;
  switcherOnTheRight?: boolean;
  icon?: JSX.Element | null | undefined;
};

const Switcher = ({ text, handler, caption = "", isOn, disabled = false, danger = false, className = "", large = false, switcherOnTheRight = false, icon = null }: SwitcherProps) => {
  function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    handler(!isOn);
  }

  return (
    <div className="switcher w-full">
      <div
        className={clsx(
          "switcher__wrapper flex items-start gap-2",
          className && className,
          switcherOnTheRight && "flex-row-reverse justify-between"
        )}
      >
        {icon && !switcherOnTheRight && <div className="switcher__icon">{icon}</div>}
        <div className="flex items-center h-5 switcher__body-wrapper">
        <div
          className={clsx(
            "switcher__body rounded-full flex transition-all duration-200 shrink-0 items-center",
            "w-[28px] h-[18px]",
            isOn ? (danger ? "bg-red-600" : "bg-blue-500") : "bg-gray-500",
            isOn && "justify-end",
            disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          )}
          onClick={onClick}
        >
          <div
            className={clsx(
              "switcher__dot rounded-full bg-white shrink-0 mx-[4px]",
              "w-[10px] h-[10px]",
              "transition-all duration-200"
            )}
          ></div>
        </div>
        </div>
        <div className="switcher__text-and-icon flex items-center space-x-3">
          {icon && <div className="switcher__icon">{icon}</div>}
          <div className="switcher__text flex flex-col gap-1">
            {text && (
              <div
                className={clsx(disabled ? "text-gray-500" : "text-gray-800", "cursor-pointer text-base font-medium")}
                onClick={onClick}
              >
                <div className="flex items-center">{text}</div>
              </div>
            )}
            {caption && <p className="switcher__label text-sm text-gray-600">{caption}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Switcher;
