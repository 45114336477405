import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppContext from "../../../../AppContext";

import Loader from "../../../Loader";

import { UserActions } from "../../../../actions/userActions";

const OAuthFigma = () => {
	const { state, dispatch } = useContext(AppContext);
	const userId = state.user?.userId;
	const history = useHistory();

	const url = new URL(window.location.href);
	const figmaCode = url.searchParams.get('code');
	const figmaState = url.searchParams.get('state');

	// get app redirect url from localStorage
	const figmaConnectAppRedirectUrl = localStorage.getItem("figmaConnectAppRedirectUrl");

	const getAccessToken = async () => {
			const result = await UserActions.setFigmaAuth(userId, figmaCode, figmaState);
			if (result) {
				dispatch(result);
				window.location.href = `${figmaConnectAppRedirectUrl}&figmaConnected=true`;
			} else {
				window.location.href = `${figmaConnectAppRedirectUrl}&figmaConnected=false`;
			}
	}

	useEffect(() => {
		if (figmaCode && figmaState) {
			getAccessToken();
		} else {
			window.location.href = `${figmaConnectAppRedirectUrl}&figmaConnected=false`;
		}
	}, []);


	return (
		<Loader/>
	);

}

export default OAuthFigma;