import React, { useState, useEffect } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import { useEditor, EditorContent } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Editor } from "@tiptap/core";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'


export interface ISmartInputProps {
  id: string;
  placeholder?: string;
  initialValue?: string;
  onBlur?: (text: string, id: string) => any;
  onChange?: (text: string, id: string) => any;
  onFocus?: (text: string, id: string) => any;
  onPaste?: (text: string[], id: string) => any;
  className?: string;
  focusOnMount?: boolean;
  large?: boolean;
  small?: boolean;
  extralarge?: boolean;
  testId?: string;
}

const SmartInput = ({
  id,
  placeholder,
  initialValue,
  onBlur,
  onChange,
  onFocus,
  className,
  focusOnMount = false,
  large = false,
  small = false,
  extralarge = false,
  testId,
  ...props
}: ISmartInputProps) => {
  // focusOnMount doesn't work on iOS Safari because of the platform limitations

  const [value, setValue] = useState(initialValue ? initialValue : "");

  function onUpdate({ editor }: { editor: Editor }) {
    const text = editor.getText();
    if (onChange) {
      onChange(text, id);
    }
    setValue(text);
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder }),
      Table,
      TableRow,
      TableHeader,
      TableCell,
    ],
    editorProps: {
      attributes: {
        id: id,
        class: clsx(className && className, large && "text-xl", extralarge && "text-2xl", small && "text-base", "focus:outline-none"),
      },
      handlePaste(view, event, slice) {
        if (props.onPaste && editor) {
          const textNodes = getTextNodesFromPaste(slice);
          console.log(slice, textNodes, view, event, editor, this);

          if (textNodes.length === 1) return false;
          props.onPaste(textNodes, id);
          editor?.commands.clearContent();
          setTimeout(() => {
            view.dispatch(view.state.tr.insertText(textNodes[0]));
          }, 10);
          return true;
        }

        return false;
      },
    },
    content: value,
    onUpdate: onUpdate,
    onBlur({ editor }) {
      if (onBlur) {
        const text = editor.getText();
        onBlur(text, id);
      }
    },
    onFocus({ editor }) {
      if (onFocus) {
        const text = editor.getText();
        onFocus(text, id);
      }
    },
  });

  useEffect(() => {
    editor?.off("update");
    editor?.on("update", onUpdate);
  }, [onChange, onBlur, onFocus]);

  useEffect(() => {
    try {
      if (focusOnMount && editor) {
        console.log('focusing');
        editor.commands.focus('start');
      }
    } catch (error) {
      console.warn(error);
    }
  }, [editor]);

  return <EditorContent data-testid={testId} editor={editor} />;
};

export default SmartInput;

function getTextNodesFromPaste(slice: any) {
  const nodes: string[] = [];
  slice.content.descendants((node: any) => {
    if (node.isText && node.text?.trim()) {
      nodes.push(node.text.trim());
    }
  });
  return nodes;
}