import { createContext } from 'react';


export type MessageType = {
	type: string;
	[key: string]: any;
};

const FigmaContext = createContext<FigmaCTX | null>(null);
export default FigmaContext;


export type onMessageCallback = (message: MessageType) => void;
export class FigmaCTX {

	public isPrototypeLoaded: boolean = false;

	private onUploadCompleteCallback: ((recordId: string) => void) | null = null;
	private onUploadFailedCallback: ((recordId: string, error: string) => void) | null = null;
	private onKeydownCallback: ((params: { key: string }) => void) | null = null;
	private onGetRenderDataCallback: ((data: any) => void) | null = null;
	private onPrototypeLoadedCallbacks: (() => void)[] = [];
	private onPrototypeReadyCallbacks: (() => void)[] = [];
	private onScrollHandlersMap: Map<string, (eventData: any) => void> = new Map();
	private frameRequestsMap: Map<string, { resolve: (data: any) => void, reject: (error: any) => void, setTimeoutId: any }> = new Map();
	private lastPresentedNodeChangedEvent: any;

	public renderData: { nodesRenderData: Record<string, any>, elementsWithoutNearestScrolling: Record<string, any> } | null = null;

	public resetContext() {
		this.renderData = null;
		this.isPrototypeLoaded = false;
		this.lastPresentedNodeChangedEvent = null;
	}

	constructor(public sendMessageHandler?: onMessageCallback | null) {
	}

	public setCallback(callback: onMessageCallback) {
		this.sendMessageHandler = callback;
	}

	public removeCallback() {
		this.sendMessageHandler = null;
	}

	public postMessage(message: MessageType) {
		if (this.sendMessageHandler) {
			this.sendMessageHandler(message);
		}
	}

	public handleEvent(event: MessageEvent) {
		const { type, data: eventData } = event.data;
		if (type === "PRESENTED_NODE_CHANGED") {
			this.triggerOnScreenChanged(eventData);
		}

		if (type === "UPLOAD_COMPLETE") {
			console.log("UPLOAD_COMPLETE", eventData);
			this.recordUploadComplete(eventData.recordId);
		}
		if (type === "UPLOAD_FAILED") {
			this.recordUploadFailed(eventData.recordId, eventData.error);
		}

		if (type === "PW_READY") {
			this.triggerOnPrototypeReady();
		}

		if (type === "PW_SCROLL") {
			this.scroll(eventData);
		}

		if (type === "PW_GET_NODE_RESPONSE") {
			this.onGetNodeResponse(eventData);
		}

		if (type === "PW_KEYDOWN") {
			this.onKeydown(eventData);
		}

		if (type === "PW_GET_RENDER_DATA_RESPONSE") {
			this.onGetRenderData(eventData);
		}
	}

	public reInit() {
		if (this.lastPresentedNodeChangedEvent) {
			this.sendMessageHandler?.({
				type: "PRESENTED_NODE_CHANGED",
				data: this.lastPresentedNodeChangedEvent
			});
		}
	}

	public navigateToNode(nodeId: string) {
		this.sendMessageHandler?.({
			type: "NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS",
			data: { nodeId }
		});
	}

	public startRecording(recordingId: string) {
		this.sendMessageHandler?.({
			type: "START_RECORD",
			data: { recordingId }
		});
	}

	public stopRecording() {
		this.sendMessageHandler?.({
			type: "STOP_RECORD",
			data: {}
		});
	}

	public onRecordUploadComplete(callback: (recordId: string) => void) {
		this.onUploadCompleteCallback = callback;
	}

	public onRecordUploadFailed(callback: (recordId: string, error: string) => void) {
		this.onUploadFailedCallback = callback;
	}

	public recordUploadComplete(recordId: string) {
		this.onUploadCompleteCallback?.(recordId);
	}

	public recordUploadFailed(recordId: string, error: string) {
		this.onUploadFailedCallback?.(recordId, error);
	}

	public onScroll(nodeId: string, callback: (eventData: any) => void) {
		this.onScrollHandlersMap.set(nodeId, callback);
	}

	public scroll(eventData: any) {
		const callback = this.onScrollHandlersMap.get(eventData.nodeId);
		if (callback) callback(eventData);
	}

	public getNodePosition(nodeId: string) {
		const promise = new Promise<{ x: number, y: number, width: number, height: number }>((resolve, reject) => {
			const requestId = Math.random().toString();

			this.sendMessageHandler?.({
				type: "PW_GET_NODE_REQUEST",
				requestId,
				nodeId
			});

			const setTimeoutId = setTimeout(() => {
				this.frameRequestsMap.delete(requestId);
				reject("timeout");
			}, 10000);

			this.frameRequestsMap.set(requestId, { resolve, reject, setTimeoutId });
		});
		return promise;
	}

	public onGetNodeResponse(eventData: { nodeId: string, requestId: string, data: { x: number, y: number, width: number, height: number } }) {
		const request = this.frameRequestsMap.get(eventData.requestId);
		if (!request) return;
		this.frameRequestsMap.delete(eventData.requestId);
		clearTimeout(request.setTimeoutId);
		request.resolve(eventData.data);
	}

	public getRenderData(clickedNodes: Set<string>, clicksWithoutScrolling: Set<string>) {
		this.sendMessageHandler?.({
			type: "PW_GET_RENDER_DATA_REQUEST",
			data: {
				clickedNodes,
				clicksWithoutScrolling
			}
		});
	}

	public setOnGetRenderDataCallback(callback: any) {
		this.onGetRenderDataCallback = callback;
	}

	public onGetRenderData(data: any) {
		this.renderData = data;
		this.onGetRenderDataCallback?.(data);
	}

	public onKeydown(params: { key: string }) {
		this.onKeydownCallback?.(params);
	}

	public listenToKeydown(fn: (params: { key: string }) => void) {
		this.onKeydownCallback = fn;
	}

	public triggerOnScreenChanged(e: any) {
		this.lastPresentedNodeChangedEvent = e;
		if (!this.isPrototypeLoaded) {
			this.isPrototypeLoaded = true;
			this.onPrototypeLoadedCallbacks.forEach(callback => callback());
		}
		else {
			// console.log('Not calling callbacks for loading');
		}
	}

	public onPrototypeLoaded(callback: () => void) {
		this.onPrototypeLoadedCallbacks.push(callback);
	}

	public offPrototypeLoaded(callback: () => void) {
		this.onPrototypeLoadedCallbacks = this.onPrototypeLoadedCallbacks.filter(cb => cb !== callback);
	}

	public triggerOnPrototypeReady() {
		// this.isPrototypeLoaded = true;
		this.onPrototypeReadyCallbacks.forEach(callback => callback());
	}

	public onPrototypeReady(callback: () => void) {
		this.onPrototypeReadyCallbacks.push(callback);
	}

	public offPrototypeReady(callback: () => void) {
		this.onPrototypeReadyCallbacks = this.onPrototypeReadyCallbacks.filter(cb => cb !== callback);
	}
}