import { useRef, useState, useEffect } from "react";

import { IArea } from "../../models";

export default function useDrawingArea(scaleRatio: number, respondentsTotal: number, initialAreas: IArea[] = []){
	const clickAreaRef = useRef<HTMLDivElement>(null);
  const [drawnAreas, setDrawnAreas] = useState<IArea[]>(initialAreas);
	const reverseScale = 1 / scaleRatio;
	const drawingAreaRef = useRef<any>({ drawingDiv: null, left: 0, top: 0 });

	useEffect(()=>{
		if (drawnAreas.length){
			drawInitialArea(drawnAreas);
		}
	},[]);

function drawInitialArea(areas: IArea[]){
	areas.forEach((area) =>{
		if (!clickAreaRef.current) return;

		const drawingArea = clickAreaRef.current;
		const drawingDiv = createDrawingDiv();

		drawingDiv.style.left = area.left + 'px';
		drawingDiv.style.top = area.top + 'px';

		drawingAreaRef.current.drawingDiv = drawingArea.appendChild(drawingDiv);
		drawingAreaRef.current.left = area.left;
		drawingAreaRef.current.top = area.top;

		drawingDiv.style.width = area.width + 'px';
		drawingDiv.style.height = area.height + 'px';

		drawingDiv.remove();
		drawingAreaRef.current.drawingDiv = null;
	})
}

	useEffect(() => {
		// attach pointer down, pointer up, pointer move events to clickAreaRef
		if (!clickAreaRef.current) return;
		/** Вся область для рисования клик-ареа */
		const drawingArea = clickAreaRef.current;

		const pointerDown = (e: PointerEvent) => onPointerDown(e, drawingArea);
		const pointerMove = (e: PointerEvent) => onPointerMove(e, drawingArea);
		const pointerUp = (e: PointerEvent) => onPointerUp(e, drawingArea);

		drawingArea.addEventListener('pointerdown', pointerDown);
		drawingArea.addEventListener('pointermove', pointerMove);
		drawingArea.addEventListener('pointerup', pointerUp);

		return () => {
			drawingArea.removeEventListener('pointerdown', pointerDown);
			drawingArea.removeEventListener('pointermove', pointerMove);
			drawingArea.removeEventListener('pointerup', pointerUp);
		};
	}, [clickAreaRef.current, respondentsTotal, reverseScale]);

	function onPointerUp(e: PointerEvent, drawingArea: HTMLDivElement) {
		// console.log('onPointerUp', e,);

		const drawingDiv = drawingAreaRef.current.drawingDiv;
		if (!drawingDiv) return;

		const drawingAreaRect = drawingArea.getBoundingClientRect();

		const xInsideDrawingArea = e.clientX - drawingAreaRect.x;
		const yInsideDrawingArea = e.clientY - drawingAreaRect.y;
		// current.left and current.top are relative to drawingArea and it is a starting point of drawingDiv
		const width = s(xInsideDrawingArea - drawingAreaRef.current.left);
		const height = s(yInsideDrawingArea - drawingAreaRef.current.top);

		// if width or height is too small, remove drawingDiv
		if (Math.abs(width) < 10 || Math.abs(height) < 10) {
			drawingAreaRef.current.drawingDiv = null;
			drawingDiv.remove();
			return console.log('width or height is too small');
		}

		if (width < 0) {
			drawingDiv.style.left = s(xInsideDrawingArea) + 'px';
		}
		if (height < 0) {
			drawingDiv.style.top = s(yInsideDrawingArea) + 'px';
		}

		drawingDiv.style.width = width + 'px';
		drawingDiv.style.height = height + 'px';

		const newArea: IArea = {
			id: Date.now(),
			// если width или height отрицательные, значит клик-ареа рисовалось справа или снизу влево или вверх (то есть наоборот)
			// тогда начальные координаты заменяем на координаты, когда мышку отпустили
			left: s(width < 0 ? xInsideDrawingArea : drawingAreaRef.current.left),
			top: s(height < 0 ? yInsideDrawingArea : drawingAreaRef.current.top),
			width: Math.abs(width),
			height: Math.abs(height),
			absoluteLeft: s((width < 0 ? xInsideDrawingArea : drawingAreaRef.current.left) + drawingAreaRect.x),
			absoluteTop: s((height < 0 ? yInsideDrawingArea : drawingAreaRef.current.top) + drawingAreaRect.y),

			clicksCount: 0,
			missedClicksCount: 0,
			respondentsCount: 0,
			respondentsPercent: 0,
		};

		setDrawnAreas((current) => ([...current, newArea]));
		drawingDiv.remove();
		drawingAreaRef.current.drawingDiv = null;
	}

  function onPointerDown(e: PointerEvent, drawingArea: HTMLDivElement) {
    // ignore right click
    if (e.button !== 0) return;
    if (drawingAreaRef.current.drawingDiv) return;

    const parentContainer = drawingArea.parentElement;
    if (!parentContainer) return;

    // Учитываем смещение прокрутки родительского контейнера
    const parentBoundingRect = parentContainer.getBoundingClientRect();
    const drawingDiv = createDrawingDiv();

    // Расчет позиции с учетом смещения родительского контейнера
    const left = e.clientX - parentBoundingRect.left;
    const top = e.clientY - parentBoundingRect.top;

    drawingDiv.style.left = s(left) + "px";
    drawingDiv.style.top = s(top) + "px";

    drawingAreaRef.current.drawingDiv = drawingArea.appendChild(drawingDiv);
    drawingAreaRef.current.left = left;
    drawingAreaRef.current.top = top;
  }

	function onPointerMove(e: PointerEvent, drawingArea: HTMLDivElement) {
		const drawingDiv = drawingAreaRef.current.drawingDiv;
		if (!drawingDiv) return;
		// console.log('onPointerMove', e);
		const boundingRect = drawingArea.getBoundingClientRect();
		const width = e.clientX - boundingRect.x - drawingAreaRef.current.left;
		const height = e.clientY - boundingRect.y - drawingAreaRef.current.top;
		if (width < 0) {
			drawingDiv.style.left = s(e.clientX - boundingRect.x) + 'px';
			// drawingAreaRef.current.left = e.clientX - boundingRect.x;
		}
		if (height < 0) {
			drawingDiv.style.top = s(e.clientY - boundingRect.y) + 'px';
			// drawingAreaRef.current.top = e.clientY - boundingRect.y;
		}
		drawingDiv.style.width = s(Math.abs(width)) + 'px';
		drawingDiv.style.height = s(Math.abs(height)) + 'px';
	}

	function removeArea(areaToRemove: IArea) {
		setDrawnAreas((current) => current.filter((area) => area.id !== areaToRemove.id));
	}

  function createDrawingDiv() {
		const drawingDiv = document.createElement('div');
		drawingDiv.style.position = 'absolute';
		drawingDiv.style.border = '2px dashed #0066FF';
		drawingDiv.style.pointerEvents = 'none';
		drawingDiv.style.borderRadius = '8px';
		drawingDiv.style.backgroundColor = 'rgba(82, 151, 255, 0.25)';
		return drawingDiv;
	}

  	/** Скейлит число, чтобы получить исходный (реальный размер) до масштабирования прототипа */
	function s(value: number) {
		return value * reverseScale;
	}

  return {
    drawnAreas,
		setDrawnAreas,
		clickAreaRef,
		removeArea,
  }
}