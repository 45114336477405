import React, { useState } from "react";

import "../../tailwind.generated.css";

import { IconZoom } from "../../icons";
import VideoPlayer from "../VideoPlayer";
import Modal from "../Modal";
import RenderIfVisible from "react-render-if-visible";
import clsx from "clsx";


type ZoomButtonProps = {
  setIsZoomModalOpen: (isOpen: boolean) => void;
};

const ZoomButton = ({ setIsZoomModalOpen }: ZoomButtonProps) => {
  return (
    <div className="absolute w-6 h-6 rounded-full bg-black opacity-75 z-50 left-[5%] top-[5%] hover:scale-105">
      <IconZoom className="w-4 h-4 m-1 text-white cursor-pointer fill-current" onClick={() => setIsZoomModalOpen(true)} />
    </div>
  );
};

type VideoWithZoomProps = {
  videoUrl: string | undefined;
  className?: string;
  withZoomIcon?: boolean;
  stylesWrapper?: string;
};

export default function VideoWithZoom(props: VideoWithZoomProps) {
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  if (!props.videoUrl) return null;

  return (
    <>
      <div className="video-with-zoom__container__preview relative cursor-zoom-in" onClick={() => { setIsZoomModalOpen(true) }}>
        {props.withZoomIcon && <ZoomButton setIsZoomModalOpen={setIsZoomModalOpen} />}
        <div className={`${props.stylesWrapper} overflow-hidden`}>
          <RenderIfVisible key={props.videoUrl} stayRendered={true} >
            <video
              className={clsx(props.className, "w-full object-cover")}
              src={props.videoUrl}
              autoPlay
              muted
              loop />
          </RenderIfVisible>
        </div>
      </div>
      {isZoomModalOpen && (
        //@ts-ignore
        <Modal isOpen={isZoomModalOpen} setIsOpen={setIsZoomModalOpen} background={false} noHeightLimit noRoundedCorners style={{ overflow: "hidden" }}>
          <div className="overflow-auto hideScrollbar">
            <VideoPlayer videoUrl={props.videoUrl} showControls={true} style={{ maxWidth: "calc(100vw - 8rem)", maxHeight: "100vh" }} className="max-w-[calc(100vw-8rem)] max-h-[100vh] w-full h-auto" />
          </div>
        </Modal>
      )}
    </>
  );
};
