import { Block, BlockType, blockHasText } from "../../models/Test";
import { blockTypeMap } from "../Common";

import i18n from "../../i18n/config";

export function getBlockName(block: Block) {
if ("text" in block && block.text.length > 0) {
    if (block.text.length > 200) {
      return block.text.slice(0, 200) + "...";
    } else {
      return block.text;
    }
  } else {
    return i18n.t(blockTypeMap[block.type].name);
  }
}
