import { useState, useEffect } from "react";

export function useViewportUnits(): 'dvh' | 'vh' {
  const [viewportUnit, setViewportUnit] = useState<'dvh' | 'vh'>('vh');

  useEffect(() => {
    function getViewportUnits(): 'dvh' | 'vh' {
      if (typeof window !== 'undefined' && 'CSS' in window && CSS.supports) {
        return CSS.supports('height', '100dvh') ? 'dvh' : 'vh';
      }
      return 'vh'; // Fallback для SSR или старых браузеров
    }

    setViewportUnit(getViewportUnits());
  }, []);

  return viewportUnit;
}