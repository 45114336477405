import { generateId } from '../../utils';
import { ConditionType } from './ConditionType';
import { Operator } from './Operator';

export const EMPTY_VALUE = "";

export const FINISH_BLOCK_ID = "thankyouscreen";
export const NEXT_QUESTION_BLOCK_ID = "nextquestion";

export class LogicCondition {
	public id: string = generateId();
	public operator: Operator = Operator.and;
	public condition: ConditionType = ConditionType.contains;
	public value: string = EMPTY_VALUE;

	constructor(
		public valueSource?: string,
	) { }
};

export class LogicStatement {
	public id: string = generateId();
	public subconditions: LogicCondition[] = [];
	public visibilityType?: VisibilityLogicType;
	constructor(
		public jumpTo: string = NEXT_QUESTION_BLOCK_ID,
		subconditions: LogicCondition[]
	) { 
		this.subconditions = subconditions;
	}
};

export type Logic = {
	statements: LogicStatement[];
	visibility?: LogicStatement[];
	elseJumpTo: string;
	// Legacy
	/** @deprecated */
	conditions?: LogicCondition[];
	/** @deprecated */
	jumpTo?: string;
};

export enum VisibilityLogicType {
	show = 'show',
	hide = 'hide',
}