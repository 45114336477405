import { IMessageTag } from '../../Common/schema/Chats/UpdateChatMessageDTO';
import { generateId } from '../../utils';

export type ImageAddon = {
    type: "image";
    url: string;
}

export type ChoiceAddon = {
    type: "choice";
    choices: string[];
}

export type ButtonAddon = {
    type: "button";
    text: string;
    onClick: () => void;
}

export interface ChatMessage {
    id: number;
    text: string;
    isUser: boolean;
    avatarUrl: string | null;
    addons: Array<ImageAddon | ChoiceAddon | ButtonAddon> | null;
    createdAt?: Date;
}

export class BaseAiChatMessage implements ChatMessage {
    public id: number;
    public tags?: IMessageTag[] | null = null;
    public createdAt?: Date;

    constructor(
        public text: string,
        public addons: Array<ImageAddon | ChoiceAddon | ButtonAddon> | null,
        public isUser: boolean,
        public avatarUrl: string | null,
    ) {
        this.id = Math.random(); // generateId();
    }
}

export class AssistantAiChatMessage extends BaseAiChatMessage {
    constructor(text: string, addons: Array<ImageAddon | ChoiceAddon | ButtonAddon> | null = null, avatarUrl: string | null = null) {
        super(text, addons, false, avatarUrl);
    }
}

export class UserAiChatMessage extends BaseAiChatMessage {
    constructor(text: string, avatarUrl: string | null = null) {
        super(text, null, true, avatarUrl);
    }
}