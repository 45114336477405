import { IRecordEvent } from '../types/events';
import { EventsReplayer } from './EventsReplayer';
import { FigmaVideoPlayer } from './FigmaVideoPlayer';

export class FigmaFlowReplayer {

	constructor(
		private videoEl: HTMLVideoElement,
		private pointerEl: HTMLElement,
		private videoOptions: {
			pixelRatio: number;
			isMobile: boolean;
		}
	) {
	}

	public async replay(eventBundles: IRecordEvent[][], indentX: number, indentY: number, startTimestamp: number, endTimestamp: number, videoUrl: string) {
		const events = eventBundles.flat().slice();
		// events.forEach(click => {
		// 	click.x += indentX;
		// 	click.y += indentY;
		// });

		// const pointer = document.getElementById('pointer');
		// const video = document.getElementById('replayVideo') as HTMLVideoElement;
		const figmaVideoPlayer = new FigmaVideoPlayer(this.videoEl, videoUrl, this.videoOptions.pixelRatio);
		const eventsReplayer = new EventsReplayer(events, startTimestamp, endTimestamp, this.pointerEl, this.videoOptions.isMobile, indentX, indentY);

		figmaVideoPlayer.video.onseeked = function (e) {
			const v = e.target as HTMLVideoElement;
			console.log('seeked', e, v.currentTime);
			eventsReplayer.seek(v.currentTime);
		};

		figmaVideoPlayer.video.onplaying = function () {
			eventsReplayer.play();
			console.log('playing');
		};

		figmaVideoPlayer.video.onpause = function () {
			eventsReplayer.pause();
			console.log('pause');
		};
	}

}
