import React, { useRef, useEffect } from "react";
import clsx from 'clsx';

import { interfaceText, locale } from '../../../../../../helpers';

type AIOpenQuestionInputProps = {
  disabled?: boolean;
  onChange?: (text: string) => void;
};

export default function AIOpenQuestionInput(props: AIOpenQuestionInputProps) {

  const chatInputRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (chatInputRef.current) {
      chatInputRef.current.style.height = 'inherit'; // Reset height to recalculate
      chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <div className={clsx("chat-input__wrapper", {
      "opacity-50": props.disabled,
    })}>
      <div className="flex items-start justify-between w-full gap-4">
        <textarea
          className={clsx("text-lg overflow-hidden rounded-none transition-all duration-700 ease-in-out w-full flex-1 placeholder:text-gray-400", {
          })}
          ref={chatInputRef}
          draggable="false"
          id="chat-input"
          maxLength={1000}
          placeholder={props.disabled ? interfaceText.recording[locale() as keyof typeof interfaceText] : interfaceText.typeMessage[locale() as keyof typeof interfaceText]}
          onChange={(e) => {
            props.onChange?.(e.target.value);
            adjustHeight(); // Adjust height on input change
          }}
          rows={1}
          disabled={props.disabled}
        />
      </div>
    </div>

  );
};