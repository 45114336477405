import React from 'react';
import { WithScroll } from '../WithScroll';
import { bgNoRepeat, FigmaDisplayMode, getChildren, PX } from './NodeInHtmlUtils';
import { INodeViewProps, NodeView } from './NodeView';

export function NodeWithScroll(props: INodeViewProps) {
	const overflowParams = props.node.overflowParams;

	const c = getChildren(props.node);

	return <div style={{ ...props.nodeProps.style, zIndex: props.nodeProps.style.zIndex + 1 }} className="node-view__el node-view__scroll-container">

		{!!props.fixedChildren && !!props.node.numberOfFixedChildren && props.fixedChildren.map((child: any) => <NodeView
			key={child.id}
			display={FigmaDisplayMode.actualSize}
			parent={props.node}
			onClick={props.onClick}
			imageScale={props.imageScale}
			node={child}
			fixed
			horizontalCompressionCoef={props.horizontalCompressionCoef}
			showDefaultCursor={props.showDefaultCursor}
		/>
		)}

		{/* {!!c.fixedChildren && !!node.numberOfFixedChildren && c.fixedChildren.map((child: any) => <NodeInHtml
			key={child.id}
			display={FigmaDisplayMode.actualSize}
			parent={node}
			onClick={onClick}
			imageScale={imageScale}
			node={child}
			fixed />
		)} */}

		<WithScroll
			data-id={props.node.id}
			data-name={props.node.name}
			data-has-scroll
			initialParams={{
				scrollLeft: overflowParams.scrollLeft * props.imageScale,
				scrollTop: overflowParams.scrollTop * props.imageScale,
			}}
		>
			<div
				onClick={props.nodeProps.onClick}
				className="node-view__scrollable-content-wrapper"
				style={{
					position: "relative",
					background: bgNoRepeat(overflowParams.image),
					height: PX(overflowParams.height * props.imageScale),
					width: PX(overflowParams.width * props.imageScale),
					overflow: "hidden",
				}}
			>
				{props.children}
			</div>
		</WithScroll>
	</div>;
}