import React, { useState } from "react";
import PropTypes from "prop-types";
import Preview from "./Preview";
import Tabs, { TabItem } from "../../Tabs";
import Button from "../../Button";
import Modal from "../../Modal";
import { PrototypeType } from "./FigmaReport";

import { IconPlay, IconTime, IconTick, IconClick } from "../../../icons";
import { IReportState, IReportStateByTester } from './FigmaReport';
import { IFormattedPrototype } from '../../../utils/figma';
import { useTranslation } from "react-i18next";
import NativePrototypeVideoPlayer from './NativePrototypeVideoPlayer';
import FigmaVideoPlayer from './FigmaVideoPlayer';
import { CanvasSize } from '../../../models/Response';

export interface IFigmaReportViewProps {
  blockId: string;
  byScreen: IReportState["byScreen"];
  byTester: IReportState["byTester"];
  onPreviewClick: (path: (string | number)[]) => void;
  prototype: IFormattedPrototype;
  sharingToken?: string;
  isSummaryReport: boolean;
}

export default function FigmaPrototypeScreensReport(props: IFigmaReportViewProps) {
  const { t } = useTranslation();
  return (
    <div className='figma-screens-report flex flex-col gap-3'>
      {props.isSummaryReport && <div className="flex flex-col gap-1">
        <div className="flex gap-2">
          <div className="flex flex-col gap-1">
            <div className="text-gray-800 text-base+ font-medium">{t("Heatmaps and clicks")}</div>
            <span className="text-gray-600 text-sm block">{t("See heatmaps and clicks for each screen or inidividual responses")}</span>
          </div>
        </div>
      </div>}
      {props.isSummaryReport ? (
        <Tabs>
          <TabItem label={t("By Screen")}>
            <FigmaReportByScreen
              byScreen={props.byScreen}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype} />
          </TabItem>
          <TabItem label={t("By Tester")}>
            <FigmaReportByTester
              blockId={props.blockId}
              byTester={props.byTester}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype}
              sharingToken={props.sharingToken}
            />
          </TabItem>
        </Tabs>
      ) : (
        <TabItem label={t("By Tester")}>
          <FigmaReportByTester
            blockId={props.blockId}
            byTester={props.byTester}
            onPreviewClick={props.onPreviewClick}
            prototype={props.prototype}
            sharingToken={props.sharingToken}
          />
        </TabItem>
      )}
    </div>
  );
}

function FigmaReportByTester(props: Omit<IFigmaReportViewProps, 'byScreen' | 'isSummaryReport'>) {

  return (
    <div className='figma-bytester-list'>
      {props.byTester.map((tester, index) => (
        <FigmaIndividualReport
          key={`FigmaIndividualReport-${index}`}
          blockId={props.blockId}
          tester={tester}
          onPreviewClick={props.onPreviewClick}
          prototype={props.prototype}
          pixelRatio={tester.devicePixelRatio || 1}
          size={tester.size}
          userAgent={tester.userAgent}
          prototypeType={tester.prototypeType}
          sharingToken={props.sharingToken}
        />
      ))}
    </div>
  );
}

function FigmaReportByScreen(props: Pick<IFigmaReportViewProps, 'byScreen' | 'onPreviewClick' | 'prototype'>) {
  return (
    <div className="flex flex-wrap mt-8 gap-4">
      {props.byScreen.screens.map((screen, index) => (
        <Preview
          key={index}
          prototype={props.prototype}
          screen={screen}
          width={96}
          onClick={() => props.onPreviewClick(screen.path)}
        />
      ))}
    </div>
  );
}

function FigmaIndividualReport(props:
  Pick<IFigmaReportViewProps, 'blockId' | 'onPreviewClick' | 'prototype' | 'sharingToken'> & {
    tester: IReportStateByTester,
    pixelRatio: number,
    size?: CanvasSize,
    prototypeType: PrototypeType,
    userAgent: string | undefined,
  }
) {
  const { t } = useTranslation();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  return (
    <div className="figma-bytester-item flex justify-items-stretch my-6" key={props.tester.answerId}>
      <div className='figma-bytester-item__stats whitespace-nowrap w-fit-content'>

        <div className={`text-gray-700 text-base`}>
          {props.tester.givenUp && <span className="figma-bytester-item__result text-orange-600">{t("Gave up")}</span>}
          {!props.tester.givenUp && <span className="figma-bytester-item__result text-green-700 flex items-center">
            <IconTick className="fill-current mr-1" width={16} height={16} />
            {t("Succeed")}
          </span>}

          <span className="figma-bytester-item__time text-gray-800 flex items-center mt-1">
            <IconTime className="mr-1" width={16} height={16} />
            {props.tester.time}
          </span>
        </div>

        {props.tester.withVideo && (
          <>
            <Button
              small
              className="mt-3"
              name={t("Watch video")}
              type="secondary"
              handler={() => setIsVideoModalOpen(true)}
              icon={<IconPlay width={24} height={24} className="mr-1" />} />
            <Modal width="95vw" height="90vh" isOpen={isVideoModalOpen} setIsOpen={setIsVideoModalOpen} background>
              {props.prototypeType === PrototypeType.Native && <NativePrototypeVideoPlayer size={props.size} recordingId={`${props.tester.answerId}/${props.blockId}`} sharingToken={props.sharingToken} pixelRatio={props.pixelRatio} userAgent={props.userAgent} />}
              {props.prototypeType === PrototypeType.Html && <FigmaVideoPlayer blockId={props.blockId} answerId={props.tester.answerId} />}
            </Modal>
          </>
        )}
      </div>

      <div className="figma-bytester-item__screens ml-6">
        <div className="screens-preview flex flex-wrap">
          {props.tester.screens.map((screen, index) => (
            <div className="screens-preview__item flex flex-nowrap mr-4 mb-4" key={index}>
              <Preview
                prototype={props.prototype}
                screen={screen}
                width={72}
                onClick={() => props.onPreviewClick(screen.path)}
              />

              {index < props.tester.screens.length - 1 && (
                <div className="screens-preview__delimiter flex items-center justify-center font-medium">
                  <span>→</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}



FigmaPrototypeScreensReport.propTypes = {
  blockId: PropTypes.string,
  byScreen: PropTypes.shape({
    screens: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        path: PropTypes.array,
        nextScreenPath: PropTypes.array,
        prevScreenPath: PropTypes.array,
        clicks: PropTypes.array,
        stats: PropTypes.array,
      })
    ),
  }),
  byTester: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.string,
      givenUp: PropTypes.bool,
      time: PropTypes.string,
      number: PropTypes.number,
      screens: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: PropTypes.string,
          path: PropTypes.array,
          nextScreenPath: PropTypes.array,
          prevScreenPath: PropTypes.array,
          clicks: PropTypes.array,
          stats: PropTypes.array,
        })
      ),
    })
  ),
  onPreviewClick: PropTypes.func,
};