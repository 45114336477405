export enum RecordEventType {
	pointerdown = 0,
	pointermove = 1,
	pointerup = 2,
}

export interface IRecordEvent {
	_type: RecordEventType;
	cX: number;
	cY: number;
	x: number;
	y: number;
	type: RecordEventType;
	pType: 'mouse' | 'pen' | 'touch';
	ts: number;
	pts?: number;
}

export interface IReplayEvent extends IRecordEvent {
	delay: number;
}