import React from 'react';
import VideoPlayer from '../../../../VideoPlayer';
import ImageWithZoom from '../../../../ImageWithZoom';

interface IMediaViewerProps {
  videoUrl?: string;
  imageUrl?: string;
  onLoad?: () => void;
  className?: string;
  style?: React.CSSProperties;
  videoSettings?: {
    isControlsVisible?: boolean;
    autoplay?: boolean;
    loop?: boolean;
  }
}

export default function MediaViewer(props: IMediaViewerProps) {

  if (props.imageUrl) {
    return <ImageWithZoom
      imageSrc={props.imageUrl}
      className={props.className}
      style={props.style}
      onLoad={props.onLoad}
    />
  }

  if (props.videoUrl) {
    return <VideoPlayer
      videoUrl={props.videoUrl}
      className={props.className}
      style={props.style}
      showControls={props.videoSettings?.isControlsVisible}
      autoplay={props.videoSettings?.autoplay}
      loop={props.videoSettings?.loop}
      onLoad={props.onLoad}
    />
  }

  console.warn('No media to display');
  return null;
}
