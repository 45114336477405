import i18n from "../../../i18n/config";
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { makeRequest } from "../../../http/Client";
import { useEffect, useRef } from "react";

export enum ExportFormat {
	CSV = 'csv',
	XLSX = 'xlsx'
}

const useReportExport = (testId: string, sharingToken: string | undefined, callbacks: { 
  onSuccess: (message: string) => void, 
  onError: (message: string) => void 
}) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortExport();
    };
  }, []);

  const abortExport = () => {
    abortControllerRef.current?.abort();
  }

  const exportReport = async (format: ExportFormat) => {
    try {
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const endpoint = `/api/v1/report/${testId}/export?sharingToken=${sharingToken}&format=${format}`;
      const response = await makeRequest(endpoint, 'GET', undefined, signal);

      if (!response.ok) {
        throw new Error(i18n.t('Something went wrong. Please try again or contact support.'));
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      const contentDisposition = response.headers.get('Content-Disposition');

      let fileName = `report.${format}`;
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename\*=UTF-8''([^;\n]*)/);
        if (matches && matches[1]) {
          fileName = decodeURIComponent(matches[1]);
        }
      }

      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return i18n.t('Download started. Check your downloads folder for the file.');
    } catch (error: any) {
      if (error?.name === 'AbortError') console.log('Request was aborted');
      console.error(error);
      throw new Error(i18n.t('Something went wrong. Please try again or contact support.'));
    }
  };

  const mutations: { [key in ExportFormat]: UseMutationResult<string, unknown, ExportFormat> } = {
    [ExportFormat.CSV]: useMutation<string, unknown, ExportFormat>({ mutationFn: () => exportReport(ExportFormat.CSV) }),
    [ExportFormat.XLSX]: useMutation<string, unknown, ExportFormat>({ mutationFn: () => exportReport(ExportFormat.XLSX) }),
  };

  const initiateDownload = (format: ExportFormat) => {
    mutations[format].mutate(format, {
      onSuccess: (message: string) => {
        callbacks.onSuccess(message);
      },
      onError: (error: any) => {
        console.log(error);
        callbacks.onError(i18n.t('Something went wrong. Please try again or contact support.'));
      },
    });
  };

  const isLoading = (format: ExportFormat) => mutations[format].isPending;

  return { isLoading, exportReport: initiateDownload, abortExport };
};

export default useReportExport;