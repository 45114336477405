import lodash from 'lodash';
import React, { useState } from 'react';
import { FigmaDisplayMode } from './NodeInHtmlUtils';
import { NodeView } from './NodeView';
import { PrototypeMouseEvent, ReactionHandlersMap, useReactions } from '../hooks/useReactions';

export interface IComponentNodeProps extends React.PropsWithChildren<any> {
  isScreen: boolean;
  node: any;
  nodeProps: any;
  imageScale: number;
  horizontalCompressionCoef: number;
  onClick: any;
  visible?: boolean;
  showDefaultCursor?: boolean;
}

export function ComponentNode(props: IComponentNodeProps) {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const [showHoverState, setShowHoverState] = useState<string | null>(null);
  const [showClickState, setShowClickState] = useState<string | null>(null);

  const isSingleClickChangeToNode = !!props.node.on_click_change_to?.id;
  const clickChangeToNodes = isSingleClickChangeToNode ? [props.node.on_click_change_to] : Object.entries(props.node.on_click_change_to || {}).map(([key, value]: [string, any]) => { value.key = key; return value; });
  const isSingleHoverChangeToNode = !!props.node.on_hover_change_to?.id;
  const hoverChangeToNodes = isSingleHoverChangeToNode ? [props.node.on_hover_change_to] : Object.entries(props.node.on_hover_change_to || {}).map(([key, value]: [string, any]) => { value.key = key; return value; });

  const nodeProps = props.nodeProps;

  const wrapperStyle: any = {
    width: nodeProps.style.width, //PX(Math.max(node.width, node.onHoverChangeTo.width) * imageScale),
    height: nodeProps.style.height, //PX(Math.max(node.height, node.onHoverChangeTo.height) * imageScale),
    left: nodeProps.style.left,
    top: nodeProps.style.top,
    right: nodeProps.style.right,
    bottom: nodeProps.style.bottom,
    position: 'absolute',
    overflow: 'visible'
  }

  const componentNodeStyle = lodash.cloneDeep(nodeProps.style);
  componentNodeStyle.left = "";
  componentNodeStyle.top = "";
  componentNodeStyle.right = "";
  componentNodeStyle.bottom = "";
  componentNodeStyle.zIndex = props.zIndex;
  // componentNodeStyle.visibility = !showClickState && !showHoverState && !!props.visible ? 'visible' : 'hidden';

  const handlerByTrigger: ReactionHandlersMap = {
    ON_CLICK: onReactionClick,
    ON_HOVER: onReactionHover
  }

  const reactionHandlers = useReactions(props.node, props.node.reactions, props.horizontalCompressionCoef, handlerByTrigger);

  function onReactionClick(e: PrototypeMouseEvent) {
    if (props.node.on_click_change_to) {
      const reactionData = (e.detail).reactionData;
      let destinationId = (reactionData?.handledReaction || reactionData?.reaction)?.action?.destinationId as string | null;
      if (!props.node.on_click_change_to[destinationId || '']) destinationId = null;
      if ((reactionData?.handledReaction || props.node.reaction_context_id?.includes(reactionData?.reaction?.context_id)) &&
        showClickState !== destinationId && reactionData?.status === 'ACTIVE') {
        reactionData.handledReaction = reactionData.defaultReaction;
        setTimeout(() => setShowClickState(destinationId), 10);
      }
      else if (props.node.reactions.ON_CLICK) {
        reactionData.reaction = props.node.reactions.ON_CLICK;
        reactionData.handledReaction = null;
      }
    }
  }

  function onReactionHover(e: React.MouseEvent) {

    if (props.node.on_hover_change_to) {
      const reactionData = (e.detail as any).reactionData;
      const destinationId = (reactionData?.handledReaction || reactionData?.reaction)?.action?.destinationId as string;
      if (reactionData.handledReaction && reactionData.status === 'ACTIVE')
        setShowHoverState(destinationId);
      else if (reactionData.handledReaction && reactionData.status === 'INACTIVE')
        setShowHoverState(null);
    }
  }

  return (
    <div className="node-component" style={wrapperStyle}>
      <div className="node-component__wrapper"
        id={`wrapper-${props.node.id}`}
        ref={wrapperRef}
        {...reactionHandlers}>
        <div
          data-id={props.node.id}
          data-name={props.node.name}
          className={'variant' + (!showClickState && !showHoverState && !!props.visible ? ' variant_visible' : ' variant_invisible')}
          onClick={nodeProps.onClick}
          onMouseEnter={nodeProps.onMouseEnter}
          onMouseLeave={nodeProps.onMouseLeave}
          style={componentNodeStyle}
        >
          {props.children}
        </div>

        {!!hoverChangeToNodes.length && hoverChangeToNodes.map(node => {
          const isVariantVisible = props.visible === true && ((isSingleHoverChangeToNode && !!showHoverState) || showHoverState === node.key);
          return (<div key={node.id} className={isVariantVisible ? 'variant variant_visible' : 'variant variant_invisible'}>
            <NodeView
              horizontalCompressionCoef={props.horizontalCompressionCoef}
              key={node.id}
              display={FigmaDisplayMode.actualSize}
              parent={props.node}
              imageScale={props.imageScale}
              node={node}
              isHover={false}
              isVariant={true}
              onClick={props.onClick}
              zIndex={props.zIndex + 10}
              onMouseLeave={nodeProps.onMouseLeave}
              visible={isVariantVisible}
              showDefaultCursor={props.showDefaultCursor}
            />
          </div>)
        })}

        {!!clickChangeToNodes.length && clickChangeToNodes.map((node: any) => {
          const isVariantVisible = props.visible === true && (isSingleClickChangeToNode && !!showClickState || showClickState === node.key);
          return (<div className={isVariantVisible ? 'variant variant_visible' : 'variant variant_invisible'} key={node.id}>
            <NodeView
              key={node.id}
              horizontalCompressionCoef={props.horizontalCompressionCoef}
              display={FigmaDisplayMode.actualSize}
              parent={props.node}
              imageScale={props.imageScale}
              node={node}
              isHover={false}
              isVariant={true}
              visible={isVariantVisible}
              onClick={props.onClick}
              zIndex={props.zIndex + 20}
              showDefaultCursor={props.showDefaultCursor}
            />
          </div>)
        })
        }
      </div>
    </div >
  )
}