// client/src/components/Report/FigmaReport/TooltipContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface TooltipData {
  x: number;
  y: number;
  content: React.ReactNode;
}

interface TooltipContextProps {
  tooltip: TooltipData | null;
  showTooltip: (data: TooltipData) => void;
  hideTooltip: () => void;
}

export const TooltipContext = createContext<TooltipContextProps>({
  tooltip: null,
  showTooltip: () => {},
  hideTooltip: () => {},
});

interface TooltipProviderProps {
  children: ReactNode;
}

export const TooltipProvider: React.FC<TooltipProviderProps> = ({ children }) => {
  const [tooltip, setTooltip] = useState<TooltipData | null>(null);

  const showTooltip = (data: TooltipData) => {
    setTooltip(data);
  };

  const hideTooltip = () => {
    setTooltip(null);
  };

  return (
    <TooltipContext.Provider value={{ tooltip, showTooltip, hideTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};