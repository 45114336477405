import React from "react";
import clsx from "clsx";

import ImageWithZoom from "../ImageWithZoom";
import VideoWithZoom from "./VideoWithZoom";

interface IReportHeader {
  questionText: string;
  className?: string;
  children?: React.ReactNode;
}

export default function ReportBlockHeader(props: IReportHeader) {
  return (
    <div className={clsx("flex justify-start items-center gap-4 my-2", props.className)}>
      {props.children}
      <div className="text-gray-700">{props.questionText}</div>
    </div>
  );
}

interface IReportMediaHeader {
  image?: string;
  video?: string;
}

export function ReportHeaderMedia(props: IReportMediaHeader) {

  if (props.image) {
    return <ImageWithZoom imageSrc={props.image} className="rounded-default w-8 h-8 object-cover" />
  }

  if (props.video) {
    return <VideoWithZoom videoUrl={props.video} className="rounded-default h-8" stylesWrapper="h-8 w-8" />
  }

  return <></>;
}