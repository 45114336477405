import React, { useContext, useEffect, useRef } from "react";
import NodeInHtml, { INodeInHtmlProps } from "./NodeInHtml";
import lodash from "lodash";
import { FigmaDisplayMode } from './NodeInHtml/NodeInHtmlUtils';
import { IFormattedPrototype } from '../../utils/figma';
import { IReportScreenState } from '../Report/FigmaReport/FigmaReport';

export interface IFigmaReportViewData {
  width: number;
  height: number;
  nodePositions: NodePositionsMap;
}

type NodePositionsMap = Record<string, { left: number, top: number }>;

export interface IFigmaHtmlReportViewPrototypeProps {
  prototype: IFormattedPrototype,
  screen: IReportScreenState;
  onLoad: (data: IFigmaReportViewData) => void;
  onResize?: INodeInHtmlProps['onResize'];
  className?: string;
}

export default function FigmaHtmlPrototypeReportView(props: IFigmaHtmlReportViewPrototypeProps) {
  const prototype = props.prototype;
  const imageScale = props.prototype.settings.imageScale;
  const mounted = useRef(false);
  const refs = useRef<{ root: HTMLDivElement | null; nodes: (HTMLDivElement | null)[]; }>({ root: null, nodes: [] });

  // state for loading images
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (!refs.current.root) return;

    const imageLoadTasks = Promise.all(prototype.images.map(url => new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.src = url;
    })));

    imageLoadTasks.then((images: any[]) => {
      // if (!mounted.current) return;
      setIsLoading(false);
    });

    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [refs.current.root, refs.current.nodes]);

  useEffect(() => {
    if (isLoading) return;
    if (!mounted.current) return;
    if (!refs.current.root) return;

    const nodePositions: Record<string, any> = {};

    const root = refs.current.root;
    const rootRect = root.getBoundingClientRect();

    const onloadData: IFigmaReportViewData = {
      width: root.clientWidth,
      height: root.clientHeight,
      nodePositions
    };

    const blocks = refs.current.nodes.length > 0
      ? lodash.reverse(refs.current.nodes)
      : [root];

    blocks.forEach((container) => {
      container?.querySelectorAll("[data-id][data-name]").forEach((element) => {
        const nodeId = (element as HTMLDivElement).dataset.id as string;
        if (nodePositions[nodeId]) return;

        const elRect = element.getBoundingClientRect();
        nodePositions[nodeId] = {
          left: elRect.left - rootRect.left,
          top: elRect.top - rootRect.top
        };
      });

    });

    props.onLoad(onloadData);
  }, [isLoading]);

  const screenId = props.screen.nodeKey;
  const nodes = [prototype.nodesForHtml[screenId]];
  nodes.push(...getNodesWithOverflow(nodes[0]));

  function getNodeKey(index: number) {
    return ['figmaReportView', nodes[index].id, ...(props.screen.overlays?.map(o => o.destinationId) || [])].filter(Boolean).join('-');
  }

  return (
    <div
      className="figma-reports-view flex flex-col items-center gap-3 w-fit-content relative"
      ref={element => refs.current.root = element}
    >
      {nodes.length === 1 &&
        <NodeInHtml
          key={getNodeKey(0)}
          overlayActions={props.screen.overlays}
          display={FigmaDisplayMode.reportView}
          imageScale={imageScale}
          node={nodes[0]}
          prototype={prototype}
          onResize={props.onResize}
        />}

      {nodes.length > 1 && nodes.map((node, index) => (
        <div key={getNodeKey(index)} ref={element => refs.current.nodes[index] = element}>
          <div>{`№${index + 1}`}</div>
          <NodeInHtml
            overlayActions={props.screen.overlays}
            display={FigmaDisplayMode.reportView}
            imageScale={imageScale}
            node={node}
            prototype={prototype}
            zIndex={(index + 1) * 10}
            onResize={props.onResize}
          />
        </div>
      ))}
    </div>
  );
}


function getNodePositions(nodeElements: (HTMLDivElement | null)[], rootRect: DOMRect) {
  const nodePositions: NodePositionsMap = {};

  nodeElements.forEach(container => {
    if (!container) return;

    container.querySelectorAll("[data-id][data-name]").forEach((el) => {
      const element = el as HTMLDivElement;
      const nodeId = element.dataset.id as string;
      if (nodePositions[nodeId])
        return;

      const rect = element.getBoundingClientRect();
      nodePositions[nodeId] = { left: rect.left - rootRect.left, top: rect.top - rootRect.top };
    });
  });

  return nodePositions;
}

function getNodesWithOverflow(node: any) {
  const nodesWithOverflow: any[] = [];

  if (node.children) {
    node.children.forEach((child: any) => {
      if (child.overflowParams)
        nodesWithOverflow.push(child);
      if (child.children)
        nodesWithOverflow.push(...getNodesWithOverflow(child));
    });
  }
  return nodesWithOverflow;
}