import React, { useState, useEffect } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import "../../../../../../tailwind.generated.css";
import uniqid from "uniqid";
import clsx from "clsx";

import Select from "../Select";
import Preference from "../Preference";
import Firstclick from "../Firstclick";
import FiveSeconds from "../FiveSeconds";
import Input from "../Input";
import CardSort from "../CardSort";
import MatrixSelect from "../MatrixSelect";
import ScaleSelect from "../ScaleSelect";
import { responseValidator } from "../ResponseValidator";

import { locale, interfaceText } from "../../../../../../helpers";

import { pushToGTM } from "../../../../../../utils/gtm";
import Figma from "../Figma";
import { Block, BLOCKS_WITH_IMAGES, BlockType, IFigmaBlockWithPrototypeData, IOptionalBlock } from "../../../../../../models/Test";
import { ContentWrapper } from './ContentWrapper';
import { FigmaContextProvider } from '../../../../../Figma/FigmaContextProvider';
import AiOpenQuestion from '../AiOpenQuestion/AiOpenQuestion';

function hasMedia(type: BlockType) {
  return BLOCKS_WITH_IMAGES.includes(type);
}

interface ContentBlockProps {
  data: Block;
  testId: string;
  answerId: string | undefined;
  showNextBlock: () => void;
  designConfig: any;
  sendAnswer: (answerData: any, blockId: string, nextBlock: any) => void;
  isPreview: boolean;
  plan: any;
}

export default function ContentBlock(props: ContentBlockProps) {
  const blockId = props.data.blockId;
  const {
    data, testId, answerId, showNextBlock, designConfig, sendAnswer, isPreview, plan,
  } = props;

  function handleFigmaGiveUp(figmaData: any) {
    submitFigmaBlockAnswer(figmaData, true);
    setBlockAnswer({});
  }

  function handleFigmaSuccess(figmaData: any) {
    submitFigmaBlockAnswer(figmaData, false);
    setBlockAnswer({});
  }

  function submitFigmaBlockAnswer(figmaData: any, givenUp: boolean) {
    const now = _.now();

    const totalTimeSpent = Object.keys(figmaData.nodeEventData).reduce((acc, key) => {
      return acc + figmaData.nodeEventData[key].timeSpent || 0;
    }, 0);

    const answerData = {
      type: BlockType.figma,
      withVideo: figmaData.withVideo ? true : false,
      videoErrorReason: figmaData.reason || null,
      devicePixelRatio: figmaData.devicePixelRatio,
      size: figmaData.size || null,
      givenUp: !!givenUp,
      submittedAt: now,
      time: totalTimeSpent,
      responseTime: totalTimeSpent,
      path: figmaData.path,
      nodeEventData: figmaData.nodeEventData
    };


    sendAnswer(answerData, blockId, answerData);

    setBlockAnswer({});
  }

  const [isLoading, setIsLoading] = useState(true);
  const [blockAnswer, setBlockAnswer] = useState<any>({});
  const [responseStart, setResponseStart] = useState<number | null>(null);
  const [showFiveSecondsImage, setShowFiveSecondsImage] = useState(null);

  const isOptional = (data as IOptionalBlock).isOptional;

  const [imageStatuses, setImageStatus] = useState({});
  const isBlockImagesLoaded = Object.values(imageStatuses).every((s) => s === "loaded");

  useEffect(() => {
    if (hasMedia(data.type) && isBlockImagesLoaded === true) {
      setResponseStart(_.now());
    }
  }, [isBlockImagesLoaded]);

  useEffect(() => {
    pushToGTM({ event: "openQuestion", blockId, testId, answerId });
    setIsLoading(true);
    // setting responseStart to null for "firstclick" to prevent showing image immediately
    setResponseStart(data.type === BlockType.firstclick ? null : _.now());
    setShowFiveSecondsImage(null);
    setImageStatus(getInitialImageStatuses(data)); // preload images
  }, [blockId, testId, answerId]);

  function getInitialImageStatuses(block: Block) {
    const statuses: Record<string, string | undefined> = {};

    if (hasMedia(block.type)) {
      if (block.type === BlockType.preference) {
        block.replies.forEach((reply) => {
          statuses[`reply.${reply.id}.image`] = undefined;
        });
      }
      if ('image' in block && block.image || 'video' in block && block.video) {
        statuses["media"] = undefined;
      }
    }

    return statuses;
  }

  function onImageLoaded(imagePath: string) {
    setImageStatus((state) => ({
      ...state,
      [imagePath]: "loaded",
    }));
  }

  function handleBlockAnswer(key: string, value: any) {
    setBlockAnswer((current: any) => {
      return { ...current, [key]: value };
    });
  }

  const isAnswerValid = responseValidator(blockAnswer, data).isValid;

  function nextStepOnClick() {
    if (isAnswerValid) {
      handleSubmitBlockAnswer();
    } else if (isOptional) {
      showNextBlock();
    }
  }

  function handleSubmitBlockAnswer(additionalBlockAnswerData = {}) {
    const answerData = {
      ...additionalBlockAnswerData,
      ...blockAnswer,
      submittedAt: _.now(),
      type: data.type,
      responseTime: _.now() - responseStart!
    };

    const nextBlock = { ...blockAnswer };
    sendAnswer(answerData, blockId, nextBlock);
    setBlockAnswer({});
    responseStart && setResponseStart(null);
  }

  console.log('CONTENT BLOCK DATA', data);

  if (!data) {
    return null;
  }

  if (data.type === BlockType.openquestion) {
    return (
      <ContentWrapper
        hasButton={true}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        block={data}
        content={<div className="flex flex-col gap-5 w-full">
          <div className="w-full text-lg font-medium flex items-start">
            <div>{parse(data.questionHtml || data.text)}</div>
          </div>
          {data.description && data.description.length > 0 && <div>{parse(data.description)}</div>}
          <div className="w-full flex justify-center">
            <Input
              singleLine={data.isShort}
              placeholder={interfaceText.test[locale()].inputPlaceholder}
              id={`${blockId}-response`}
              handler={handleBlockAnswer}
              designConfig={designConfig}
              className="text-lg rounded-lg p-2 bg-white mx-auto w-full focus:bg-white focus:bg-opacity-100 focus:opacity-100 hover:bg-white hover:bg-opacity-100 hover:opacity-100" />
          </div>
        </div>} />
    );
  }

  if (data.type === BlockType.context) {
    return (
      <ContentWrapper
        hasButton={true}
        nextStepOnClick={() => showNextBlock()}
        isValidated={true}
        block={data}
        content={<div className="w-full flex flex-col gap-6">
          <div className="whitespace-pre-line">
            <div className="font-medium text-xl">{parse(data.questionHtml || data.text)}</div>
          </div>
          {data.description && data.description.length > 0 && <div>{parse(data.description)}</div>}
        </div>} />
    );
  }

  if (data.type === BlockType.choice) {
    return (
      <ContentWrapper
        hasButton={true}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        block={data}
        content={<>
          <div className='select-task-title mb-3 flex flex-col gap-2'>
            <div className="w-full text-lg flex items-start">
              <div className="font-medium">{parse(data.questionHtml || data.text)}</div>
            </div>
            {data.description && data.description.length > 0 && <div className='mb-2'>{parse(data.description)}</div>}
          </div>
          <div className="select-task-options w-full">
            <Select
              type={data.replyType}
              data={data.replies}
              isRandomized={data.randomized}
              withOther={data.other}
              handler={handleBlockAnswer}
              selectedOptions={blockAnswer.selectedOptions || []}
              hasChoiceLimit={data.hasChoiceLimit}
              choiceLimit={data.choiceLimit || 2} />
          </div>
        </>} />
    );
  }

  if (data.type === BlockType.preference) {
    return (
      <Preference
        blockData={data}
        blockAnswer={blockAnswer}
        handleBlockAnswer={handleBlockAnswer}
        nextStepOnClick={nextStepOnClick}
        responseStart={responseStart}
        setResponseStart={setResponseStart} />
    );
  }

  if (data.type === BlockType.cardsort) {
    return (
      <CardSort
        blockData={_.cloneDeep(data)}
        responseStart={responseStart}
        setResponseStart={setResponseStart}
        isValidated={isAnswerValid}
        nextStepOnClick={nextStepOnClick}
        blockAnswer={blockAnswer}
        handleBlockAnswer={handleBlockAnswer} />
    );
  }

  if (data.type === BlockType.matrix) {
    return (
      <ContentWrapper
        hasButton={true}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        block={data}
        content={<MatrixSelect blockData={data} blockAnswer={blockAnswer} handleBlockAnswer={handleBlockAnswer} />} />
    );
  }

  if (data.type === BlockType.scale) {
    return (
      <ContentWrapper
        hasButton={true}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        block={data}
        content={<>
          <div className="w-full flex items-start flex-col gap-2">
            <div className="font-medium text-lg">{parse(data.questionHtml || data.text)}</div>
            {data.description && data.description.length > 0 && <div>{parse(data.description)}</div>}
          </div>
          <div className="my-2 w-full">
            <ScaleSelect blockData={data} blockAnswer={blockAnswer} handleBlockAnswer={handleBlockAnswer} />
          </div>
        </>} />
    );
  }

  if (data.type === BlockType.firstclick) {
    return (
      <Firstclick
        blockData={data}
        blockAnswer={blockAnswer}
        handleBlockAnswer={handleBlockAnswer}
        nextStepOnClick={nextStepOnClick}
        // onImageLoaded={onImageLoaded}
        responseStart={responseStart}
        setResponseStart={setResponseStart}
        isAnswerValid={isAnswerValid} />
    );
  }

  // type === "fiveseconds"
  if (data.type === BlockType.fiveseconds) {
    return (
      <FiveSeconds
        blockData={data}
        showNextBlock={showNextBlock}
         />
    );
  }

  // Figma prototype testing
  if (data.type === BlockType.figma) {
    return (
      <FigmaContextProvider>
        <Figma
          data={data as IFigmaBlockWithPrototypeData}
          plan={plan}
          testId={testId}
          answerId={answerId!}
          isPreview={isPreview}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleFigmaGiveUp={handleFigmaGiveUp}
          handleFigmaSuccess={handleFigmaSuccess} />
      </FigmaContextProvider>
    );
  }

  if (data.type === BlockType.ai) {
    return (
      <AiOpenQuestion
        block={data}
        testId={testId}
        answerId={answerId || ('answer-' + uniqid() + '-preview')}
        blockId={blockId}
        isPreview={isPreview}
        isOptional={isOptional}
        onChatComplete={(chatId) => {
          if (!!chatId) {
            handleSubmitBlockAnswer({ chatId });
          } else {
            showNextBlock();
          }
        } } />
    );
  }

  return null;
}
