import { useMutation } from '@tanstack/react-query';
import { makeRequest } from '../../../../http/Client';
import { SignWithEmailDTO } from './LoginWithEmail';
import { auth } from '../../../../firebase';

export enum LoginErrorCode {
  TooManyRequests = 'too_many_requests',
  NetworkError = 'network',
  InvalidAuthCode = 'invalid_auth_code',
}

export function useLoginByCode(options: { onSendCodeError?: (errorCode: LoginErrorCode) => void; onVerifyCodeError?: (errorCode: LoginErrorCode) => void; }) {

  const sendCode = useMutation<boolean, Error, SignWithEmailDTO>({
    mutationFn: async (data) => {
      const response = await makeRequest('/api/v1/auth/login', 'POST', data);
      if (!response.ok && response.status === 429) {
        throw new Error(LoginErrorCode.TooManyRequests);
      }
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.error_code);
      }
      return true;
    },
    onError: (error: Error) => {
      console.error(error);
      options.onSendCodeError?.(error.message as any);
    },
  });

  const verifyCode = useMutation<{ token: string }, Error, { email: string; code: string; }>({
    mutationFn: async (data) => {
      const response = await makeRequest('/api/v1/auth/verify', 'POST', data);
      if (!response.ok && response.status === 429) {
        throw new Error(LoginErrorCode.TooManyRequests);
      }
      if (!response.ok && response.status === 400) {
        const result = await response.json();
        throw new Error(result.error_code);
      }
      const result = await response.json();
      await auth.signInWithCustomToken(result.token)
      return result;
    },
    onError: (error: Error) => {
      console.error(error);
      options.onVerifyCodeError?.(error.message as any);
    },
  });


  return {
    sendCode,
    verifyCode,
  };
}
