import { IRecordEvent, IReplayEvent, RecordEventType } from '../types/events';

export class EventsReplayer {
	private timeOffset = 0;
	/** Отметка предыдущего рендера либо начала воспроизведения */
	private lastTimestamp = 0;
	private speed = 1;
	private isPlaying = false;
	private finishedPlaying = false;
	private animationRequestId: number | null = null;
	private events: IReplayEvent[];
	private eventsQueue: IReplayEvent[] = [];
	private baselineTime = 0;

	constructor(
		events: IRecordEvent[],
		private startTimestamp: number,
		private endTimestamp: number,
		private pointer: HTMLElement,
		private isMobile: boolean,
		private indentX: number = 0,
		private indentY: number = 0,
	) {
		this.events = events as IReplayEvent[];
		this.setStartState();
	}

	public play() {
		this.isPlaying = true;
		if (this.finishedPlaying) {
			this.finishedPlaying = false;
			this.timeOffset = 0;
			this.setStartState();
		}
		// lastTimestamp увеличен на разницу между стартом записи и первым событием
		const a = (this.events[0].ts - this.startTimestamp);
		const b = (this.endTimestamp - (this.events[this.events.length - 1].ts));
		console.log(this.events[0].ts, this.startTimestamp, a, b);
		this.lastTimestamp = performance.now();
		if (this.startTimestamp !== 0) {
			// this.lastTimestamp += a;
		}
		this.animationRequestId = requestAnimationFrame(this.playEvents.bind(this));
	}

	public pause() {
		this.isPlaying = false;
		this.reset(true);
	}

	public seek(time: number) {
		time *= 1000;
		// this.pause();
		this.reset();
		this.eventsQueue.length = 0;
		//baselineTime is the time we want to start
		// this.baselineTime = this.events[0].timeStamp + time;
		// this.calculateDelays(this.baselineTime);
		this.timeOffset = time;

		for (const e of this.events) {
			const re = e as IReplayEvent;
			if (re.delay > time) {
				this.eventsQueue.push(re);
			}
		}
	}

	private setStartState() {
		this.baselineTime = this.events[0].ts;
		this.calculateDelays(this.baselineTime);
		this.eventsQueue = this.events.slice();
	}

	private reset(fromPause: boolean = false) {
		if (this.animationRequestId) {
			cancelAnimationFrame(this.animationRequestId);
			this.animationRequestId = null;
		}
	}

	private calculateDelays(baselineTime: number) {
		for (const e of this.events) {
			e.delay = e.ts - baselineTime;
		}
	}

	private playEvents() {
		const now = performance.now();
		// сколько времени прошло с предыдущего рендера
		this.timeOffset += (now - this.lastTimestamp) * this.speed;
		this.lastTimestamp = now;

		// show pointer element
		this.pointer.classList.remove("opacity-0");

		while (this.eventsQueue.length > 0) {
			const event = this.eventsQueue[0];
			if (event.delay > this.timeOffset) {
				break;
			}

			const e = this.eventsQueue.shift() as IReplayEvent;
			this.playEvent(e);
		}

		if (this.eventsQueue.length > 0) {
			this.animationRequestId = requestAnimationFrame(this.playEvents.bind(this));
		}
		else {
			this.animationRequestId = null;
			this.finishedPlaying = true;
		}
	}

	private playEvent(e: IReplayEvent) {
		this.pointer.style.transform = `translate(${e.x - this.indentX}px, ${e.y - this.indentY}px)`;

		if (this.isMobile) {
			if (e._type === RecordEventType.pointerdown) {
				this.pointer.style.backgroundColor = "orange";
			} else if (e._type === RecordEventType.pointerup) {
				this.pointer.style.backgroundColor = "green";
			} else if (e._type === RecordEventType.pointermove) {
				this.pointer.style.backgroundColor = "red";
			}
		} else {
			if (e._type === RecordEventType.pointerdown) {
				this.pointer.style.transform += `scale(0.7)`;
			} else if (e._type === RecordEventType.pointerup) {
				this.pointer.style.transform += `scale(1)`;
			} else if (e._type === RecordEventType.pointermove) {
			}
		}

	}
}