import { VideoMimeType } from "@vidstack/react";

export const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

export const validImageTypes = ["image/png", "image/jpeg", "image/gif", "image/svg+xml", "image/webp"];
export const validVideoTypes = ["video/mp4", "video/webm", "video/quicktime"];
export const supportedVideoPlayerTypes: VideoMimeType[] = ["video/mp4", "video/webm"];
export const validFileSize = {
	image: 15 * 1024 * 1024, // 15MB
	video: 25 * 1024 * 1024, // 25MB
};
export const maxDimension = 16000;