import React, { useRef } from "react";
import FirstClickPointer from "../../Common/FirstClickPointer";
import { IFirstClickResponse } from '../../../models/Response';

export interface ClicksTabProps {
  responses: IFirstClickResponse[];
  imageUrl: string;
  summaryReport?: boolean;
}

export default function ClicksTab(props: ClicksTabProps) {
  const { summaryReport = true } = props;

  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex-auto rounded-2xl bg-gray-800 p-6">
      {/* {tabs && <Tabs tabs={tabs} />} */}
      <div className="flex items-center justify-center pt-6">
        <div className="flex items-center justify-center">
          <div
            className="relative inline"
            style={{ maxWidth: summaryReport ? "800px" : "320px" }}
            ref={imageContainerRef}
          >
            {props.responses.map((response) => (
              <FirstClickPointer
                key={`clicks-tab-${response.answerId}`}
                position={response.clickData}
                opacity={95} 
                withPopover={false} 
                popoverContentBlock={undefined}
                imageContainerRef={imageContainerRef}
                 />
            ))}
            <img
              id="firstClickImage"
              src={props.imageUrl}
              alt=""
              className="opacity-50 hover:opacity-100 transition-opacity ease-in duration-75"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
