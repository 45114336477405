import React from "react";
import { t } from "i18next";

import ClickareaDataLabel from "./ClickareaDataLabel";

import { IconCursor, IconDelete, IconRespondent } from "../../../icons";
import { IArea } from "../models";

export default function FigmaClickareaData(props: {area: IArea}) {
  const { area } = props;

  return (	
    <>
      <ClickareaDataLabel>
        <IconCursor className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Clicks")}: {area.clicksCount}</span>
      </ClickareaDataLabel>
      <ClickareaDataLabel>
        <IconDelete className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Missed")}: {area.missedClicksCount}</span>
      </ClickareaDataLabel>
      <ClickareaDataLabel>
        <IconRespondent className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Respondents")}: {area.respondentsCount} ({area.respondentsPercent}%)</span>
      </ClickareaDataLabel>
    </>
  );
}