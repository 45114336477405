import React, { useRef } from "react";
import lodash from "lodash";
import clsx from 'clsx';
import { Environment } from '../../../utils/env';
import {
  FigmaDisplayMode,
  PX, bgNoRepeat, getNodeCoordinates,
  hasRotation,
  getChildren
} from './NodeInHtmlUtils';
import { NodeWithScroll } from './NodeWithScroll';
import { NodeWithHover } from './NodeWithHover';
import { ComponentNode } from './ComponentNode';
import { useReactions } from '../hooks/useReactions';
import { IFormattedNode } from '../../../utils/figma';
import { OverlayPositionType } from '../../../models/Figma/prototype';

export const throttledOnClick = lodash.throttle((onClick, data) => onClick(data), 100);

const baseZIndex = 10;
export interface INodeViewProps extends React.PropsWithChildren<any> {
  parent?: any;
  node: IFormattedNode;
  onClick?: Function;
  imageScale: number;
  horizontalCompressionCoef: number;
  display: FigmaDisplayMode;
  windowHeight?: number;
  scaleRate?: number;
  fixed?: boolean;
  zIndex?: number;
  isHover?: boolean;
  isVariant?: boolean;
  onMouseLeave?: (e: any) => void;

  visible?: boolean;
  className?: string;
  showDefaultCursor?: boolean;
}

export function NodeView(props: INodeViewProps) {
  const { parent, imageScale, onClick: onPrototypeClick, node } = props;
  const { staticChildren, fixedChildren } = getChildren(node);
  const isScreen = !parent;
  const nodeRef = useRef<HTMLDivElement | null>(null);
  let isClickable = node.reactions?.hasReactions && (node.reactions?.ON_CLICK?.action || node.reactions?.ON_HOVER?.action);

  const nodeProps: any = {
    style: {
      position: "absolute",
      width: (node.width) * imageScale,
      height: (node.height) * imageScale,
      zIndex: baseZIndex + (props.zIndex || 0),
    }
  };

  const handlers = useReactions(node, node.reactions, props.horizontalCompressionCoef);
  Object.assign(nodeProps, handlers);

  if (node.image) {
    nodeProps.style.background = bgNoRepeat(node.image);
    if (!node.children?.length) {
      nodeProps.style.overflow = "hidden";
    }
  }
  if (node.clipsContent === true) {
    nodeProps.style.overflow = "hidden";
  }

  if (hasRotation(node) && !node.absoluteBoundingBox) {
    // need to subtract angle, because in figma elements are rotated counter-clockwise, and in browser - clockwise
    nodeProps.style.transform = `rotate(${0 - node.rotation}deg)`;
    nodeProps.style.transformOrigin = 'top left';
  }

  if (isClickable) {
    nodeProps.style.cursor = !!props.showDefaultCursor ? "default" : "pointer";
    nodeProps.style.pointerEvents = "all";

    if (Environment.isDevelopment) {
      nodeProps.style.backgroundColor = "rgba(255,0,0,0.3)";
    }
  }

  if (!isScreen) {
    const coordinates = getNodeCoordinates(node, parent, imageScale);
    nodeProps.style.left = coordinates.left;
    nodeProps.style.top = coordinates.top;
  }

  if (node.flowNodeType === "overlay" && parent) {
    setOverlayCoordinates(nodeProps, node, props.windowHeight as number / (props.scaleRate || 1), parent.width, props.imageScale, props.scaleRate || 1);
  }

  if (props.fixed) {
    nodeProps.style.zIndex += baseZIndex;

    if (!isClickable) {
      nodeProps.style.pointerEvents = "none";
    }

    // set background image for fixed nodes from parent
    if (!node.image && parent && parent.image) {
      const bgImage = `url(${parent.image})`;
      const bgPosition = `-${node.x * imageScale}px -${node.y * imageScale}px`;
      nodeProps.style.background = `${bgImage} ${bgPosition} no-repeat`;
      nodeProps.style.overflow = "hidden";
    }

    if (parent && node.constraints) {
      if (node.constraints.horizontal === "MAX") {
        nodeProps.style.right = PX((parent.width - node.x - node.width) * imageScale);
        nodeProps.style.left = "";
      }
      if (node.constraints.vertical === "MAX") {
        nodeProps.style.bottom = PX((parent.height - node.y - node.height) * imageScale);
        nodeProps.style.top = "";
      }
      if (node.constraints.vertical === "CENTER" && props.windowHeight && props.scaleRate) {
        nodeProps.style.top = PX(getCenteredTopCoordinate(props.windowHeight, node, imageScale));
      }
    }
  }

  if (props.isHover || props.isVariant) {
    nodeProps['data-hover-for'] = parent.id;

    resetCoordinates(nodeProps);

    if (parent.constraints.horizontal === "MAX") {
      nodeProps.style.right = PX(0);
    }
    if (parent.constraints.vertical === "MAX") {
      nodeProps.style.bottom = PX(0);
    }

    if (parent.constraints.horizontal === "CENTER") {
      nodeProps.style.left = PX((parent.width - node.width) / 2 * imageScale);
    }
    if (parent.constraints.vertical === "CENTER") {
      nodeProps.style.top = PX((parent.height - node.height) / 2 * imageScale);
    }
  }

  if (node.overflowParams) {
    nodeProps.style.overflow = "";
    nodeProps.style.position = "absolute";

    // In reportView, the scrolling nodes need to be
    // displayed in full height and width.
    if (props.display === FigmaDisplayMode.reportView) {
      nodeProps.style.background = bgNoRepeat(node.overflowParams.image);
      nodeProps.style.height = PX(node.overflowParams.height * imageScale);
      nodeProps.style.width = PX(node.overflowParams.width * imageScale);
    }
  }

  const staticNodes = staticChildren.filter(c => !!c).map((child: any, index: number) => (
    <NodeView
      key={child.id}
      display={FigmaDisplayMode.actualSize}
      parent={node}
      onClick={onPrototypeClick}
      imageScale={imageScale}
      node={child}
      zIndex={nodeProps.style.zIndex + index}
      horizontalCompressionCoef={props.horizontalCompressionCoef}
      showDefaultCursor={props.showDefaultCursor}
    />
  ));

  if (node.overflowParams && props.display != FigmaDisplayMode.reportView) {
    return <NodeWithScroll
      node={node}
      parent={parent}
      nodeProps={nodeProps}
      onClick={onPrototypeClick}
      imageScale={imageScale}
      fixedChildren={fixedChildren}
      horizontalCompressionCoef={props.horizontalCompressionCoef}
      display={props.display}
    >
      {staticNodes}
    </NodeWithScroll>;
  }

  if (node.onHoverChangeTo) {
    return <NodeWithHover
      node={node}
      nodeProps={nodeProps}
      imageScale={imageScale}
      onClick={onPrototypeClick}
      isScreen={false}
      horizontalCompressionCoef={props.horizontalCompressionCoef}
    >
      {staticNodes}
    </NodeWithHover>
  }

  if (node.type === "INSTANCE" && (node.on_hover_change_to || node.on_click_change_to)) {
    return <ComponentNode
      node={node}
      nodeProps={nodeProps}
      imageScale={imageScale}
      onClick={onPrototypeClick}
      isScreen={false}
      horizontalCompressionCoef={props.horizontalCompressionCoef}
      zIndex={props.zIndex}
      visible={typeof (props.visible) === 'undefined' ? true : props.visible}
      showDefaultCursor={props.showDefaultCursor}
    >
      {staticNodes}
    </ComponentNode>;
  }

  return (
    <div
      data-id={node.id}
      data-name={node.name}
      className={clsx(
        `node-view__el`,
        props.isHover ? 'node-view__el_hover' : '',
        isScreen ? 'prototype-screen' : 'prototype-element',
        props.className)}
      style={nodeProps.style}
      // onClick={nodeProps.onClick}
      {...nodeProps}
      {...handlers}
      ref={nodeRef}
    >
      {staticNodes}
    </div>
  );
}

function resetCoordinates(nodeProps: any) {
  nodeProps.style.left = "";
  nodeProps.style.top = "";
  nodeProps.style.right = "";
  nodeProps.style.bottom = "";
}

function getCenteredTopCoordinate(windowHeight: number, node: any, imageScale: number): any {
  return (windowHeight - node.height) * imageScale / 2;
}
function getCenteredLeftCoordinate(windowWidth: number, node: any, imageScale: number): any {
  return (windowWidth - node.width) * imageScale / 2;
}

export function setOverlayCoordinates(nodeProps: any, node: IFormattedNode, windowHeight: number, windowWidth: number, imageScale: number, scaleRate: number) {
  resetCoordinates(nodeProps);
  nodeProps.style.zIndex = nodeProps.style.zIndex || "20";
  nodeProps.style.pointerEvents = "none";

  if (node.overlayPositionType === OverlayPositionType.CENTER) {
    nodeProps.style.left = PX(getCenteredLeftCoordinate(windowWidth, node, imageScale));
    nodeProps.style.top = PX(getCenteredTopCoordinate(windowHeight, node, imageScale));
  }

  if (node.overlayPositionType?.startsWith("TOP")) {
    nodeProps.style.top = PX(0);
    if (node.overlayPositionType === OverlayPositionType.TOP_LEFT) {
      nodeProps.style.left = PX(0);
    }
    if (node.overlayPositionType === OverlayPositionType.TOP_RIGHT) {
      nodeProps.style.right = PX(0);
    }
    if (node.overlayPositionType === OverlayPositionType.TOP_CENTER) {
      nodeProps.style.left = PX(getCenteredLeftCoordinate(windowWidth, node, imageScale));
    }
  }

  if (node.overlayPositionType?.startsWith("BOTTOM")) {
    nodeProps.style.bottom = PX(0);

    if (node.overlayPositionType === OverlayPositionType.BOTTOM_LEFT) {
      nodeProps.style.left = PX(0);
    }
    if (node.overlayPositionType === OverlayPositionType.BOTTOM_RIGHT) {
      nodeProps.style.right = PX(0);
    }
    if (node.overlayPositionType === OverlayPositionType.BOTTOM_CENTER) {
      nodeProps.style.left = PX(getCenteredLeftCoordinate(windowWidth, node, imageScale));
    }
  }

  if (node.overlayPositionType === OverlayPositionType.MANUAL) {
    nodeProps.style.left = PX(node.overlayPosition.x * imageScale);
    nodeProps.style.top = PX(node.overlayPosition.y * imageScale);
  }

}
