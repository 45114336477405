export class FigmaVideoPlayer {

	constructor(
		public video: HTMLVideoElement,
		public src: string,
		public pixelRatio: number = 1
	) {
		this.setupVideoElement();
		this.addEventListeners();
	}

	private setupVideoElement() {
		const video = this.video;
		video.controls = false;
		video.autoplay = false;
		video.muted = true;
		video.loop = false;
		video.playsInline = true;
	}

	private addEventListeners() {
		this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this));
	}

	private onMetadataLoaded() {
		const width = (this.video.videoWidth / this.pixelRatio) + 'px';
		this.video.style.width = width;
		const height = (this.video.videoHeight / this.pixelRatio) + 'px';
		this.video.style.height = height;
	}
}