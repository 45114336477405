import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";

import Clickarea  from "../Clickareas/Clickarea";
import useDrawingArea from "../Clickareas/hooks/useDrawingArea";
import useFirstClickAreaStats from "../Clickareas/hooks/useFirstClickAreaStats";
import { useClickmap } from "./hooks/useClickmap";

import { IClickmap } from "./STORAGE";

import {IArea} from "../models";
import { IFirstClickResponse } from "../../../models/Response";
import { FirstClickareaData } from "../Clickareas/FirstClickareaData";

export interface IClickmapProps {
  clickmap: IClickmap;
  wrapperRef?: React.RefObject<HTMLElement>;
  onAreaAdded: (areas: IArea[]) => void;
  imageUrl?: string;
  hideAreas?: boolean;
  figmaPrototype?: React.ReactNode;
  clicks: IFirstClickResponse[];
  
  children: (
    withScale: ScaleFunction,
    getScaleRate: GetScaleRateFunction
  ) => React.ReactElement;
}

export type ScaleFunction = (x: number) => number;
export type GetScaleRateFunction = (width: number) => number;

export default function FirstClickmap(props: IClickmapProps) {
  const [areas, setAreas] = useState<IArea[]>([]);

  const {
    wrapperRef, 
    innerRef,
    scaleRate,
    withScale,
    getScaleRate,
    handleResize,
  } = useClickmap(props.clickmap.width, props.clickmap.height);

  const respondentsTotal = props.clicks.length;
  const { drawnAreas, removeArea, clickAreaRef } = useDrawingArea(scaleRate, respondentsTotal, props.clickmap.areas);
  const { calculateStatistics } = useFirstClickAreaStats(props.clicks, props.clickmap.width, props.clickmap.height);


  useEffect(() => {
  	window.addEventListener("resize", handleResize);
  	handleResize();

  	return () => {
  		window.removeEventListener("resize", handleResize);
  	};
  }, []);

	useEffect(() => {
			setAreas(calculateStatistics([...drawnAreas]));
      props.onAreaAdded(drawnAreas);
	}, [drawnAreas]);

  return (
    <div ref={wrapperRef} className="clickmap h-auto overflow-x-hidden select-none">
      <style>
        {`.figma-reports-view {
              counter-reset: scrollblocks;
              counter-increment: scrollblocks;
            }
          
            .figma-reports-view [data-has-scroll]::before {
              counter-increment: scrollblocks;
              content: "№ " counter(scrollblocks);
              font-size: ${withScale(16 * 2)}px;
              background: #000c;
              z-index: 50;
              color: #fff;
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              top: 0;
              right: 0;
            }`}
      </style>
      <div
        ref={innerRef}
        className="clickmap__heatmap-container relative"
        style={{
          width: props.clickmap.width,
          height: props.clickmap.height,
        }}
      >
        {props.figmaPrototype && (
          <div className="prototype-screen-wrapper absolute top-0">
            {props.figmaPrototype}
          </div>
        )}

        {!props.figmaPrototype && !!props.imageUrl && (
          <img className="absolute top-0" src={props.imageUrl} />
        )}

        {!!props.children && (
          <div className="prototype-clicks absolute top-0">
            {props.children(withScale, getScaleRate)}
          </div>
        )}
        <div className="absolute top-0"></div>

         <div     
          className={`clickmap_draw absolute top-0 overflow-hidden inset-0 ${
            props.hideAreas
              ? "pointer-events-none"
              : "pointer-events-auto cursor-[crosshair]"
          }`}
					style={{
						width: props.clickmap.width,
						height: props.clickmap.height,
            userSelect: 'none',
					}}
        >
          <div className="clickareas absolute inset-0" ref={clickAreaRef}>
            {areas.map((area, index) => (
              <Clickarea
                key={"area-" + index}
                scaleRatio={scaleRate}
                area={area}
                onClick={removeArea}
              >
               <FirstClickareaData area={area}/>
              </Clickarea>
            ))}
          </div>
         </div>
      </div>
    </div>
  );
};

