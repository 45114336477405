import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { PrototypesAPI } from '../../../actions/PrototypesAPI';
import { IFigmaPrototype } from '../../../models/Test';
import { getFormattedPrototype } from '../../../utils/figma';


export function usePrototypeQuery(protoId: string | undefined) {
	return useQuery<any, any, IFigmaPrototype, (string | undefined)[]>({
		queryKey: ['prototypes', protoId],
		queryFn: protoQueryFunction,
		staleTime: Infinity,
		enabled: !!protoId,
	});
}

export async function protoQueryFunction({ queryKey, signal }: QueryFunctionContext<(string | undefined)[]>) {
	if (!queryKey[1]) {
		return null;
	}
	const prototype = await PrototypesAPI.fetchPrototype(queryKey[1], { signal });
	return getFormattedPrototype(prototype);
}
