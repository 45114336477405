import { RefObject } from "react";
import { isWithin } from "../../Clickmaps/Utils";

import { IArea } from "../../models";

export default function useFigmaNativeClickAreaStats(scaleRatio:number, clickmapRef: RefObject<HTMLDivElement>, respondentsTotal: number){

  function calcClickareasStats(areas: IArea[]) {
		// когда меняются areas, нужно пересчитать clicksCount и missedClicksCount
		const newAreas = [...areas];
		newAreas.forEach((area) => {
			area.clicksCount = 0;
			area.missedClicksCount = 0;
			area.respondentsCount = 0;
			const responseIdSet = new Set();

			// берутся все слои (которые скроллятся)
			const allScreenLayers = clickmapRef.current?.querySelectorAll('.screen-layer');
			allScreenLayers?.forEach((layer) => {
				// find all parents of layer with class screen-layer until its parent with class root
				const parents = getParentLayers(layer);
				const targetRect = layer.getBoundingClientRect();
				const intersection = {
					top: targetRect.top,
					left: targetRect.left,
					bottom: targetRect.bottom,
					right: targetRect.right,
				};

				// Берем всех родителей текущего слоя (они все скроллящиеся) и вычисляем минимальную видимую область для текущего слоя
				// Если слой не виден в области прокрутки родителей, то он не учитывается
				calculateAreaIntersection(parents, intersection);

				// Если область получается с отрицательной высотой или шириной, значит она не видна и для этого слоя клики не смотрим
				if (intersection.top >= intersection.bottom || intersection.left >= intersection.right) {
					return;
				}

				// берем клики внутри каждого слоя
				let clicks = Array.from(layer.querySelector('.screen-layer__clicks')?.querySelectorAll('.click') || []);

				// фильтруем клики которые входят в видимую область прокрутки
				clicks = clicks.filter((click) => {
					const rect = click.getBoundingClientRect();
					const clickX = rect.left + rect.width / 2;
					const clickY = rect.top + rect.height / 2;
					return isWithin(clickX, intersection.left, intersection.right) && isWithin(clickY, intersection.top, intersection.bottom);
				});

        clicks.forEach((click) => {
          const rect = click.getBoundingClientRect();
					const parentRect = clickmapRef.current?.getBoundingClientRect();

					// относительные координаты клика в области прокрутки
					const relativeX = rect.left - parentRect!.left;
					const relativeY = rect.top - parentRect!.top;

          // Расчет координат клика с учетом прокрутки родительского контейнера
          const clickX = relativeX + rect.width / 2;
          const clickY = relativeY + rect.height / 2;

          const areaMinX = area.left! * scaleRatio;
          const areaMaxX = (area.left! + area.width) * scaleRatio;
          let areaMinY = area.top! * scaleRatio;
          let areaMaxY = (area.top! + area.height) * scaleRatio;

					if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
						console.log('click', click, layer, rect, area, click.getAttribute('data-handled'))
						area.clicksCount!++;
						if (click.getAttribute('data-handled') !== 'true') {
							area.missedClicksCount!++;
						}
						responseIdSet.add(click.getAttribute('data-response-id'));
						area.respondentsCount!++;
					}
				});
			});

			area.respondentsCount = responseIdSet.size;
			area.respondentsPercent = respondentsTotal > 0 ? Math.round((area.respondentsCount / respondentsTotal) * 100) : 0;
			console.log('area', respondentsTotal, area.respondentsCount, area.respondentsPercent);
		});

		return newAreas;
	}

	return {
		calcClickareasStats,
	}
}

function calculateAreaIntersection(parents: HTMLElement[], intersection: { top: number; left: number; bottom: number; right: number; }) {
	parents.forEach((div) => {
		const divRect = div.getBoundingClientRect();

		intersection.top = Math.max(intersection.top, divRect.top);
		intersection.left = Math.max(intersection.left, divRect.left);
		intersection.bottom = Math.min(intersection.bottom, divRect.bottom);
		intersection.right = Math.min(intersection.right, divRect.right);
	});
}

function getParentLayers(layer: Element) {
	const parents = [];
	let parent = layer as HTMLElement | null;
	while (parent) {
		parent = parent.parentElement;
		if (parent?.classList.contains('screen-layer')) {
			parents.push(parent);
			if (parent.classList.contains('root')) break;
		}
	}
	return parents;
}
