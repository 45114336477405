import React, { useEffect, useState } from "react";
import { getAuthToken } from "../../firebase";
import "../../tailwind.generated.css";

import Popover from "../Popover";
import Switcher from "../Switcher";
import { Formik } from "formik";

import lodash from "lodash";

const getButtonClassName = (params = {}) => {
  const signInButtonClassName =
    "my-2 py-1 px-2 rounded-full text-base font-medium tracking-tight transition-all duration-75 ease-in";
  if (!params.disabled)
    return (
      signInButtonClassName +
      " bg-blue-600 hover:bg-blue-400 text-white cursor-pointer"
    );
  else
    return (
      signInButtonClassName + " bg-gray-100 text-gray-600 cursor-not-allowed"
    );
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isForbidden, setIsForbidden] = useState(false);

  const fetchUsers = async () => {
    const authToken = await getAuthToken();
    const response = await fetch(`/api/admin/getUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      setUsers(data);
    }
    else if (response.status === 403) {
      setIsForbidden(true);
    }
    // setIsLoading(false);
  };

  const handleAddCredits = async (userId, value, comment, type) => {
    const authToken = await getAuthToken();
    const response = await fetch(`/api/admin/addCredits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({
        userId: userId,
        value: parseInt(value),
        comment: comment,
        type,
      }),
    });

    if (response.ok) {
      const { credits } = await response.json();

      setUsers((state) => {
        const newState = lodash.cloneDeep(state);
        const userIndex = lodash.findIndex(newState, { userId });
        newState[userIndex].credits = credits;
        return newState;
      })
    }
    return;
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (isForbidden) {
    return <div className="container mx-auto w-full pb-6">
      <span className="text-3xl font-bold block my-8">No access</span>
    </div>;
  }

  return (
    <div className="container mx-auto w-full">
      <span className="text-3xl font-bold block my-8">Users</span>
      <div className="grid grid-cols-5 my-4 gap-4 text-gray-600 text-base font-medium">
        <div>Name</div>
        <div>Email</div>
        <div>Team</div>
        <div>Credits</div>
        <div>Actions</div>
      </div>
      {users
        .sort(
          (a, b) =>
            b.userData.createdAt._seconds - a.userData.createdAt._seconds
        )
        .map((user) => {
          return (
            <div className="grid grid-cols-5 my-4 gap-4 text-base" key={user.userId}>
              <div>{user.userData.displayName}</div>
              <div>{user.userData.email}</div>
              <div>
                {user.credits.projectName || (
                  <span className="text-gray-500">—</span>
                )}
              </div>
              <div>{user.credits.value}</div>
              <div className="flex items-center justify-start">
                <Popover
                  position="bottom-left"
                  getContent={(setIsVisible) => (
                    <Formik
                      initialValues={{ amount: "", comment: "", type: "purchased" }}
                      validate={({ amount }) => {
                        const errors = {};
                        if (Number(amount) <= 0) {
                          errors.amount = "Incorrect amount";
                        }
                        return errors;
                      }}
                      onSubmit={async ({ amount, comment, type }) => {
                        await handleAddCredits(
                          user.userId,
                          amount,
                          comment,
                          type
                        );
                        setIsVisible(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="p-1 w-48">
                            <input
                              name="amount"
                              className="w-full"
                              disabled={isSubmitting}
                              placeholder="Amount"
                              value={values.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                              <div className="mb-2 text-base font-medium text-red-600">
                                {errors.amount}
                              </div>
                            )}

                            <input
                              name="comment"
                              className="w-full"
                              disabled={isSubmitting}
                              placeholder="Comment"
                              value={values.comment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <Switcher
                              text="It's gratuitous credits"
                              isOn={values.type === "gratuitous"}
                              handler={(isGratuitous) => {
                                setFieldValue("type", isGratuitous ? "gratuitous" : "purchased");
                              }}
                            />
                          </div>

                          <button
                            className={getButtonClassName({
                              disabled: (errors.email && touched.email) || isSubmitting,
                            })}
                            type="sumbit"
                          >
                            {isSubmitting ? "Wait..." : "Add"}
                          </button>
                        </form>
                      )}
                    </Formik>
                  )}
                  anchorClassName="inline w-fit-content"
                >
                  <div className="rounded-full border-2 border-black font-medium text-xs px-1 hover:opacity-75 mr-2">
                    Add credits
                  </div>
                </Popover>
                <a
                  href={`/authByToken?userId=${user.userId}`}
                  className="inline w-fit-content"
                >
                  <div className="rounded-full border-2 border-black font-medium text-xs px-1 hover:opacity-75 mr-2">
                    Log in
                  </div>
                </a>
                <div
                  className="rounded-full border-2 border-black font-medium text-xs px-1 hover:opacity-75 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(user.userId);
                  }}
                >
                  Copy userId
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Users;
