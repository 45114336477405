import React, { useEffect, useRef } from "react";
import HeatmapJS from "heatmap.js";
import { IReportPrototypeClick } from "../FigmaReport/FigmaReport";

type HeatmapProps = {
	width: number;
	height: number;
	maxWeight: number;
	clicks: IReportPrototypeClick[];
	useAbsoluteValues?: boolean;
	className?: string;
};

function Heatmap({ width, height, clicks, useAbsoluteValues, className, maxWeight }: HeatmapProps) {

	const heatmapRef = useRef<HTMLDivElement & { heatmapInstance: HeatmapJS.Heatmap<"value", "x", "y"> }>(null);

	useEffect(() => {
		if (!heatmapRef.current) return;


		const heatmapInstance = !heatmapRef.current.heatmapInstance
			? HeatmapJS.create({ container: heatmapRef.current })
			: heatmapRef.current.heatmapInstance;

		heatmapRef.current.heatmapInstance = heatmapInstance;


		const points = clicks.map(click =>
		({
			x: Math.round(useAbsoluteValues ? click.clickData.left : Math.floor(click.clickData.left * width)),
			y: Math.round(useAbsoluteValues ? click.clickData.top : Math.floor(click.clickData.top * height)),
		} as HeatmapJS.DataPoint<"value", "x", "y">));

		heatmapInstance.setData({
			max: maxWeight,
			min: 0,
			data: points
		});

		return () => {
			(heatmapInstance as any)._renderer?.canvas?.remove();
			if (heatmapRef.current) {
				(heatmapRef.current.heatmapInstance as any) = undefined;
			}
		}
	}, [width, height, clicks, className, maxWeight]);

	return <div className={className} ref={heatmapRef} style={{ width, height }} />;
}

export default Heatmap;
