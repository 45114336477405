import { useState, useRef } from "react";

export function useClickmap(clickmapWidth:number, clickmapHeight:number ){
  const [scaleRate, setScaleRate] = useState<number>(1);
  
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const withScaleMemo = useRef<Record<string, number>>({}).current;

  function getScaleRate(width: number) {
    if (wrapperRef.current) {
      const wrapperOffsetWidth = wrapperRef.current.offsetWidth;
      return width > wrapperOffsetWidth ? wrapperOffsetWidth / width : 1;
    }

    return 1;
  }

  function withScale(value: number) {
    const key = `${value}${clickmapWidth}`;

    if (withScaleMemo[key]) {
      return withScaleMemo[key];
    }

    const scaleRate = getScaleRate(clickmapWidth);
    const result = Math.round(value * (value / (value * scaleRate)));
    withScaleMemo[key] = result;

    return result;
  }

  function handleResize () {
  	const scaleRate = getScaleRate(clickmapWidth);

  	if (innerRef.current) {
  		innerRef.current.style.transformOrigin = "0 0";
  		innerRef.current.style.transform = `scale(${scaleRate})`;
  		innerRef.current.style.height = "auto";
  		innerRef.current.style.left = "50%";
  		innerRef.current.style.marginLeft = `-${(clickmapWidth * scaleRate) / 2}px`;
  	}

  	if (wrapperRef.current) {
  		wrapperRef.current.style.height = `${clickmapHeight * scaleRate}px`;
  	}

  	setScaleRate(scaleRate);
  };

  return {
    wrapperRef, 
    innerRef,
    scaleRate,
    withScale,
    handleResize,
    getScaleRate,
  }
}