export enum FilterType {
  block = "block",
  urlParameter = "urlParameter",
  source = "source",
  panelRespondentStatus = "panelRespondentStatus"
}

export enum FilterConditionOperator {
  and = "and",
  or = "or",
}
export interface IReportFilterCondition {
  id: string;
  type: FilterType;
  operator: FilterConditionOperator;
  condition?: string | null;
  value?: string | undefined;
  createdAt: number;
}

export interface IBlockFilterCondition extends IReportFilterCondition {
  type: FilterType.block;
  blockId: string;
}

export interface IUrlFilterCondition extends IReportFilterCondition {
  type: FilterType.urlParameter;
  tag: string | undefined;
}

export interface ISourceFilterCondition extends IReportFilterCondition {
  type: FilterType.source;
}

export interface IPanelStatusFilterCondition extends IReportFilterCondition {
  type: FilterType.panelRespondentStatus;
}

export function isBlockFilterCondition(
  condition: IReportFilterCondition
): condition is IBlockFilterCondition {
  return condition.type === FilterType.block;
}

export function isUrlFilterCondition(
  condition: IReportFilterCondition
): condition is IUrlFilterCondition {
  return condition.type === FilterType.urlParameter;
}

export function isSourceFilterCondition(
  condition: IReportFilterCondition
): condition is ISourceFilterCondition {
  return condition.type === FilterType.source;
}

export function isPanelStatusFilterCondition(
  condition: IReportFilterCondition
): condition is IPanelStatusFilterCondition {
  return condition.type === FilterType.panelRespondentStatus;
}