import { useState } from 'react';
import { useTranslation } from 'react-i18next';

enum TabsLabels {
	HEATMAP = "Heatmap",
	CLICKS = "Clicks",
	IMAGE = "Image",
}

export const tabsLabels = {
	HEATMAP: TabsLabels.HEATMAP,
	CLICKS: TabsLabels.CLICKS,
	IMAGE: TabsLabels.IMAGE,
};

export default function useReportScreenTabs() {
	const defaultTab = (localStorage.getItem("figmaReportDefaultTab") as string) || tabsLabels.HEATMAP;
	const [activeTab, setActiveTab] = useState(defaultTab);
	const { t } = useTranslation();
	
	const tabs = Object.entries(tabsLabels).map(([, label]) => ({
		onClick: () => {
			setActiveTab(label);
			localStorage.setItem("figmaReportDefaultTab", label);
		},
		isActive: activeTab === label,
		label: t(label as TabsLabels),
	}));

	return { tabs, activeTab };
}