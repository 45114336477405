import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconClose, IconDropoff, IconHire } from '../../../../icons';
import { NodeData } from './types/pathExploration';

interface SelectedNodeDetailsProps {
  nodeData: NodeData;
  onClose: () => void;
}

export default function SelectedNodeDetails({ nodeData, onClose }: SelectedNodeDetailsProps) {
  const { t } = useTranslation();

  return (
    <div className="selected-node-details__wrapper absolute top-4 right-4 bg-white rounded-lg shadow-md border-1 border-gray-250 p-3 w-[240px] z-50 flex flex-col gap-3">
      <div className='flex items-start justify-between'>
        <div className='flex flex-col'>
          <div className='flex items-center gap-1'>
            <span className={clsx('text-xs font-medium', nodeData.isGoal ? '!text-green-500' : '!text-gray-600')}>
              {t("Step")} {nodeData.step}
            </span>
          </div>
          <h2 className="text-base+ font-medium">{nodeData.name}</h2>
        </div>
        <IconClose
          className='w-4 h-4 fill-current text-gray-700 hover:text-gray-800 cursor-pointer transition-all duration-150 ease-in-out'
          onClick={onClose}
        />
      </div>
      <img
        src={nodeData.image}
        alt={nodeData.name}
        className="w-full h-48 object-contain bg-blue-100 rounded-md p-2"
      />
      <div className='selected-node-details__stats flex flex-col gap-1 font-medium'>
        <div className="flex items-center">
          <IconHire className="w-6 h-6 mr-1 text-gray-700 fill-current" />
          <p className='text-gray-700'>
            {t("{{count}} of {{total}}", { count: nodeData.count, total: nodeData.totalVisits })}{' '}
            <span className="text-gray-700 font-normal">
              ({Number(((nodeData.count / nodeData.totalVisits) * 100).toFixed(2))}%)
            </span>
          </p>
        </div>
        {nodeData.givenUp > 0 && (
          <div className="flex items-center text-orange-600">
            <IconDropoff className="w-6 h-6 mr-1 fill-current" />
            <p>
              {t("{{count}} gave up on this step", { count: nodeData.givenUp })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
