import React from "react";
import clsx from "clsx";
import "../../tailwind.generated.css";

import Loader from "../Loader";

export default function FloatingLoader() {
  return (
    <div
      style={{ zIndex: "99990", transform: "translate(-50%, 0)", left: "50%" }}
      className={clsx(
        "toast fixed bottom-8 text-white rounded-full p-5 font-medium flex items-center justify-center bg-gray-800"
      )}
    >
      <Loader color="white" />
    </div>
  );
}