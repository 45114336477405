import { useContext, useEffect, useState } from "react";
import { TestsActions } from "../../../actions/testsActions";
import AppContext, { IActionResult } from "../../../AppContext";
import { useSendNotification } from "../../../hooks";
import { ITest } from "../../../models/Test";
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useTestsActions({
	folderId,
	testId,
	sharingToken,
}: {
	folderId?: string;
	testId?: string;
	sharingToken?: string | null;
}) {
	const { dispatch } = useContext(AppContext);
	const queryClient = useQueryClient();

	const [isTestCreating, setIsTestCreating] = useState(false);
	const [isTestUpdating, setIsTestUpdating] = useState(false);
	const [areTestsFetching, setAreTestsFetching] = useState(false);
	const [isTestFetching, setIsTestFetching] = useState(false);
	const [areTestersFetching, setAreTestersFetching] = useState(false);
	const [isBanningTester, setIsBanningTester] = useState(false);

	const setNotification = useSendNotification();

	const handleError = (e: Error) => {
		if (e instanceof DOMException && e.name === "AbortError") return;
		if ((e.cause as any)?.error_code) {
			switch ((e.cause as any).error_code) {
				case "limit_exceeded":
					setNotification("warning", "Please upgrade to Team plan to create more tests.");
					return;
			}
		}
		setNotification("error", "Oops, something went wrong");
	};

	const getTest = () => { };

	const getTestWithSharingToken = (testId: string, sharingToken: string) => {
		setIsTestFetching(true);
		TestsActions.getTestWithSharingToken(testId, sharingToken)
			.then(dispatch)
			.catch(handleError)
			.finally(() => setIsTestFetching(false));
	};

	const createTest = (userId: string, folderId: string, projectId: string, onCreated: (result: ITest) => void) => {
		setIsTestCreating(true);
		TestsActions.createTest(userId, folderId, projectId)
			.then((result) => {
				dispatch(result);
				onCreated && onCreated(Object.values(result.payload.data as Record<string, ITest>)[0] as ITest);
				queryClient.invalidateQueries({ queryKey: ['user'] });
			})
			.catch(handleError)
			.finally(() => setIsTestCreating(false));
	};

	const updateTest = () => { };

	const createTestInSplitGroup = (splitGroupId: number, name: string, projectId?: string, signal?: AbortSignal) => {
		setIsTestCreating(true);
		TestsActions.createTestInSplitGroup(splitGroupId, name, projectId, signal)
			.then(result => {
				dispatch(result);
				queryClient.invalidateQueries({ queryKey: ['user'] });
			})
			.catch(handleError)
			.finally(() => setIsTestCreating(false));
	};

	const listTests = (folderId: string, signal?: AbortSignal) => {
		setAreTestsFetching(true);
		TestsActions.listTests(folderId, signal)
			.then(dispatch)
			.catch(handleError)
			.finally(() => setAreTestsFetching(false));
	};

	const getTesters = (testId: string, sharingToken?: string) => {
		setAreTestersFetching(true);
		TestsActions.getTesters(testId, sharingToken || "")
			.then(dispatch)
			.catch(handleError)
			.finally(() => setAreTestersFetching(false));
	};

	const banTester = (testId: string, testerId: string) => {
		setIsBanningTester(true);
		return TestsActions.banTester(testId, testerId)
			.then(dispatch)
			.catch(handleError)
			.finally(() => setIsBanningTester(false));
	};

	const approveTester = (testId: string, testerId: string) => {
		setIsBanningTester(true);
		return TestsActions.approveTester(testId, testerId).then(dispatch).catch(handleError);
	};

	const duplicate = useMutation<IActionResult<{ [key: string]: ITest }>, Error, { testId: string }>({
		mutationFn: (data) => TestsActions.duplicateTest(data.testId),
		onSuccess: (result) => {
			dispatch(result);
		},
		onError: (error) => {
			handleError(error);
		}
	});

	useEffect(() => {
		const controller = new AbortController();

		if (!!folderId) listTests(folderId, controller.signal);

		return () => controller.abort();
	}, [folderId]);

	useEffect(() => {
		const controller = new AbortController();

		if (!!testId) {
			if (!!sharingToken) {
				getTestWithSharingToken(testId, sharingToken);
			} else {
				getTest();
			}
		}

		return () => controller.abort();
	}, [testId]);

	return {
		isTestCreating,
		isTestUpdating,
		areTestsFetching,
		isTestFetching,
		areTestersFetching,
		isBanningTester,
		setIsBanningTester,
		approveTester,
		getTest,
		getTesters,
		getTestWithSharingToken,
		createTest,
		updateTest,
		createTestInSplitGroup,
		listTests,
		banTester,
		duplicate,
	};
}
