import React from "react";
import { useTranslation } from "react-i18next";

import Switcher from "../../Switcher";
import { ScreenStats } from "./ScreenStats";

import { IconClickarea } from "../../../icons";

import { IArea } from '../models';


export function FigmaReportSidebar(props: {
	screenStats: any[];
	showFirstClickControls: boolean;
	showClicksOrderControls?: boolean;
	showClickAreas: boolean;
	onAreaAdded: (areas: IArea[]) => void;
	setShowOnlyFirstClicks: (val: boolean) => void;
	showOnlyFirstClicks: boolean;
	setShowClicksOrder: (val: boolean) => void;
	showClicksOrder: boolean;

	showClickAreaMode?: boolean;
	clickAreaModeOn?: boolean;
	setClickAreaModeOn?: (val: boolean) => void;
	clickAreaStats?: {
		areasWithWithTargetClicks: any[];
		testersCount: number;
	}
}) {

	const { t } = useTranslation();
	const isSettingsAvailable = props.showFirstClickControls || props.showClicksOrderControls;
	return (
		<>
			<ScreenStats stats={props.screenStats} />
			{isSettingsAvailable && (
				<div className="font-medium mt-6">{t("Settings")}</div>
			)}
			{props.showFirstClickControls && (
				<div className="mt-6 text-base font-medium text-white">
					<Switcher
						text={
							<span className="text-white">
							{t("Show only first clicks")}
							</span>
						}
						handler={props.setShowOnlyFirstClicks}
						isOn={props.showOnlyFirstClicks}
						large />
				</div>
			)}
			{props.showClicksOrderControls && (
				<div className="mt-6 text-base font-medium">
					<Switcher 
					  text={
							<span className="text-white">
							{t("Show clicks order")}
							</span>
						} 
					  handler={props.setShowClicksOrder} isOn={props.showClicksOrder} large />
				</div>
			)}
			{props.showClickAreaMode && (
				<>
					<div className="font-medium mt-6">{t("Analyze")}</div>
					<div className="mt-6 text-base font-medium">
						<Switcher
							text={<span className="flex items-center text-white">
								<IconClickarea className="mr-2 fill-current text-white" />
								{t("Draw click areas")}
							</span>}
							handler={props.setClickAreaModeOn as any}
							isOn={props.clickAreaModeOn as any}
							large />
					</div>
					{props.clickAreaModeOn && (
						<div className="text-base text-white mt-6">
							{t("To analyze a specific area, draw a rectangle on the prototype.")}{" "}
							{t("Click on area to remove.")}
						</div>
					)}
				</>
			)}
		</>
	);
}
