import lodash from "lodash";
import { IFigmaNode, IFigmaPrototype } from '../Common/schema/prototype';

export function getFigmaNodeKey(nodeId: string, version: string, ...params: any) {
  return [nodeId, version, ...params].join("-");
}

/** @deprecated From old version of figma support */
export function getNodeAsset([nodeId, version]: [string, string], source: object) {
  const defaultValue = lodash.get(source, nodeId);
  return lodash.get(source, getFigmaNodeKey(nodeId, version), defaultValue);
}

export function getNodeParams(node: IFigmaNode) {
  const {
    absoluteBoundingBox, transitionNodeID, isFixed, constraints, children, ...rest
  } = node;

  let width = absoluteBoundingBox.width;
  let height = absoluteBoundingBox.height;

  return {
    ...absoluteBoundingBox,
    ...constraints,
    width,
    height,
    transitionNodeID,
    isFixed,
    children,
    ...rest,
  } as IFigmaNode;
}

export function getOverflowParams(node: IFigmaNode) {
  const parent = getNodeParams(node);

  let x0 = parent.x;
  let x1 = parent.x + parent.width;
  let y0 = parent.y;
  let y1 = parent.y + parent.height;

  parent.children?.forEach((node) => {
    const { x, y, width, height } = getNodeParams(node);
    x0 = Math.min(x0, x);
    x1 = Math.max(x1, x + width);
    y0 = Math.min(y0, y);
    y1 = Math.max(y1, y + height);
  });

  return {
    width: x1 - x0,
    height: y1 - y0,
    scrollLeft: parent.x - x0,
    scrollTop: parent.y - y0,
  };
}

export const supportedFeaturesUrl = "https://carnelian-station-4ad.notion.site/Figma-prototypes-supported-features-92accf5242db4c898defbfc56d9a8f32";
export const figmaPluginUrl = "https://www.figma.com/community/plugin/1102613500351696592";

export function getFormattedPrototype(prototype: IFigmaPrototype) {
  const nodes = prototype.nodesForHtml;
  const images: string[] = [];

  const nodesForHtml = lodash.mapValues(nodes, (node) => getFormattedNode(prototype, nodes, images, node));

  return {
    ...prototype,
    nodesForHtml,
    images,
  };
}

function getFormattedNode(prototype: IFigmaPrototype, nodes: Record<string, IFigmaNode>, images: string[], node: IFigmaNode, parent?: IFigmaNode) {
  const result = lodash.cloneDeep(node) as IFormattedNode;

  if (node.image) {
    images.push(node.image);
  }

  if (node.overflowParams && node.overflowParams.image) {
    images.push(node.overflowParams.image);
  }

  if (result.reactions) {
    result.reactions = mapReactionsByTrigger(node.reactions as Reaction[]);

    if (hasHoverChangeTo(result)) {
      try {
        const destination = isNodeAction(result.reactions.ON_HOVER?.action) ? result.reactions.ON_HOVER?.action?.destinationId : undefined;
        const index = parent?.children?.findIndex((c: any) => c.id == destination);

        if (typeof index === 'number' && index > -1) {
          const [hoverNode] = parent?.children?.splice(index, 1) || [];

          result.onHoverChangeTo = getFormattedNode(prototype, nodes, images, hoverNode, result);
        }
        // console.log('HAS CHANGETO', result, destination, nodes[destination]);
      } catch (error) {
        console.log('ERROR', error, result, node, parent);
      }
    }
  }
  if (node.on_hover_change_to) {
    // result.on_hover_change_to = getFormattedNode(prototype, nodes, images, result.on_hover_change_to, result);

    if (!!node.on_hover_change_to.id) {
      result.on_hover_change_to = getFormattedNode(prototype, nodes, images, result.on_hover_change_to, result);
    }
    else {
      Object.keys(node.on_hover_change_to).forEach((key) => {
        result.on_hover_change_to[key] = getFormattedNode(prototype, nodes, images, node.on_hover_change_to[key], result);
      });
    }
  }
  if (node.on_click_change_to) {
    if (!!node.on_click_change_to.id) {
      result.on_click_change_to = getFormattedNode(prototype, nodes, images, result.on_click_change_to, result);
    }
    else {
      // result.on_click_change_to = {} as any;
      Object.keys(node.on_click_change_to).forEach((key) => {
        result.on_click_change_to[key] = getFormattedNode(prototype, nodes, images, node.on_click_change_to[key], result);
      });
    }
  }

  if (result.children) {
    result.children = result.children
      .filter((c: any) => prototype.hoverNodes ? !prototype.hoverNodes[c.id] : true)
      .map((child: any) => getFormattedNode(prototype, nodes, images, child, result));
  }

  if (parent) {
    // console.log('FIXING COORDS ' + result.name + ' ' + result.id, parent, result, prototype.nonClippedNodes);
    if (prototype.nonClippedNodes && prototype.nonClippedNodes[result.id]) {
      result.x = result.x - (result.absoluteTransform[0].value[2] - result.absoluteRenderBounds.x);
      result.y = result.y - (result.absoluteTransform[1].value[2] - result.absoluteRenderBounds.y);
      result.width = result.absoluteRenderBounds.width;
      result.height = result.absoluteRenderBounds.height;
    }
    else if (result.absoluteBoundingBox && result.overflowParams && result.overflowParams.image) {
      result.x = result.absoluteBoundingBox.x - parent.absoluteBoundingBox.x;
      result.y = result.absoluteBoundingBox.y - parent.absoluteBoundingBox.y;
      if (result.absoluteRenderBounds && !node.overflowParams) {
        result.width = result.absoluteRenderBounds.width;
        result.height = result.absoluteRenderBounds.height;
      }
    }
    else {
      if (result.absoluteBoundingBox) {
        result.x = result.absoluteBoundingBox.x - parent.absoluteBoundingBox.x;
        result.y = result.absoluteBoundingBox.y - parent.absoluteBoundingBox.y;
        if (!node.overflowParams) {
          result.width = result.absoluteBoundingBox.width;
          result.height = result.absoluteBoundingBox.height;
        }
      }
      else {
        result.x = result.absoluteTransform[0].value[2] - parent.absoluteTransform[0].value[2];
        result.y = result.absoluteTransform[1].value[2] - parent.absoluteTransform[1].value[2];
      }
    }
  }
  else {
    result.x = 0;
    result.y = 0;
    result.isRoot = true;
  }

  return result;
}

function mapReactionsByTrigger(reactions: Reaction[]) {
  return reactions.reduce((acc, reaction) => {
    if (isValidReaction(reaction)) {
      const triggerType = reaction.trigger?.type as AllTriggers;
      acc[triggerType] = isNodeAction(reaction.action) && reaction.action.navigation !== 'CHANGE_TO' ?
        reaction :
        (acc[triggerType] || reaction);

      acc.hasReactions = true;

      if (isNodeAction(reaction.action) && reaction.trigger?.type === 'ON_CLICK' && reaction.action?.navigation === 'NAVIGATE') {
        acc.hasClickReaction = true;
      }
    }
    return acc;
  }, { hasReactions: false, hasClickReaction: false } as IFormattedReactions);
}

function isValidReaction(reaction: Reaction) {
  return !!reaction.trigger && !!reaction.action && isNodeAction(reaction.action) && !!reaction.action.destinationId;
}

function hasHoverChangeTo(node: any) {
  return node.reactions?.hasReactions && node.reactions.ON_HOVER && node.reactions.ON_HOVER.action.navigation === 'CHANGE_TO';
}

function hasClickOverlay(node: any) {
  return node.reactions?.hasClickReaction && node.reactions.ON_CLICK.action.navigation === 'OVERLAY';
}

export type OverlayAction = NodeTypeAction & { navigation: "OVERLAY"; destinationId: string; };
export type OverlayReaction = Reaction & { action: OverlayAction };

export type NavigateAction = NodeTypeAction & { navigation: "NAVIGATE"; destinationId: string; };
export type NavigateReaction = Reaction & { action: NavigateAction };

export function isValidNavigateReaction(reaction?: Reaction | null): reaction is NavigateReaction {
  return isValidNavigateAction(reaction?.action);
}

export function isValidNavigateAction(action?: Action | null): action is NavigateAction {
  return isNodeAction(action) && action?.navigation === 'NAVIGATE' && !!action?.destinationId;
}

export function isValidOverlayReaction(reaction?: Reaction | null): reaction is OverlayReaction {
  return isValidOverlayAction(reaction?.action);
}

export function isValidOverlayAction(action?: Action | null): action is OverlayAction {
  return isNodeAction(action) && action?.navigation === 'OVERLAY' && !!action?.destinationId;
}

export function isNodeAction(action?: Action | null): action is NodeTypeAction {
  return action?.type === 'NODE';
}

export function isCloseOverlayAction(action?: Action | null): action is CloseOverlayAction {
  return action?.type === 'CLOSE';
}

export interface IFormattedNode extends IFigmaNode {
  isRoot?: boolean;
  reactions: IFormattedReactions;
}

export interface IFormattedPrototype extends IFigmaPrototype {
  nodesForHtml: Record<string, IFormattedNode>;
  images: string[];
}

export interface IFormattedReactions extends Record<AllTriggers, Reaction> {
  hasReactions: boolean;
  hasClickReaction: boolean;
}

export type Reaction = {
  action: Action | null
  trigger: Trigger | null
  context_id?: string
}

export type Action = NodeTypeAction | CloseOverlayAction;

export type NodeTypeAction = {
  readonly type: 'NODE'
  readonly destinationId: string | null
  readonly navigation: Navigation
  readonly transition: any | null
  readonly preserveScrollPosition: boolean
  readonly overlayRelativePosition?: { x: number, y: number };
  readonly resetVideoPosition?: boolean
}

export type CloseOverlayAction = { type: 'CLOSE', destinationId?: string };

export type Trigger =
  | {
    readonly type: 'ON_CLICK' | 'ON_HOVER' | 'ON_PRESS' | 'ON_DRAG'
  }
  | {
    readonly type: 'AFTER_TIMEOUT'
    readonly timeout: number
  }
  | {
    readonly type: 'MOUSE_ENTER' | 'MOUSE_LEAVE' | 'MOUSE_UP' | 'MOUSE_DOWN'
    readonly delay: number
  }
  | {
    readonly type: 'ON_KEY_DOWN'
    readonly device: 'KEYBOARD' | 'XBOX_ONE' | 'PS4' | 'SWITCH_PRO' | 'UNKNOWN_CONTROLLER'
    readonly keyCodes: ReadonlyArray<number>
  }
  | {
    readonly type: 'ON_MEDIA_HIT'
    readonly mediaHitTime: number
  }
  | {
    readonly type: 'ON_MEDIA_END'
  }

export type Navigation = 'NAVIGATE' | 'SWAP' | 'OVERLAY' | 'SCROLL_TO' | 'CHANGE_TO';

export type AllTriggers = "ON_CLICK" | "ON_HOVER" | "ON_PRESS" | "ON_DRAG" | "AFTER_TIMEOUT" | "MOUSE_ENTER" | "MOUSE_LEAVE" | "MOUSE_UP" | "MOUSE_DOWN" | "ON_KEY_DOWN" | "ON_MEDIA_HIT" | "ON_MEDIA_END";