import React, { EventHandler } from 'react';
import { IFormattedReactions, Reaction } from '../../../utils/figma';
import { IPrototypeClickEvent } from '../../../models/Figma/IClickEvent';

export const REACTION_ONHOVER_CHANGETO = {
  trigger: {
    type: 'ON_HOVER'
  },
  action: {
    type: 'NODE',
    navigation: 'CHANGE_TO'
  }
};

export const REACTION_ONCLICK_CHANGETO = {
  trigger: {
    type: 'ON_CLICK'
  },
  action: {
    type: 'NODE',
    navigation: 'CHANGE_TO'
  }
};

export const REACTION_ONCLICK = {
  trigger: {
    type: 'ON_CLICK'
  },
  action: {
    type: 'NODE',
    navigation: 'NAVIGATE'
  }
}

export interface IReactionData {
  originNodeId: string;
  status: 'ACTIVE' | 'INACTIVE';
  reaction: Reaction | null;
  handledReaction: Reaction | null;
  defaultReaction: Reaction;
}

export interface IWithClickData {
  click_data: IPrototypeClickEvent;
  detail: {
    reactionData: IReactionData
  }
}
export type PrototypeMouseEvent = React.MouseEvent & IWithClickData;
type SupportedReaction = (typeof REACTION_ONCLICK);
type ReactHandlerMap = Record<string, EventHandler<React.MouseEvent> | undefined>;
export type ReactionHandlersMap = Record<'ON_CLICK' | 'ON_HOVER', (e: PrototypeMouseEvent) => void>;

export const supportedReactions: SupportedReaction[] = [REACTION_ONCLICK, REACTION_ONHOVER_CHANGETO, REACTION_ONCLICK_CHANGETO];

export function useReactions(node: any, reactions: IFormattedReactions, horizontalCompressionCoef: number, reactionHandlersMap?: ReactionHandlersMap) {

  const eventHandlersMap: ReactHandlerMap = {
    onClick: defaultClickHandler
  };

  if (!reactions || !reactions.hasReactions) {
    return eventHandlersMap;
  }

  const supported = Object.values(reactions).filter(reaction =>
    supportedReactions.some(supportedReaction => supportedReaction.trigger.type === reaction.trigger?.type &&
      supportedReaction.action.type === reaction.action?.type));

  for (const reaction of supported) {
    if (!reaction.trigger || !reaction.action) continue;

    switch (reaction.trigger.type) {
      case 'ON_CLICK': {
        eventHandlersMap.onClick = function (e: PrototypeMouseEvent) {
          e.click_data = e.click_data || getClickData(e, node, horizontalCompressionCoef);
          setReactionData(reaction, e, 'ACTIVE');
          if (reactionHandlersMap?.ON_CLICK) reactionHandlersMap?.ON_CLICK(e);
        }
        break;
      }
      case 'ON_HOVER': {
        eventHandlersMap.onMouseEnter = function reactionsOnMouseEnter(e: PrototypeMouseEvent) {
          setReactionData(reaction, e, 'ACTIVE');
          fireReactionEvent(e);
          if (reactionHandlersMap?.ON_HOVER) reactionHandlersMap.ON_HOVER(e);
        }
        eventHandlersMap.onMouseLeave = function reactionsOnMouseLeave(e: PrototypeMouseEvent) {
          setReactionData(reaction, e, 'INACTIVE');
          fireReactionEvent(e);
          if (reactionHandlersMap?.ON_HOVER) reactionHandlersMap.ON_HOVER(e);
        }
        break;
      }
    }
  }

  return eventHandlersMap;


  function defaultClickHandler(e: PrototypeMouseEvent) {
    e.click_data = e.click_data || getClickData(e, node, horizontalCompressionCoef);
    if (reactionHandlersMap?.ON_CLICK) reactionHandlersMap?.ON_CLICK(e);
  }

  function setReactionData(reaction: Reaction, e: PrototypeMouseEvent, status: 'ACTIVE' | 'INACTIVE' = 'ACTIVE') {
    if (!e.detail.reactionData) {
      const details = {
        reactionData: {
          originNodeId: node.id,
          status,
          reaction: null as Reaction | null,
          handledReaction: null as Reaction | null,
          defaultReaction: reaction,
        } as IReactionData
      };


      if (reaction.context_id && node.reaction_context_id?.includes(reaction.context_id)) {
        details.reactionData.handledReaction = reaction;
        // HANDLE REACTION
      }
      else {
        details.reactionData.reaction = reaction;
      }
      (e as IWithClickData).detail = details;

    } else if (e.detail.reactionData.reaction?.context_id) {
      if (node.reaction_context_id?.includes(e.detail.reactionData.reaction?.context_id)) {
        e.detail.reactionData.handledReaction = e.detail.reactionData.reaction;
        // HANDLE REACTION
      }
    }
    else {
      e.detail.reactionData.reaction = reaction;
    }
  }
}

function fireReactionEvent(e: React.MouseEvent<Element, MouseEvent>) {
  const event = new CustomEvent('reaction', e);
  (event as any).originalEvent = e;
  e.currentTarget.dispatchEvent(event);
}

export function getClickData({ clientX, clientY, target }: React.MouseEvent, thisNode: any, horizontalCompressionCoef: number) {
  const { left, top } = (target as any).getBoundingClientRect();

  return {
    clickData: {
      nodeId: thisNode.id,
      x: (clientX - left) / horizontalCompressionCoef,
      y: (clientY - top) / horizontalCompressionCoef,
    },
  };
}