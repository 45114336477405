import lodash from 'lodash';

export function isWithin(value: number, min: number, max: number) {
	return value >= min && value <= max;
}

export function getHeatmapMaxWeight(clicks: any[], width: number, height: number) {
	
	const clicksWeight: Record<string, number> = {};
	clicks.forEach(({ clickData }) => {
		const x = lodash.round(clickData.left * width, -1);
		const y = lodash.round(clickData.top * height, -1);

		const key = `${x}-${y}`;

		clicksWeight[key] = lodash.get(clicksWeight, key, 0) + 1;
	});
	const maxWeight = lodash.max(lodash.values(clicksWeight)) as number;

	return maxWeight;
}