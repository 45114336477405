import React, { useState, useCallback, useMemo } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  useViewport,
  NodeProps,
  Handle,
  Position,
  NodeToolbar,
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomEdge from './CustomEdge';
import { TooltipProvider, TooltipContext } from '../TooltipContext';
import { IconFlag, IconPlay } from '../../../../icons';

import { FigmaPathExplorationProps, NodeData as FlowNodeData, EdgeData as FlowEdgeData } from './types/pathExploration';

import useFigmaFlowStats from './types/hooks/useFigmaFlowStats';

import SelectedNodeDetails from './SelectedNodeDetails';

const CustomNode: React.FC<NodeProps<FlowNodeData>> = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { zoom } = useViewport(); // Get the transform object
  const baseNodeSize = 96; // Base node size
  const nodeSize = baseNodeSize; // You can adjust this if node size changes with zoom

  // Calculate the toolbar width based on zoom level
  const toolbarWidth = nodeSize * zoom;

  return (
    <>
      <NodeToolbar
        isVisible={true}
        position={Position.Top}
        style={{ maxWidth: toolbarWidth }} // Set maxWidth based on zoom
        offset={0}
        align='start'
      >
        <div className="text-gray-600 text-xs mb-2 flex items-center justify-start w-full gap-1">
          {data.isGoal && (
            <div className="w-4 h-4 rounded-[6px] bg-green-500 flex items-center justify-center shrink-0">
              <IconFlag className="w-3 h-3 fill-current text-white" />
            </div>
          )}
          {data.isStart && (
            <div className="w-4 h-4 rounded-[6px] bg-blue-400 flex items-center justify-center shrink-0">
              <IconPlay className="w-3 h-3 fill-current text-white" />
            </div>
          )}
          <div className="text-sm font-medium truncate flex-1 text-gray-600">{data.screen.name}</div>
        </div>
      </NodeToolbar>
      <div
        className="bg-white rounded-lg ring-1 ring-gray-250 shadow-xxs relative transition-all duration-150 ease-in-out cursor-pointer hover:opacity-75"
        style={{ width: nodeSize, height: nodeSize }}
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
      >
        <Handle type="target" position={Position.Left} style={{ visibility: "hidden" }} />
        <img src={data.screen.pathImagePreview} alt={data.screen.name} className="w-full h-full object-cover object-top rounded-lg" />
        {/* Optional: Add node tooltip if needed */}
        <Handle type="source" position={Position.Right} style={{ visibility: "hidden" }} />
      </div>
    </>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const COLUMN_WIDTH = 250;
const ROW_HEIGHT = 196;
const POSITION_OFFSET_Y = 96;
const INITIAL_OPACITY = 0.35;
const MAX_STROKE_WIDTH = 72;
const MIN_STROKE_WIDTH = 1;
const EDGE_STROKE = "#5297FF";

export default function FigmaPathExploration({ testerResponses, goalScreens, startNodeId }: FigmaPathExplorationProps) {
  const [selectedNode, setSelectedNode] = useState<Node<FlowNodeData> | null>(null);

  // Utilize the custom hook to get nodes and edges data
  const { nodes: flowNodes, edges: flowEdges } = useFigmaFlowStats({
    testerResponses,
    goalScreens,
    startNodeId,
  });

  // Transform flow nodes into React Flow nodes
  const nodes: Node<FlowNodeData>[] = useMemo(() => 
    flowNodes.map((flowNode: FlowNodeData) => ({
      id: flowNode.id,
      type: "custom",
      position: { 
        x: flowNode.column * COLUMN_WIDTH, 
        y: flowNode.row * ROW_HEIGHT + POSITION_OFFSET_Y 
      },
      data: flowNode,
      draggable: false,
    })), [flowNodes]);

  // Transform flow edges into React Flow edges
  const edges: Edge[] = useMemo(() => {
    const maxEdgeCount = Math.max(...flowEdges.map(edge => edge.count), 1);
    
    return flowEdges.map((flowEdge: FlowEdgeData) => ({
      id: flowEdge.id,
      source: flowEdge.source,
      target: flowEdge.target,
      type: "custom",
      data: flowEdge,
      style: { 
        strokeWidth: Math.max(
          (flowEdge.count / maxEdgeCount) * MAX_STROKE_WIDTH, 
          MIN_STROKE_WIDTH
        ), 
        stroke: EDGE_STROKE, 
        opacity: INITIAL_OPACITY 
      },
    }));
  }, [flowEdges]);

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node<FlowNodeData>) => {
    setSelectedNode(node);
  }, []);

  return (
    <TooltipProvider>
      <div className="h-[480px] relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={onNodeClick}
          fitView
          minZoom={0.25}
          className='border-1 border-gray-250 rounded-md'
          nodesConnectable={false}
          proOptions={{ hideAttribution: true }}
        >
          <Background />
          <Controls showInteractive={false} className='rounded-md overflow-hidden border-1 border-gray-250' />
        </ReactFlow>
        {selectedNode && (
          <SelectedNodeDetails
            nodeData={selectedNode.data}
            onClose={() => setSelectedNode(null)}
          />
        )}
        {/* Render Tooltip */}
        <TooltipContext.Consumer>
          {({ tooltip }) =>
            tooltip && (
              <div
                className="fixed pointer-events-none z-50"
                style={{
                  left: tooltip.x,
                  top: tooltip.y - 20, // 20px above the cursor
                  transform: 'translate(-50%, -100%)',
                }}
              >
                <div className="bg-white border-1 border-gray-250 rounded-md shadow-xxs px-2 py-1 flex items-center">
                  {tooltip.content}
                </div>
              </div>
            )
          }
        </TooltipContext.Consumer>
      </div>
    </TooltipProvider>
  );
};