import uniqid from "uniqid";
import * as firestore from "firebase/firestore";
import { makeAuthorizedRequest, makeRequest } from "../http/Client";
import { generateVerificationCode } from "../utils";
import { db } from "../firebase";
import { ITest } from "../models/Test";
import { IActionResult } from "../AppContext";
import { pushToGTM } from "../utils/gtm";
import i18n from "../i18n/config";
import { ITimestamp } from "../models/Common";

export class UserActions {
	public static async setFigmaAuth(userId: string, code: string | null, state: string | null) {
		const body = {
			code: code,
			state: state,
		};
		const response = await makeAuthorizedRequest("/api/v1/figma/oauth/getAccessToken", "POST", body);
		if (response.ok) {
			const data = await response.json();
			return {
				type: "SET_USER_DATA",
				payload: {
				  userId: userId,
				  data: data,
				}
			}
		}
		throw new Error("http_error");
	}
}
