import lodash from 'lodash';
import { IFormattedNode } from '../../../utils/figma';

export enum FigmaDisplayMode {
	actualSize = "actualSize",
	reportView = "reportView",
	fitWidth = "fitWidth",
}

export const REAL_SIZE_MODES = [FigmaDisplayMode.actualSize, FigmaDisplayMode.reportView];

export function needDisplayInRealSize(displayMode: FigmaDisplayMode) {
	return REAL_SIZE_MODES.includes(displayMode);
}

export function needFitToContainer(displayMode: FigmaDisplayMode) {
	return !needDisplayInRealSize(displayMode);
}

/** Appends 'px' to any value  */
export function PX(value: any) {
	return `${value}px`;
}

export function bgNoRepeat(imageUrl: string) {
	return `url(${imageUrl}) 0 0 no-repeat`;
}

export const fullSizeRelative = {
	position: "relative",
	height: "100%",
	width: "100%",
}

export function getNodeCoordinates(node: any, parent: any, imageScale: number) {
	if (!parent.isRoot &&
		(hasDifferentVisibleSize(parent) && !!parent.image) &&
		node.absoluteRenderBounds &&
		parent.absoluteRenderBounds
	) {
		let nodeAbsCoords = node.absoluteBoundingBox;
		let parentAbsCoords = parent.absoluteBoundingBox;

		if (parent.width != parentAbsCoords.width || parent.height != parentAbsCoords.height) {
			nodeAbsCoords = node.absoluteRenderBounds;
			parentAbsCoords = parent.absoluteRenderBounds;
		}

		if (nodeAbsCoords && parentAbsCoords) { // && node.type != 'GROUP'
			return {
				left: PX((nodeAbsCoords.x - parentAbsCoords.x) * imageScale),
				top: PX((nodeAbsCoords.y - parentAbsCoords.y) * imageScale)
			}
		}
		else {
			return {
				left: PX(node.x * imageScale),
				top: PX(node.y * imageScale)
			}
		}
	}
	else {
		return {
			left: PX(node.x * imageScale),
			top: PX(node.y * imageScale)
		}
	}
}

function hasDifferentVisibleSize(node: any) {
	return !!node.absoluteRenderBounds && node.type == 'FRAME' &&
		(
			// (
			// node.absoluteRenderBounds.height != node.height ||
			// node.absoluteRenderBounds.width != node.width
			// ) ||
			!!node.absoluteBoundingBox &&
			(
				node.absoluteRenderBounds.height != node.absoluteBoundingBox.height ||
				node.absoluteRenderBounds.width != node.absoluteBoundingBox.width
			)
		)
}

export function hasRotation(node: any) {
	return typeof (node.rotation) == "number" && node.rotation !== 0;
}

export function getChildren(node: IFormattedNode) {
	const { numberOfFixedChildren = 0, children = [] } = node;
	
	return ({
		staticChildren: lodash.dropRight(children, numberOfFixedChildren),
		fixedChildren: lodash.takeRight(children, numberOfFixedChildren),
	});
}