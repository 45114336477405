import React from "react";

import { locale, interfaceText } from "../../../../../helpers";

import { IconPC, IconMobile } from "../../../../../icons";

const DeviceIsNotAllowed = ({ device }: { device: "mobile" | "desktop" }) => {
  const iconsMap = {
    mobile: <IconMobile className="w-8 h-8 fill-current text-gray-500" />,
    desktop: <IconPC className="w-8 h-8 fill-current text-gray-500" />,
  };

  const language = locale();

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="text-center">
        <div className="flex items-center justify-center">
          {iconsMap[device]}
        </div>
        <div className="deviceisnotallowed__title mb-4 p-6 text-gray-700">
          {
            interfaceText.deviceIsNotAllowed[
              language as keyof typeof interfaceText.deviceIsNotAllowed
            ][device]
          }
        </div>
      </div>
    </div>
  );
};

export default DeviceIsNotAllowed;
