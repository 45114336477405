import React, { useState, useContext } from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { IconHire } from '../../../../icons';
import { TooltipContext } from '../TooltipContext'; // Import TooltipContext

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  data,
}: EdgeProps) {
  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const handleMouseMove = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    const svg = event.currentTarget.ownerSVGElement;
    if (svg) {
      const point = svg.createSVGPoint();
      point.x = event.clientX;
      point.y = event.clientY;
      const screenCursor = { x: event.clientX, y: event.clientY };

      showTooltip({
        x: screenCursor.x,
        y: screenCursor.y,
        content: (
          <div className="flex items-center text-xs">
            <IconHire className="w-4 h-4 mr-1" />
            <p className="font-medium">
              {t("{{count}} of {{total}}", { count: data.count, total: data.total })}{' '}
              <span className="text-gray-700 font-normal">({data.percentage}%)</span>
            </p>
          </div>
        ),
      });
    }
  };

  const handleMouseEnter = () => {
    // Optionally, you can set initial tooltip position here
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  return (
    <g
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{ cursor: 'pointer' }}
    >
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} />
    </g>
  );
};