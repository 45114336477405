import lodash from 'lodash';
import React from 'react';
import { FigmaDisplayMode } from './NodeInHtmlUtils';
import { NodeView } from './NodeView';

export function NodeWithHover({
	isScreen, node, nodeProps, imageScale, horizontalCompressionCoef, children, onClick,
	showDefaultCursor
}: any) {
	const wrapperStyle: any = {
		width: nodeProps.style.width, //PX(Math.max(node.width, node.onHoverChangeTo.width) * imageScale),
		height: nodeProps.style.height, //PX(Math.max(node.height, node.onHoverChangeTo.height) * imageScale),
		left: nodeProps.style.left,
		top: nodeProps.style.top,
		right: nodeProps.style.right,
		bottom: nodeProps.style.bottom,
		position: 'absolute',
		overflow: 'visible'
	}

	const nodeStyle = lodash.cloneDeep(nodeProps.style);
	nodeStyle.left = "";
	nodeStyle.top = "";
	nodeStyle.right = "";
	nodeStyle.bottom = "";

	return (
		<div className="node-view__hover-wrapper" style={wrapperStyle}>
			<div
				data-id={node.id}
				data-name={node.name}
				className={`node-view__el node-view__el_hashover ${isScreen ? 'prototype-element' : 'prototype-screen'}`}
				style={nodeStyle}
				onClick={nodeProps.onClick}>
				{children}
			</div>

			<NodeView
				horizontalCompressionCoef={horizontalCompressionCoef}
				key={node.onHoverChangeTo?.id || node.on_hover_change_to.id}
				display={FigmaDisplayMode.actualSize}
				parent={node}
				imageScale={imageScale}
				node={node.onHoverChangeTo || node.on_hover_change_to}
				isHover={true}
				onClick={onClick}
				showDefaultCursor={showDefaultCursor}
			/>
		</div>
	)
}