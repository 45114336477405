import { Test } from "../../../../../utils/tests";
import lodash from "lodash";
import { makeRequest } from "../../../../../http/Client";
import { FINAL_BLOCK_ID } from "../../../../Common/Hooks/useLogic";

export function usePanelHelpers(testId: string, test?: Test, answerId?: string) {

  async function screenRespondent(nextBlockId: string) {
    if (!answerId || !test) return false;

    const response = await makeRequest(`/api/v1/tests/${testId}/answers/${answerId}/screen`, "POST", {
      screeningPassed: nextBlockId !== FINAL_BLOCK_ID
    })

    const { isRespondentScreenedIn } = await response.json();

    return isRespondentScreenedIn;
  }

  function isItScreeningBlock(blockId: string) {
    const screeningQuestions = test?.customPanelScreeningQuestions

    return screeningQuestions
      ? Boolean(lodash.find(screeningQuestions, ["blockId", blockId]))
      : false;
  }

  return {
    isItScreeningBlock,
    screenRespondent,
  } 
}