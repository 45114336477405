import axios from 'axios';

export class StorageAPI {

	public static async uploadFile(name: string, data: string | Blob, mimeType: string, fileName: string, path?: string) {
		//  convert string to blob
		const blob = new Blob([data], { type: mimeType });

		//  create formdata object
		const formData = new FormData();
		formData.append(name, blob, fileName);

		//  send request
		const response = await axios.post("/api/v1/storage/" + path, formData);
		if (response.status !== 201) {
			throw new Error("Failed to upload images");
		}
		return response.data;
	}

	public static async uploadFiles(files: { name: string, data: string | Blob, mimeType: string, fileName: string }[], path?: string) {
		const formData = new FormData();
		files.forEach(file => {
			const blob = new Blob([file.data], { type: file.mimeType });
			formData.append(file.name, blob, file.fileName);
		});

		const response = await axios.post("/api/v1/storage/" + path, formData);
		if (response.status !== 201) {
			throw new Error("Failed to upload images");
		}
		return response.data;
	}
}