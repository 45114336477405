import axios from 'axios';
import { makeRequest } from '../http/Client';
import { INativePrototypeData } from '../models/Test';

export class PrototypesAPI {
    static async fetchPrototype(protoId: string, options: { signal?: AbortSignal }) {
		const response = await makeRequest(`/api/prototypes/${protoId}`, 'GET', undefined, options.signal);
		if (response.ok) {
			const data = await response.json();
			return data;
		}
		else if (response.status === 400) {
			const error = await response.json();
			throw new Error('bad_request', { cause: error });
		}
		throw new Error('http_error', { cause: response });
	}

	static async loadFromUrl(url: string) {
		const response = await axios.get<INativePrototypeData>(url, { responseType: 'json' });
		return response.data;
	}
}