import React, { useState, useEffect, useRef, FocusEvent } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

export interface IInputProps {
  placeholder?: string;
  initialValue?: string;
  id?: string;
  className?: string;
  useCustomClassName?: boolean;
  designConfig?: any;
  singleLine?: boolean;
  initialRows?: number;
  handler?: (key: string, value: string) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement, Element>, value: string) => void;
}

export default function Input(props: IInputProps) {
  const [value, setValue] = useState(props.initialValue || "");
  const useCustomClassName = props.useCustomClassName || false;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  useEffect(() => {
    if (value !== "") {
      setValue("");
    }
  }, [props.id]);

  useEffect(() => {
    if (props.singleLine) {
      textareaRef.current?.addEventListener("keydown", PreventTextAreaEnter);

      return () => textareaRef.current?.removeEventListener("keydown", PreventTextAreaEnter);
    }
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea && !props.singleLine) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [value]);

  function onChange(e: any) {
    if (props.handler) {
      props.handler("textValue", e.target.value);
    }
    setValue(e.target.value);
  }

  function PreventTextAreaEnter(e: KeyboardEvent) {
    if (e.keyCode === 13 || e.key === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <textarea
      ref={textareaRef}
      rows={props.singleLine ? 1 : props.initialRows || 3}
      draggable="false"
      className={clsx(
        props.className,
        {
          "default-input": !useCustomClassName
        }
      )}
      style={!useCustomClassName ? { minWidth: "200px", } : undefined}
      id={props.id}
      value={value}
      placeholder={props.placeholder}
      onChange={onChange}
      onBlur={(e) => props.onBlur?.(e, value)} />
  );
}
