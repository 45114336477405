import { useState } from 'react';
import lodash from "lodash";

import { isWithin } from "../../Clickmaps/Utils";

import { IReportPrototypeClick } from "../../FigmaReport/FigmaReport";
import { IArea } from '../../models';

export function useFigmaHtmlClickAreaStats(clicks: IReportPrototypeClick[], areas: IArea[]) {
	const testersCount = lodash.uniqBy(clicks, (response) => response.answerId).length;
	// if showOnlyFirstClicks is enabled, we need to filter clicks

	const [showOnlyFirstClicks, setShowOnlyFirstClicks] = useState(false);

	const filteredClicks = showOnlyFirstClicks
		? lodash.uniqBy(clicks.filter((click) => click.number === 1), click => click.answerId)
		: clicks;

	const areasWithWithTargetClicks: IArea[] = areas.map((a) => ({ ...a, clicksCount: 0, targetClicks: [] }));

	filteredClicks.forEach((click) => {
		const clickX = click.clickData.left;
		const clickY = click.clickData.top;

		areasWithWithTargetClicks.forEach((area) => {
			if (!area.clicksCount) {
				area.clicksCount = 0;
			}

			const areaMinX = area.left;
			const areaMaxX = area.left + area.width;
			const areaMinY = area.top;
			const areaMaxY = area.top + area.height;

			if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
				if (!area.targetClicks) area.targetClicks = [];
				area.targetClicks?.push(click);
			}
		});
	});

	areasWithWithTargetClicks.forEach((area) => {
		area.clicksCount = area.targetClicks!.length;
		area.missedClicksCount = lodash.filter(area.targetClicks, ({ handled }) => !handled).length;
		area.respondentsCount = lodash.uniqBy(area.targetClicks, click => click.answerId).length;
		area.respondentsPercent = area.respondentsCount > 0 ? Math.round((area.respondentsCount / testersCount) * 100) : 0;
	});

	return {
		areasWithWithTargetClicks,
		testersCount,
		showOnlyFirstClicks,
		setShowOnlyFirstClicks,
		filteredClicks
	};
}
