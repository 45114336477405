import React from "react";
import "../../tailwind.generated.css";

import {
	useFloating,
	offset,
	FloatingPortal,
  autoPlacement
} from "@floating-ui/react";


type FirstClickPointerProps = {
  position: { left: number; top: number };
  opacity: number;
  withPopover: boolean;
  popoverContentBlock: React.ReactNode;
  imageContainerRef: React.MutableRefObject<HTMLElement | null>;
};

const 
FirstClickPointer = ({
  position,
  opacity,
  withPopover,
  popoverContentBlock,
  imageContainerRef
}: FirstClickPointerProps) => {
  
  const pointerPositionStyle = {
    left: position.left * 100 + "%",
    top: position.top * 100 + "%",
    transform: "translate3d(-50%, -50%, 0)",
  };


  const {x, y, strategy, refs, context} = useFloating({
    open: withPopover,
    middleware: [autoPlacement(), offset(10)],
  });

  return (
    <>
      <div
        style={pointerPositionStyle}
        className={`absolute bg-green-400 w-4 h-4 rounded-full z-999 opacity-${opacity}`}
        id="firstClickPointer"
        ref={refs.setReference}
      ></div>
        {withPopover && <FloatingPortal root={imageContainerRef.current}>
        <div
        ref={refs.setFloating}
          className="bg-white rounded-lg p-[6px] shadow-lg"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: "max-content",
            zIndex: 9999,
          }}
        >
          {popoverContentBlock}
        </div>
        </FloatingPortal>}
    </>
  );
};

export default FirstClickPointer;
