import { calcMean, calcMedian, msToSeconds } from "../../utils";
import { isWithin } from "../../Clickmaps/Utils";

import { IArea } from "../../models";
import { IFirstClickResponse } from "../../../../models/Response";

export default function useFirstClickAreaStats(
  clicks: IFirstClickResponse[],
  imageWidth: number,
	imageHeight: number,
){

  function  calculateStatistics(areas: IArea[]){
    const areasWithClicksCount = areas.map((a) => ({ ...a }));

    clicks.forEach((click) => {
      const clickX = click.clickData.left * imageWidth;
      const clickY = click.clickData.top * imageHeight;
  
      areasWithClicksCount.forEach((area) => {
        if (!area.clicksCount) {
          area.clicksCount = 0;
        }
  
        const areaMinX = area.left;
        const areaMaxX = area.left + area.width;
        const areaMinY = area.top;
        const areaMaxY = area.top + area.height;
  
        if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
          area.clicksCount += 1;
          if (!area.clicks) {
            area.clicks = [];
          }
          area.clicks.push(click);
        }
      });
    });

    areasWithClicksCount.forEach((area) => {
      area.respondentsPercent =
        (area.clicksCount as number) > 0 ? Math.round(((area.clicksCount as number) / clicks.length) * 100) : 0;
      if (area.clicks && area.clicks.length > 0) {
        area.medianTime = +msToSeconds(calcMedian(area.clicks.map((c) => c.clickData.responseTime)));
        area.averageTime = +msToSeconds(calcMean(area.clicks.map((c) => c.clickData.responseTime)));
      }
    });

    return areasWithClicksCount;
  };

  return {
    calculateStatistics,
  }
}