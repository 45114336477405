import React from "react";
import "../tailwind.generated.css";
import { useHistory } from "react-router-dom";

import { IconLogo } from "../icons";

export default function Logo({ clickable = true }) {
  const history = useHistory();

  if (clickable) {
    return <div
      className="hover:opacity-75 cursor-pointer"
      onClick={() => history.push("/")}
    >
      <IconLogo className="w-[28px] h-[28px]" />
    </div>;
  }

  return <IconLogo />;
}