import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SmTextAccent } from '../../../Common/Text';
import { IconSearch } from '../../../../icons';
import Input, { InputProps } from '../../../Input';
import { useTags } from '../useTags';
import { MessageTag } from './MessageTag';
import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import Loader from '../../../Loader';
import { TestBlockReportContext } from '../../ReportContext';

export function TagSelect(props: { excludeTags?: IMessageTag[] | null, onTagSelect?: (tag: Pick<IMessageTag, 'id' | 'name' | 'color'> | Pick<IMessageTag, 'name' | 'color'>) => void }) {
	const { t } = useTranslation();
	const [filter, setFilter] = useState<string>('');
	const chatReportContext = useContext(TestBlockReportContext);
	const tags = useTags({ testId: chatReportContext?.testId || '', blockId: chatReportContext?.block.blockId || '' });
	const [newTagColor, setNewTagColor] = useState<string>(tags.getNextColor());

	const filteredTags = useCallback(() => {
		const availableTags = !!props.excludeTags ?
			tags.list.data?.filter(tag => !props.excludeTags?.find(exclude => exclude.id === tag.id)) :
			tags.list.data;

		if (!filter) return availableTags;

		const search = filter.toLocaleLowerCase();
		return availableTags?.filter(tag => tag.name.toLocaleLowerCase().includes(search));
	}, [filter, tags.list.data, props.excludeTags]);

	const availableTags = filteredTags();

	const canCreateTag = !!filter && !tags.list.data?.some(tag => tag.name.toLocaleLowerCase() === filter.toLocaleLowerCase());

	useEffect(() => {
		setNewTagColor(tags.getNextColor());
	}, [canCreateTag]);

	function onCreateTagClick() {
		props.onTagSelect?.({ name: filter, color: newTagColor });
		setTimeout(() => setFilter(''), 50);
	}

	return <div className='tag-select'>
		<div className="tag-select__filter mb-2">
			<SearchInput placeholder={t("Type topic name...")} initialValue={filter} onChange={setFilter} updateFromInitialValue />
		</div>
		<div className="separator w-full h-[1px] bg-gray-200  mb-2"></div>
		<div className="tag-select__tags-list max-h-[200px] overflow-auto">
			{!!availableTags?.length && <div className='tag-select__tags-list-header sticky top-0 bg-white'>
				<SmTextAccent>{t("Topics")}</SmTextAccent>
			</div>}

			{availableTags?.map(tag => {
				return <MessageTag key={tag.id} className='cursor-pointer mt-2 max-w-[170px]' colorClassName={tags.getTagStyle(tag.color)} onClick={() => props.onTagSelect?.(tag)} >
					<div className='leading-tight'>{tag.name} &nbsp;<span className='font-semibold'>{Object.keys(tag.messages).length}</span></div>
					{tags.tagMessage.isPending && <Loader color='blue' className='mr-1' />}
				</MessageTag>
			})}

			{!!availableTags?.length && <div className="h-4 bg-gradient-to-b from-transparent to-white sticky bottom-0 pointer-events-none"></div>}
		</div>

		{canCreateTag && !!availableTags?.length && <div className="separator w-full h-[1px] bg-gray-200 my-2"></div>}
		<div className="tag-select__create">
			{!availableTags?.length && !canCreateTag && <div className='text-gray-500 text-center'>{t("No topics found")}</div>}
			{canCreateTag && <button className='create-tag-button flex flex-row items-center gap-1 mt-2' onClick={onCreateTagClick}>
				<div>{t("Create")} </div><MessageTag className='ml-1' colorClassName={tags.getTagStyle(newTagColor)} >{filter}</MessageTag>
			</button>}
		</div>
	</div>
}

function SearchInput(props: InputProps) {

	return <div className='search-input flex flex-row gap-1 items-center w-full'>
		<IconSearch className="fill-current text-gray-500 mr-1" />
		<Input
			maxLength={100}
			initialValue=""
			className={clsx("w-full rounded-none")}
			{...props}
		/>
	</div>
}