import React, { useEffect, useState, useRef } from "react";

import { useDownloadRecording } from "../hooks/useDownloadRecording";
import { formatTime } from "./utils";

import Loader from "../../Loader";
import { IconPlay, IconPause } from "../../../icons";

import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";

interface IPlayerControllerButtonsProps {
  replayer: rrwebPlayer;
  currentTime: number | undefined;
  totalTime: number | undefined;
  playerState: string | undefined;
}

const PlayerControllerButtons = ({
  replayer,
  currentTime,
  totalTime,
  playerState,
}: IPlayerControllerButtonsProps) => {
  const progressRef = useRef(null);

  if (!currentTime || !totalTime) return null;

  const formattedCurrentTime = formatTime(currentTime);
  const formattedTotalTime = formatTime(totalTime);

  const percent = Math.min(1, currentTime / totalTime);
  const percentage = `${100 * percent}%`;

  const handleProgressClick = (event: MouseEvent) => {
    const progress = progressRef.current;
    if (progress !== null && progress !== undefined && replayer !== null) {
      //@ts-ignore
      const progressRect = progress.getBoundingClientRect();
      const x = event.clientX - progressRect.left;
      let percent = x / progressRect.width;
      if (percent < 0) {
        percent = 0;
      } else if (percent > 1) {
        percent = 1;
      }
      const timeOffset = totalTime * percent;
      replayer.goto(timeOffset);
    }
  };

  return (
    <div className="px-6 pt-2 pb-6 w-full">
      <div
        className="w-full bg-gray-300 rounded-full h-2 my-2 cursor-pointer"
        ref={progressRef}
        // @ts-ignore
        onClick={handleProgressClick}
      >
        <div
          className="bg-blue-500 rounded-full h-2"
          style={{ width: percentage }}
        ></div>
      </div>
      <div className="grid items-center grid-cols-3 mt-4 -mx-1">
        <div
          className="cursor-pointer hover:opacity-50 w-6 h-6"
          onClick={() => {
            replayer.toggle();
          }}
        >
          {playerState === "playing" ? <IconPause /> : <IconPlay />}
        </div>
        <div className="text-base flex items-center justify-center text-gray-700">
          {formattedCurrentTime} / {formattedTotalTime}
        </div>
      </div>
    </div>
  );
};

interface IFigmaVideoPlayerProps {
  blockId: string;
  answerId: string;
}

const FigmaVideoPlayer = ({ blockId, answerId }: IFigmaVideoPlayerProps) => {
  const { events, error } = useDownloadRecording({ blockId, answerId });

  const [currentTime, setCurrentTime] = useState<number | undefined>();
  const [totalTime, setTotalTime] = useState<number | undefined>();
  const [playerState, setPlayerState] = useState<string | undefined>("playing");

  const containerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<rrwebPlayer | undefined>(undefined);

  useEffect(() => {
    if (playerRef.current) return;
    if (!containerRef.current || !events) return;

    playerRef.current = new rrwebPlayer({
      target: containerRef.current,
      props: {
        events,
        speedOption: [1, 2, 4],
        showController: false,
        height: (containerRef.current.parentElement?.offsetHeight || 0) - 88, 
        width: containerRef.current.parentElement?.offsetWidth
      },
    });
    playerRef.current.play();
    playerRef.current.addEventListener("ui-update-current-time", (event: any) => {
      setCurrentTime(event.payload);
    });
    playerRef.current.addEventListener("ui-update-player-state", (event: any) => {
      setPlayerState(event.payload);
    });
    const meta = playerRef.current.getMetaData();
    setTotalTime(meta.totalTime);

    if (containerRef) {
      const replayer = containerRef.current.querySelector(".rr-player");
      replayer?.classList.add("replayer");
      replayer?.classList.remove("rr-player");
    }

    const onResize = () => {
      if (playerRef.current && containerRef.current) {
        // @ts-ignore: The developers didn't add this function to the interface :( 
        playerRef.current.$set({ 
          height: (containerRef.current.parentElement?.offsetHeight || 0) - 88, 
          width: containerRef.current.parentElement?.offsetWidth 
        });
      }
    }

    window.addEventListener("resize", onResize)
    
    return () => {
      window.removeEventListener("resize", onResize)
      // @ts-ignore: The developers didn't add this function to the interface :( 
      if (playerRef.current) playerRef.current.$destroy()
    }
  }, [events]);

  return (
    <div className="h-full w-full">
      {!events && !error && <Loader />}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <div>Unable to upload video. Please try again later or message us.</div>
        </div>
      )}
      <div ref={containerRef} />
      {playerRef.current && (
        <PlayerControllerButtons
          replayer={playerRef.current}
          currentTime={currentTime}
          totalTime={totalTime}
          playerState={playerState}
        />
      )}
    </div>
  );
};

export default FigmaVideoPlayer;
