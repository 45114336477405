import React from 'react';
import clsx from 'clsx';

type BaseBadgeProps = {
  text?: string;
};

type VariantBadgeProps = {
  variant: 'beta' | 'new' | 'custom' | 'enterprise' | 'team';
} & BaseBadgeProps;

type CustomBadgeProps = {
  backgroundColor: string;
  textColor: string;
} & BaseBadgeProps;

type BadgeProps = VariantBadgeProps | CustomBadgeProps;

export default function Badge(props: BadgeProps) {
  const baseStyles = 'px-[6px] py-[3px] text-xs leading-none font-medium ml-[6px] rounded-full';
  
  let styles = baseStyles;
  let variantStyles = '';
  let displayText = '';

  if ('variant' in props) {
    const { variant } = props;
    variantStyles = clsx({
      'bg-blue-300 text-blue-800': variant === 'beta',
      'bg-green-300 text-green-800': variant === 'new',
      'bg-gray-400 text-gray-700': variant === 'enterprise',
      'bg-orange-300 text-orange-800': variant === 'team',
    });
    
    // Set specific texts for variants
    switch (variant) {
      case 'enterprise':
        displayText = 'Enterprise';
        break;
      case 'beta':
        displayText = 'Beta';
        break;
      case 'new':
        displayText = 'New';
        break;
      case 'team':
        displayText = 'Team';
        break;
      default:
        displayText = props.text || '';
    }
  } else {
    variantStyles = `${props.backgroundColor} ${props.textColor}`;
    displayText = props.text || '';
  }

  styles = `${baseStyles} ${variantStyles}`;

  return <span className={styles}>{displayText}</span>;
};