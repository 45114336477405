import { useState, useEffect } from 'react';

function useIsMobile() {
    const isMobileQuery = '(max-width: 768px)';
    const [isMobile, setIsMobile] = useState(window.matchMedia(isMobileQuery).matches);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(isMobileQuery);
        const documentChangeHandler = () => setIsMobile(mediaQueryList.matches);

        // Add listener for media query state changes using addEventListener
        mediaQueryList.addEventListener('change', documentChangeHandler);

        // Check the initial state
        documentChangeHandler();

        // Cleanup listener on component unmount using removeEventListener
        return () => mediaQueryList.removeEventListener('change', documentChangeHandler);
    }, []);

    return isMobile;
}

export default useIsMobile;