import React from "react";
import "../../tailwind.generated.css";

import Tabs, { TabItem } from "../Tabs";
import Tests from "./Tests";
import { Testers } from "./Testers";
import Users from "./Users";

const Admin = () => {
  const testId = "rAwES97u4KEu97Ym36pb";
  const blockId = "58757f8e-e33c-481d-a8e5-133492fd4c67";
  const sharingToken = "";

  return (
    <div className="container mx-auto h-full pt-6">
      <Tabs>
        <TabItem label="Tests">
          <Tests />
        </TabItem>
        <TabItem label="Users">
          <Users />
        </TabItem>
        <TabItem label="Testers">
          <Testers blockId={blockId} testId={testId} sharingToken={sharingToken} />
        </TabItem>
      </Tabs>
    </div>
  );
};

export default Admin;
