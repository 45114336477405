import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "../../tailwind.generated.css";

import Modal from "../Modal";

import { ReactComponent as IconZoom } from "../../icons/zoom.svg";
import ReportBlockHeader from "./ReportBlockHeader";

import { IPreferenceResponse } from "../../models/Response";
import { IPreferenceOptionReply } from "../../models/Test";

interface IPreferenceReportProps{
  replies: IPreferenceOptionReply[];
  responses: IPreferenceResponse[];
  questionText: string;
}

export default function PreferenceReport(props: IPreferenceReportProps) {
  const { t } = useTranslation();
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [zoomedImgUrl, setZoomedImgUrl] = useState<string | null>(null);

  const findReplyDataById = (id: string) => {
    const reply = props.replies.find((element) => element.id === id);
    return { replyValue: reply?.replyValue, image: reply?.image };
  };

  const data: Record<string, number> = {};
  const total: Record<string, number> = {};

  total.totalSelectedOptions = 0;

  props.replies.forEach((reply) => {
    data[reply.id] = 0;
  });

  props.responses.forEach((response) => {
    response.selectedOptions.forEach((option) => {
      if (!isNaN(data[option.id])) {
        data[option.id]++;
        total.totalSelectedOptions++;
      } else {
        return;
      }
    });
  });

  return (
    <div className="preference-report flex flex-col gap-4">
      <div className="preference-report__question">
        <span className="block caption">{t("Question")} </span>
        <ReportBlockHeader questionText={props.questionText} className="mb-4" />   
      </div>
      {Object.keys(data).map((key) => (
        <div key={`preference-icon-${key}`}>
          <div className="flex justify-between w-full items-center mt-2">
            <div className="flex items-center justify-center">
              <div className="preferenceOption relative">
                <div
                  className="preferenceZoomIcon absolute right-1 top-1 bg-gray-600 rounded-full p-1 hover:bg-black cursor-pointer"
                  onClick={() => {
                    setZoomedImgUrl(findReplyDataById(key).image || null);
                    setIsZoomModalOpen(true);
                  }}
                >
                  <IconZoom
                    width={16}
                    height={16}
                    className="fill-current text-white"
                  />
                </div>
                <img
                  className="rounded-md w-12 h-12 object-contain bg-gray-200"
                  src={findReplyDataById(key).image}
                  alt=""
                />
              </div>
            </div>
            <div className="flex-1 pl-6 mt-1">
              <div className="h-1 bg-gray-300 rounded-full w-full relative">
                <div
                  className="bg-purple-600 h-1 rounded-full"
                  style={{
                    width: `${(data[key] / total.totalSelectedOptions) * 100}%`,
                  }}
                ></div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="font-medium">
                  {findReplyDataById(key).replyValue}
                </div>
                <div className="font-medium">
                  {Math.floor((data[key] / total.totalSelectedOptions) * 100) +
                    "%"}
                  <span className="font-normal text-gray-600 text-xs">
                    &nbsp;({data[key]})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {isZoomModalOpen && (
        ///@ts-ignore
        <Modal isOpen={isZoomModalOpen} setIsOpen={setIsZoomModalOpen}>
          <div className="p-6 h-auto min-h-full flex items-center justify-center">
            <img src={zoomedImgUrl!} alt="" />
          </div>
          <div
            className="absolute text-white top-4 right-4 p-1 rounded-full bg-black"
            onClick={() => {
              setIsZoomModalOpen(false);
            }}
          ></div>
        </Modal>
      )}
      </div>
  );
};
