import { authorizedClient, makeRequest } from "../http/Client";
import { ITestAnswer } from "../models/Report/ITestAnswer";

export class AnswersAPI {
  static async deleteAnswer(testId: string, answerId: string) {
    const response = await authorizedClient.post(`/api/tests/${testId}/answers/${answerId}/delete`);
    return await response.data;
  }

  static async getAnswersBatch(testId: string, sharingToken: string, lastCreatedAt?: string | number | undefined, abortSignal?: AbortSignal) {
    let apiUrl = `/api/v1/tests/${testId}/answers?sharingToken=${sharingToken}`;
    if (lastCreatedAt) {
      apiUrl += `&lastCreatedAt=${new Date(lastCreatedAt).toISOString()}`;
    }

    const response = await makeRequest(apiUrl, 'GET', undefined, abortSignal);
    
    const answersBatch: {
      answers: ITestAnswer[];
      hasNextPage: boolean;
    } = await response.json();

    return answersBatch;
  }
}