import React from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";
import _ from "lodash";

import { ScaleBlock, Block } from "../../../../../models/Test";

interface ScaleSelectProps {
  blockData: ScaleBlock & Block;
  blockAnswer: {
    selectedOption: string;
  };
  handleBlockAnswer: (key: string, value: any) => void;
}

export default function ScaleSelect(props: ScaleSelectProps) {
  const {
    blockData: { replyType, from, to, selections, startLabel, endLabel },
    blockAnswer,
  } = props;

  const handleSelectOption = (value: string) => {
    props.handleBlockAnswer("selectedOption", value);
  };

  if (replyType === "numeric") {
    const options = _.range(parseInt(from), parseInt(to) + 1);

    return (
      <div className="my-2 max-w-md mx-auto">
        <div className="flex items-center justify-center shrink-0">
          {options.map((option, i) => {
            const isSelected = blockAnswer.selectedOption === option.toString();
            return (
              <div
                key={option}
                className={clsx(
                  "w-8 h-8 rounded-[8px] cursor-pointer mx-1 my-1 flex items-center justify-center transition-all duration-75 ease-in hover:border-blue-600 hover:border-2 hover:text-blue-600",
                  isSelected ? "border-blue-600 text-blue-600 border-2" : "border-gray-300 text-gray-700 border-1"
                )}
                onClick={() => handleSelectOption(option.toString())}
              >
                {option}
              </div>
            );
          })}
        </div>
        {(startLabel || endLabel) && (
          <div className="flex justify-between mt-4">
            <span className="text-gray-600 text-base">
              {parseInt(from)} — {startLabel}
            </span>
            <span className="text-gray-600 text-base">
              {parseInt(to)} — {endLabel}
            </span>
          </div>
        )}
      </div>
    );
  }

  if (replyType === "emoji") {
    const options = selections === "5"
      ? [
        { name: <>&#128544;</>, value: "-2" },
        { name: <>&#128577;</>, value: "-1" },
        { name: <>&#128528;</>, value: "0" },
        { name: <>&#128578;</>, value: "1" },
        { name: <>&#128525;</>, value: "2" },
      ]
      : [
        { name: <>&#128577;</>, value: "-1" },
        { name: <>&#128528;</>, value: "0" },
        { name: <>&#128578;</>, value: "1" },
      ];

    return (
      <div className="my-2 max-w-md mx-auto">
        <div className="flex flex-row items-center justify-center h-12">
          {options.map((option, i) => {
            const isSelected = blockAnswer.selectedOption === option.value.toString();
            return (
              <div
                key={option.value}
                className={clsx(
                  "cursor-pointer mx-3 flex items-center justify-center transition-all duration-75 ease-in text-3xl w-10",
                  !blockAnswer?.selectedOption && "hover:text-4xl",
                  isSelected && "text-4xl",
                  !isSelected && blockAnswer?.selectedOption && "opacity-50"
                )}
                onClick={() => handleSelectOption(option.value.toString())}
              >
                {option.name}
              </div>
            );
          })}
        </div>
        {(startLabel || endLabel) && (
          <div className="flex justify-between mt-2">
            <span className="text-gray-600 text-base">{startLabel && startLabel}</span>
            <span className="text-gray-600 text-base">{endLabel && endLabel}</span>
          </div>
        )}
      </div>
    );
  }

  return null;
}

