import { IActionResult } from '../AppContext';
import { ApiException } from '../Common/Exceptions/ApiException';
import { INextTestDTO } from '../DTO/SplitGroups/INextTestDTO';
import { makeAuthorizedRequest, makeRequest } from '../http/Client';
import { ISplitGroup, ITest } from '../models/Test';

const splitGroupsApiPath = '/api/v1/tests/splitgroup';
const splitGroupV1ApiPath = '/api/v1/split-group';

export type SplitGroupUpdate = Partial<Pick<ISplitGroup, 'folderId' | 'name'>>;

export enum SplitGroupErrorCodes {
	limitExceeded = "limit_exceeded",
	httpError = "http_error",
}

export class SplitGroupAPI {

	static async createSplitGroup(projectId: string, folderId: string, name: string, testIds?: string[], signal?: AbortSignal): Promise<IActionResult<ISplitGroup | any>[]> {
		const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}?projectId=${projectId}`, 'POST', {
			name: name,
			folderId,
			testIds
		}, signal);

		if (response.ok) {
			const data: { tests: ITest[], splitGroup: ISplitGroup } = await response.json();
			let testActionTYPE = "CREATE_TEST";
			let testUpdates: any[] = data.tests.map(t => ({
				type: testActionTYPE,
				payload: {
					data: {
						[t.id!]: t
					}
				}
			}));

			if (testIds?.length) {
				testActionTYPE = "UPDATE_TEST";
				testUpdates = data.tests.map(t => ({
					type: testActionTYPE,
					payload: {
						data: {
							testId: t.id,
							data: t,
						}
					}
				}));
			}
			
			return [
				{
					type: "CREATE_SPLIT_GROUP",
					payload: {
						data: data.splitGroup,
					},
				},
				...testUpdates
			];
		}
		throw new Error('http_error')
	}

	public static async duplicateSplitGroup(splitGroupId: number, signal?: AbortSignal) {
		const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}`, 'POST', {
			copyFromId: splitGroupId
		}, signal);

		if (response.ok) {
			const data: { tests: ITest[], splitGroup: ISplitGroup } = await response.json();

			return [{
				type: "CREATE_SPLIT_GROUP",
				payload: {
					data: data.splitGroup,
				},
			}, {
				type: "CREATE_TEST",
				payload: {
					data: data.tests.reduce((acc, test) => {
						acc[test.id as string] = test;
						return acc;
					}, {} as Record<string, ITest>)
				}
			}];
		}

		let errorCode: string = SplitGroupErrorCodes.httpError;

		try {
			const result = await response.json();
			errorCode = result.error_code as string;
		} catch (error) {
			console.error(error);
		}

		throw new Error(errorCode);
	}

	public static async listSplitGroups(folderId: string, projectId: string = '', signal?: AbortSignal): Promise<IActionResult<ISplitGroup[]>> {
		const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}?projectId=${projectId}&folderId=${folderId}`, 'GET', undefined, signal);

		if (response.ok) {
			return {
				type: "GET_SPLIT_GROUPS",
				payload: {
					data: await response.json() as ISplitGroup[],
				}
			};
		}
		throw new Error('http_error')
	}

	public static async updateSplitGroup(splitGroupId: number | string, projectId: string, update: SplitGroupUpdate, signal?: AbortSignal): Promise<IActionResult<ISplitGroup>> {
		const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}/${splitGroupId}?projectId=${projectId}`, 'PUT', {
			...update
		}, signal);

		if (response.ok) {
			return {
				type: "UPDATE_SPLIT_GROUP",
				payload: {
					data: await response.json() as ISplitGroup,
				},
			};
		}

		throw new Error('http_error');
	}

	public static async deleteSplitGroup(splitGroupId: number, signal?: AbortSignal): Promise<IActionResult<number>> {
		const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}/${splitGroupId}`, 'DELETE', undefined, signal);

		if (response.ok) {
			return {
				type: "DELETE_SPLIT_GROUP",
				payload: {
					data: splitGroupId
				}
			};
		}

		throw new Error('http_error');
	}

	public static async getSplitGroup(splitGroupId: number, signal?: AbortSignal): Promise<IActionResult<ISplitGroup>> {
			const response = await makeAuthorizedRequest(`${splitGroupV1ApiPath}/${splitGroupId}`, 'GET', undefined, signal);

			if (response.ok) {
				return {
					type: "GET_SPLIT_GROUP",
					payload: {
						data: await response.json() as ISplitGroup,
					},
				};
			} else {
				let error;

        try {
            error = await response.json();
        } catch (jsonError) {
            throw new Error('http_error');
        }

			  if (error.error_code === "splitgroup_not_found" || error.error_code === "access_denied"){
					throw new Error('http_error', { cause: error.error_code });
				}

				throw new Error('http_error');
			}
	}

	public static async getNextTest(splitGroupCode: string, meta: any, urlParams: Record<string, string>, panelParams: { respId: string | null, panelId: string | null }) {
		const response = await makeRequest(`${splitGroupsApiPath}/${splitGroupCode}/nexttest`, 'POST', { meta, urlParams, ...panelParams });
		if (response.ok) {
			return {
				type: "GET_NEXT_TEST",
				payload: {
					data: await response.json() as INextTestDTO,
				}
			};
		}
		else if (response.status === 400) {
			const result = await response.json();
			throw new ApiException(result.error_code, result.message, response);
		}
		throw new Error('http_error', { cause: response });
	}

}