import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Action, CloseOverlayAction, IFormattedNode, IFormattedPrototype, OverlayAction, OverlayReaction, Reaction, isNodeAction, isValidNavigateReaction, isValidOverlayReaction } from '../../../utils/figma';
import { IWithClickData, getClickData } from './useReactions';
import { IPrototypeClickEvent } from '../../../models/Figma/IClickEvent';

export interface IUsePrototypeEventsOptions {
  prototype: IFormattedPrototype;
  node: IFormattedNode;
  onPrototypeClick?: (data: IPrototypeClickEvent) => void;
  wrapper: React.RefObject<HTMLDivElement>;
  fixed: React.RefObject<HTMLDivElement>;
  sizeParams: {
    windowHeight: number;
    windowWidth: number;
    horizontalCompressionCoef: number;
    scaleRate: number;
  },
  showOverlays?: OverlayAction[]
}

export interface IOverlayInfo {
  type: 'fixed' | 'manual',
  overlay: IFormattedNode,
  reaction: Reaction,
  position?: { x: number, y: number }
}

interface IMarkedEvent {
  fromOverlay?: boolean;
  fromUnderOverlay?: boolean;
}

type IPrototypeEvent = (MouseEvent | CustomEvent) & IMarkedEvent & IWithClickData & { outsideOverlay?: boolean } & { target: HTMLElement };

export default function usePrototypeEvents(options: IUsePrototypeEventsOptions) {
  const [overlayLayers, setOverlayLayers] = useState<IOverlayInfo[]>([]);

  useEffect(showOverlays, [options.showOverlays]);

  function handlePrototypeEvent(e: IPrototypeEvent) {
    const reactionData = (e as IWithClickData).detail.reactionData;
    const reaction = reactionData?.reaction;

    const topOverlay = overlayLayers[overlayLayers.length - 1];
    const isDeactivateOverlayReaction = reactionData?.status === 'INACTIVE' && isNodeAction(reaction?.action) && reaction?.action?.destinationId === topOverlay?.overlay.id;
    const isActivateCurrenOverlayReaction = reactionData?.status === 'ACTIVE' && isNodeAction(reaction?.action) && reaction?.action?.destinationId === topOverlay?.overlay.id;
    const isEventFromUnderOpenedOverlay = !!topOverlay && !!e.fromUnderOverlay && !e.fromOverlay;
    // if click was under overlay and overlay is active
    const canHandleReaction = (!isEventFromUnderOpenedOverlay || isDeactivateOverlayReaction) && !isActivateCurrenOverlayReaction;

    const hasUnhandledReaction = !reactionData?.handledReaction && !!reactionData?.reaction;

    let prototypeEventData: IPrototypeClickEvent = {} as any;

    if (canHandleReaction && hasUnhandledReaction) {
      // If reaction is navigate reaction (go to next screen)
      if (isValidNavigateReaction(reaction)) {
        prototypeEventData.action = reaction.action;
        if (reaction.action.destinationId === options.node.id) {
          setOverlayLayers([]);
        }
      }

      // If reaction is overlay reaction (show overlay)
      if (isValidOverlayReaction(reaction)) {
        handleOverlayReaction(reactionData.status, prototypeEventData, reaction, e);
      }
    } else if (e.outsideOverlay && topOverlay.overlay.overlayBackgroundInteraction === "CLOSE_ON_CLICK_OUTSIDE") {
      const closeOverlayAction = {
        type: 'CLOSE',
        destinationId: e.click_data?.clickData.nodeId,
      } as CloseOverlayAction;
      prototypeEventData.action = closeOverlayAction;
      hideTopOverlay();
    }


    // If event has click data -- record it
    if (e.click_data && options.onPrototypeClick) {
      prototypeEventData = { ...prototypeEventData, ...e.click_data };
      options.onPrototypeClick(prototypeEventData);
    }
  }

  function handleOverlayReaction(
    reactionStatus: 'ACTIVE' | 'INACTIVE',
    prototypeEventData: IPrototypeClickEvent,
    reaction: OverlayReaction,
    e: IPrototypeEvent) {

    if (reactionStatus === 'ACTIVE') {
      prototypeEventData.action = _.cloneDeep(reaction.action);
      const overlayNode = options.prototype.nodesForHtml[reaction.action.destinationId];
      const overlayClone = _.clone(overlayNode);

      const overlayInfo: IOverlayInfo = {
        type: 'fixed',
        overlay: overlayClone,
        reaction
      };

      if (isNodeAction(reaction?.action) && reaction.action.overlayRelativePosition) {
        const hasFixedLayer = overlayLayers.some(layer => layer.type === 'fixed');
        const el = e.target as HTMLElement;
        const targetElRect = el?.getBoundingClientRect() || { x: 0, y: 0 } as any;
        const wrapperRect = (hasFixedLayer ? options.fixed : options.wrapper).current?.getBoundingClientRect() || { x: 0, y: 0 } as any;
        const position = getOverlayPosition(reaction as ReactionWithRelativeOverlay, targetElRect, wrapperRect);

        overlayInfo.type = 'manual';
        overlayInfo.overlay.overlayPosition = position;

        const clientPosition = _.cloneDeep(position);
        clientPosition.x = clientPosition.x / options.sizeParams.horizontalCompressionCoef;
        clientPosition.y = clientPosition.y / options.sizeParams.horizontalCompressionCoef;
        (prototypeEventData.action as any).position = clientPosition;
      }

      setOverlayLayers((current) => [...current, overlayInfo]);
    } else if (reactionStatus === 'INACTIVE') {
      hideTopOverlay();
    }
  }

  function showOverlays() {
    if (!options.showOverlays) return;

    const overlays = options.showOverlays.map((overlay) => {
      const overlayClone = _.cloneDeep(options.prototype.nodesForHtml[overlay.destinationId]);
      const isRelativeOverlay = isNodeAction(overlay) && !!overlay.overlayRelativePosition;
      if (isRelativeOverlay) {
        overlayClone.overlayPosition = (overlay as any).position;
      }

      const overlayInfo: IOverlayInfo = {
        type: isRelativeOverlay ? 'manual' : 'fixed',
        overlay: overlayClone,
        reaction: { action: overlay } as any
      };

      return overlayInfo;
    });

    setOverlayLayers(overlays);
  }

  function hideTopOverlay() {
    setOverlayLayers(current => current.slice(0, current.length - 1));
  }

  function onOverlayPlaceholderClick(e: any) {
    e.click_data = e.click_data || getClickData(e, options.node, options.sizeParams.horizontalCompressionCoef);
    // markFromOverlay(e);
    e.fromOverlay = true;

    if (overlayLayers.length) {
      const topOverlay = overlayLayers[overlayLayers.length - 1];
      let rect = e.target.querySelector(`div[data-id="${topOverlay.overlay.id}"]`)?.getBoundingClientRect()
      const container = topOverlay.type === 'fixed' ? options.fixed : options.wrapper;
      if (!rect) rect = container.current?.querySelector(`div[data-id="${topOverlay.overlay.id}"]`)?.getBoundingClientRect();
      if (rect &&
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom
      ) {
        e.outsideOverlay = false;
      }
      else {
        e.outsideOverlay = true;
      }
    }
  }


  return {
    handlePrototypeEvent,
    overlayLayers,
    onOverlayPlaceholderClick
  }
}

type RelativeOverlayAction = Action & Required<OverlayReaction['action']['overlayRelativePosition']>;
type ReactionWithRelativeOverlay = OverlayReaction & { action: { overlayRelativePosition: RelativeOverlayAction } };

function getOverlayPosition(
  reaction: ReactionWithRelativeOverlay,
  targetElRect: DOMRect,
  layoutRect: DOMRect
) {
  const overlayPosition = { ...reaction.action.overlayRelativePosition };
  overlayPosition.x += targetElRect.x - layoutRect.x;
  overlayPosition.y += targetElRect.y - layoutRect.y;

  return overlayPosition;
}