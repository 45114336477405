import _ from "lodash";
import { useState, useContext, useEffect } from "react";
import { useSendNotification } from "../../../hooks";

import { TestsActions } from "../../../actions/testsActions";
import { AnswersAPI } from "../../../actions/AnswersAPI";

import appContext from "../../../AppContext";

import { getPanelStatusBaseCondition } from "../Filters/Filters";

import { IReportTestData } from "../../../models/Report";
import { ITestAnswer } from "../../../models/Report/ITestAnswer";
import { PanalAnswerStatuses } from "../../../models/Report";
import { ITest } from "../../../models/Test";

import { getAnswersByBlocksWithMetadata, patchTestWithScreeningQuestions } from "../utils";
import { getTestFigmaBlockData } from "../FigmaReport/useFigmaBlockData";

export const useReport = ({
  testId,
  sharingToken,
  setFilter,
}: {
  testId: string;
  sharingToken?: string | undefined | null;
  setFilter: any;
}) => {
  const [isReportTestDataLoading, setIsReportTestDataLoading] = useState(true);
  const [isHugeReport, setIsHugeReport] = useState(false);
  const [reportTestData, setReportTestData] = useState<IReportTestData | null | undefined>(null);
  const { state, dispatch } = useContext(appContext);
  const [testData, setTestData] = useState<ITest | null>(null);
  const setNotification = useSendNotification();

  useEffect(() => {
    const fetchTestFigmaData = async () => {
      let test;

      if (sharingToken && !state.tests[testId]) {
        test = await getTestWithSharingToken(testId, sharingToken);
      } else if (state.tests[testId] && !sharingToken) {
        test = state.tests[testId];
      }

      if (test) {
        const updatedPublishedContent = await getTestFigmaBlockData(test.publishedContent);
        const updatedTest = { ...test, publishedContent: updatedPublishedContent };
        setTestData(updatedTest);
      }
    };

    fetchTestFigmaData();
  }, [testId, sharingToken, state.tests]);

  const handleError = (e: Error) => {
    if (e instanceof DOMException && e.name === "AbortError") return;
    setNotification("error", "Something went wrong while loading your report");
  };

  const getTestWithSharingToken = async (testId: string, sharingToken: string) => {
    try {
      const result = await TestsActions.getTestWithSharingToken(testId, sharingToken);
      dispatch(result);
      return result.payload.data;
    } catch (error) {
      handleError(error as Error);
    }
  };

  const addPanelReportFilter = (reportTestData: IReportTestData | undefined | null, setFilter: any) => {
    if (reportTestData) {
      const isNonCompletedPanelStatusPresent = Object.keys(reportTestData.answersMetaData).some((answerId) =>
        reportTestData.answersMetaData[answerId].panelRespondentStatus && reportTestData.answersMetaData[answerId].panelRespondentStatus !== PanalAnswerStatuses.completed
      );

      if (isNonCompletedPanelStatusPresent) {
        const panelStatusBaseCondition = getPanelStatusBaseCondition();
        setFilter((filter: any) => {
          const newFilter = _.cloneDeep(filter) as any;
          const conditionExists = Object.values(newFilter.conditions).some(
            (condition: any) => condition.type === panelStatusBaseCondition.type && condition.value === panelStatusBaseCondition.value
          );

          if (!conditionExists) {
            newFilter.conditions[panelStatusBaseCondition.id] = panelStatusBaseCondition;
          }

          return newFilter;
        });
      }
    }
  }

  const deleteAnswer = async (answerId: string) => {
    try {
      await AnswersAPI.deleteAnswer(testId, answerId);

      setReportTestData((current) => {
        if (!current) return current;
        const newReportTestData = _.cloneDeep(current);
        Object.keys(newReportTestData.answers).forEach((blockId) => {
          newReportTestData.answers[blockId].responses = newReportTestData.answers[blockId].responses.filter((answer) => answer.answerId !== answerId);
        });
        return newReportTestData;
      });
    } catch (error) {
      handleError(error as Error);
    }
  };


  const getAnswers = async (testId: string, sharingToken: string, abortSignal?: AbortSignal) => {
    const answers: ITestAnswer[] = [];
    let hasNextPage = false;
    let cursor: string | number | undefined = undefined;

    do {
      try {
        if (abortSignal?.aborted) break;

        const batch = await AnswersAPI.getAnswersBatch(testId, sharingToken, cursor);
        hasNextPage = batch.hasNextPage;
        answers.push(...batch.answers);
        cursor = _.last(answers)?.createdAt;

        if (hasNextPage && !isHugeReport) {
          setIsHugeReport(true);
        }

      } catch (error) {
        handleError(error as Error);
      }
    } while (!abortSignal?.aborted && hasNextPage)

    return getAnswersByBlocksWithMetadata(answers);
  };

  const getTestWithAnswers = async (test: ITest, testId: string, sharingToken?: string | undefined | null, abortSignal?: AbortSignal) => {
    /* 1. Getting answers */

    const answersWithMetaData = await getAnswers(testId, sharingToken || _.get(test, "sharingToken"), abortSignal);

    /* 2. Patching test data with screening questions (if applicable). Returning united data  */
    return {
      ...patchTestWithScreeningQuestions(test),
      answersMetaData: answersWithMetaData.answersMetaData,
      answers: answersWithMetaData.answers,
    };
  };

  useEffect(() => {
    if(!testData) return;
    const abortController = new AbortController();

    getTestWithAnswers(testData, testId, sharingToken, abortController.signal)
      .then((testWithAnswers) => {
        setReportTestData(testWithAnswers as any as IReportTestData);

        /* Setting Filter if report has custom panel responses */
        addPanelReportFilter(testWithAnswers as any as IReportTestData, setFilter);

        setIsReportTestDataLoading(false);
      })
      .catch(handleError);

    return () => abortController.abort();
  }, [testData]);

  return {
    isHugeReport,
    reportTestData,
    isReportTestDataLoading,
    deleteAnswer,
  };
};
