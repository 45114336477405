import React from "react";
import { ScaleFunction } from './Models';
import { IClickData } from '../../../models/Response';
import { IReportPrototypeClick } from '../FigmaReport/FigmaReport';

export interface IFigmaClicksProps {
  width: number;
  height: number;
  showClicksOrder: boolean;
  withScale: ScaleFunction;
  clicks: IReportPrototypeClick[];
}
const defaultClickData = { left: 0, top: 0, nodeId: '' };

function FigmaClicks({ width, height, clicks, withScale, showClicksOrder = false }: IFigmaClicksProps) {

  function getClickStyle(click: IClickData) {
    if (!click.clickData) click.clickData = defaultClickData;

    return {
      left: click.clickData.left + "px",
      top: click.clickData.top + "px",
      transform: "translate3d(-50%, -50%, 0)",
      width: withScale(24),
      height: withScale(24),
      fontSize: withScale(14),
      position: 'absolute'
    };
  }

  return (
    <div className="relative" style={{ width, height }}>
      {clicks?.map((click, key) => (
        <div
          key={key}
          style={getClickStyle(click) as any}
          className={`${click.handled ? 'bg-green-600' : 'bg-red-600'} rounded-full opacity-90 text-white text-base flex items-center justify-center leading-none font-medium`}
        >
          {showClicksOrder && click.number}
        </div>
      ))}
    </div>
  );
}

export default FigmaClicks;
