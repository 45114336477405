import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

type LoaderProps = {
	className?: string;
	color?: "white" | "default" | "blue" | "black";
};

const Loader = ({ className = "", color }: LoaderProps) => {
	return (
		<div
			className={clsx(
				className,
				"spinner",
				color === "white" && "spinner-white",
				color === "blue" && "spinner-blue",
				color === "black" && "spinner-black",
				(color === "default" || !color) && "spinner-default"
			)}
		>
			<div className="bounce1"></div>
			<div className="bounce2"></div>
			<div className="bounce3"></div>
		</div>
	);
};

export default Loader;
