import React from "react";
import "../../../../../tailwind.generated.css";

import Modal from "../../../../Modal";
import Button from "../../../../Button";
import { IconSuccess } from "../../../../../icons";

import { locale, interfaceText } from "../../../../../helpers";

import { motion } from "framer-motion";

import TestBranding from "./TestBranding";

function FigmaSuccessModal(props: {
  onClick: (e: React.MouseEvent | undefined) => void;
  isOpen: boolean;
  isLoading?: boolean;
}) {

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={() => { }}
      width="480px"
      disallowClickOutside
      sidebarMode
    >
      <div className="success-modal mx-auto flex flex-col gap-3 justify-between h-full">
        <div className="flex flex-col gap-6 p-5">
          <TestBranding />
          <div className="flex flex-col gap-4">
            <IconSuccess className="w-[28px] h-[28px] fill-current text-blue-400" />
            <div className="flex items-center text-lg font-medium gap-2">
              {(interfaceText as any).figmaTestingSuccess[locale()].header}
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end pb-5 px-5">
          <Button
            className="success-modal__button"
            name={(interfaceText as any).figmaTestingSuccess[locale()].button}
            handler={props.onClick}
            large
            type="primary"
            disabled={props.isLoading}
            isLoading={props.isLoading}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
}

export default FigmaSuccessModal;
