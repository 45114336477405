import React from 'react';

import { Heatmap, Clicks } from "../Clickmaps";
import { getHeatmapMaxWeight } from '../Clickmaps/Utils';
export const tabsLabels = {
	HEATMAP: "Heatmap",
	CLICKS: "Clicks",
	IMAGE: "Image",
};

export interface IClicksMapLayerProps {
	width: number;
	height: number;
	clicks: any[];
	withScale: (width: number) => number;
	activeTab: string;
}

export function ClicksMapLayerTabs(props: IClicksMapLayerProps) {
	return (
		<>
			<div
				style={{ display: props.activeTab === tabsLabels.HEATMAP ? "block" : "none" }}
			>
				<Heatmap width={props.width} height={props.height} clicks={props.clicks} maxWeight={getHeatmapMaxWeight(props.clicks, props.width, props.height)} />
			</div>
			<div
				style={{ display: props.activeTab === tabsLabels.CLICKS ? "block" : "none" }}
			>
				<Clicks width={props.width} height={props.height} clicks={props.clicks} withScale={props.withScale} />
			</div>
		</>
	);
}