import React, { useEffect } from 'react';
import { useChatReport } from './useChatReport';
import { AIBlock } from '../../../models/Test';
import { AIChatMessages } from './ReportSections/AIChatMessages';
import { BTextAccent } from '../../Common/Text';

export function AiChatReport(props: { chatId: number, testId: string, block: AIBlock }) {

	const { currentChat, setCurrentChatId } = useChatReport({ testId: props.testId, block: props.block });

	useEffect(() => {
		setCurrentChatId(props.chatId);
	}, [props.chatId]);

	return (
		<div className='ai-chat-report pt-6'>
			{!!currentChat && <AIChatMessages className='col-start-2 col-span-2 sm:col-span-4' key={currentChat.id} messages={currentChat.messages || []} showScrollPadding />}
		</div>
	);
}