import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { makeAuthorizedRequest, makeRequest } from '../../../http/Client';

export function useReportConfig(options: { testId: string, blockId?: string, sharingToken?: string }) {
	const queryClient = useQueryClient();

	type ReportConfigData = {
		report: any,
		blocks: Record<string, any>
	} | null;

	const reportConfig = useQuery<ReportConfigData>({
		queryKey: ['config/reports', options.testId],
		queryFn: async () => {
			const response = await (options.sharingToken ? makeRequest : makeAuthorizedRequest)(`/api/v1/report/${options.testId}/config?sharingToken=${options.sharingToken}`, 'GET');
			return response.json();
		},
		staleTime: Infinity,
		enabled: !!options.testId
	});

	// типизация запроса на обновление конфига
	type UpdateConfigRequest = { config: any, blockType: string } | null;

	const updateBlockConfig = useMutation<{ result: string }, Error, UpdateConfigRequest>({
		mutationFn: async (update: UpdateConfigRequest) => {
			const response = await makeAuthorizedRequest(`/api/v1/report/${options.testId}/${options.blockId}/config`, 'PUT', update);
			return response.json();
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['config/reports', options.testId] });
		},
	});

	return {
		reportConfig,
		updateBlockConfig
	}
}