import React from "react";
import { useTranslation } from "react-i18next";

import PluginInstructions from "./PluginInstructions";
import FigmaImportWithCode from "./FigmaImportWithCode";
import FigmaImportWithLink from "./FigmaImportWithLink";
import Tabs, { TabItem } from "../../../Tabs";
import { isGlobalApp } from '../../../../helpers';

type FigmaImportProps = {
	blockId: string;
	onSubmitCodeImport: (value: { prototypeId: string }) => void;
	updateTestBlock: (data: (block: any) => any, blockId: string) => void;
	setIsImportModalOpen: (value: boolean) => void;
}

const FigmaImport = ({ blockId, onSubmitCodeImport, updateTestBlock, setIsImportModalOpen }: FigmaImportProps) => {
	const { t } = useTranslation();
	
	return (
		<div className="p-5">
			<span className="text-2xl font-medium block mb-2">{t("Import your prototype")}</span>
			<Tabs>
				<TabItem
					label={
						<>
							<span>{t("From link")}</span>
						</>
					}
				>
					<FigmaImportWithLink
						blockId={blockId}
						updateTestBlock={updateTestBlock}
						setIsImportModalOpen={setIsImportModalOpen}
					/>
				</TabItem>
				{isGlobalApp() ? <></> : <TabItem label={t("From import code")}>
					{/* <span className="block my-2 text-xl font-bold font-display">{t("Import your prototype")}</span> */}
					<FigmaImportWithCode onSubmit={onSubmitCodeImport} />
				</TabItem>}
			</Tabs>
		</div>
	);
};

export default FigmaImport;
