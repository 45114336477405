import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import { IconDropdown } from "../icons";

import Dropdown, { Divider, DropdownOption } from "./Dropdown";
import Loader from "./Loader";

export interface TextDropdownProps {
  title?: string;
  small?: boolean;
  large?: boolean;
  position?: string;
  items: (DropdownOption | Divider)[];
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string | JSX.Element;
  className?: string;
  isLoadingValue?: boolean;
  light?: boolean;
  parentTestId?: string;
};

function TextDropdown(props: TextDropdownProps) {
  const {
    title = "",
    small,
    large = false,
    items,
    onChange,
    value,
    placeholder,
    isLoadingValue = false,
    light,
    parentTestId
  } = props;

  const getItemNamebyValue = React.useMemo(() => {
    return (value: string | undefined) => {
      const selectedItem = props.items.find((item) => typeof item !== 'string' && item.value === value) as DropdownOption | undefined;
      // console.log('VALUE getItemNamebyValue', value, selectedItem);

      const selectedItemName = selectedItem?.name;
      if (!selectedItemName) {
        return props.placeholder;
      } else {
        return selectedItemName;
      }
    };
  }, [props.items, props.value]);

  const isEmpty = value === "" || value === null || value === undefined;

  return (
    <div className={props.className}>
      {title && <div className="caption">{title}</div>}
      <Dropdown
        parentTestId={parentTestId && `${parentTestId}.dropdown`}
        type="single"
        items={items}
        handler={onChange}
        value={value ? value : undefined}
        light={light}
      >
        <div
          className={clsx("flex items-center justify-between default-select", small ? "default-input-small" : "default-input")}
          data-testid={parentTestId && `${parentTestId}.dropdown`}
        >
          <div
            className={clsx(
              items.length === 0 && "cursor-not-allowed",
              large && "text-xl",
              small && "text-base",
              !large && !small && "text-base",
              isEmpty && "text-gray-500",
              items.length !== 0 && "cursor-pointer",
              "min-w-0 overflow-hidden text-ellipsis whitespace-nowrap"
            )}
          >
            {isLoadingValue ?
              <Loader className='py-[9px]' /> :
              <p className="dropdown__item--selected overflow-hidden whitespace-nowrap text-ellipsis w-full min-w-0 max-w-full"
                title={typeof getItemNamebyValue(value) === 'string' ? getItemNamebyValue(value) as string : ''}>
                {getItemNamebyValue(value)}
              </p>
            }
          </div>
          <IconDropdown
            className="ml-1 cursor-pointer text-gray-600 fill-current shrink-0 w-4 h-4" />
        </div>
      </Dropdown>
    </div>
  );
}

export default TextDropdown;
