import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import "../../tailwind.generated.css";

import { IconSearch } from "../../icons";

import Input from "../Input";

type SearchInputProps = {
  placeholder?: string;
  id: string;
  className?: string;
  onChange: (value: string) => void;
};

const ResponsesSearchInput = ({ placeholder, onChange, id, className }: SearchInputProps) => {
  const [focus, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    const isMac = navigator.userAgent.indexOf("Mac OS X") != -1;
    const isCtrlOrCmd = isMac ? e.metaKey : e.ctrlKey;

    if (isCtrlOrCmd && e.key === "k") {
      e.preventDefault();
      const searchEl = document.getElementById(id);
      searchEl?.focus();
      searchEl?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
<div
      className={clsx(
        "flex items-center px-2 py-0.5 rounded-lg transition-all duration-75 ease-in hover:bg-gray-400 mb-6",
        focus ? "border-blue-600" : "border-transparent",
        className && className
      )}
    >
      <IconSearch className="fill-current text-gray-600 mr-1" />
      <Input
        initialValue=""
        className={clsx("w-full rounded-none")}
        placeholder={placeholder || "Search"}
        id={id}
        onChange={(value: string) => {
          onChange(value);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
          setIsOpen(false);
        }}
        selectAllOnFocus={false}
      />
    </div>
  );
};

export default ResponsesSearchInput;
