import React from "react";
import { t } from "i18next";

import ClickareaDataLabel from "./ClickareaDataLabel";

import { IconCursor, IconTime } from "../../../icons";

import { IArea } from "../models";

export function FirstClickareaData(props: { area: IArea }) {
  const { area } = props;

  return (
    <>
      <ClickareaDataLabel>
        <IconCursor className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Clicks")}: {area.clicksCount} ({area.respondentsPercent}%)</span>
      </ClickareaDataLabel>
      {!!area.clicksCount &&
        <>
          <ClickareaDataLabel>
            <IconTime className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Average")}: {area.averageTime}{t("s")}</span>
          </ClickareaDataLabel>
          <ClickareaDataLabel>
            <IconTime className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Median")}: {area.medianTime}{t("s")}</span>
          </ClickareaDataLabel>
        </>
      }
    </>
  );
}