import React from 'react';
import { RadioProps, useRadio } from "@nextui-org/radio";
import clsx from 'clsx';

type RadioButtonProps = {
  children: React.ReactNode;
  description?: string;
} & RadioProps;

const RadioButton = (props: RadioButtonProps) => {
  const {
    Component,
    children,
    description,
    isSelected,
    getBaseProps,
    getInputProps,
    getLabelWrapperProps,
  } = useRadio(props);

  return (
    <Component
      {...getBaseProps()}
      className={clsx(
        "flex p-2 transition-all duration-150 ease-in-out",
        "cursor-pointer border-1 rounded-lg",
        isSelected ? "border-blue-600 ring-2 ring-blue-200" : "border-default"
      )}
    >
      <input {...getInputProps()} />

      <span className={clsx(
        "w-[18px] h-[18px] min-w-[18px] min-h-[18px] m-[5px] bg-white block rounded-full",
        isSelected ? "border-6 border-blue-600" : "border-default"
      )} />

      <div className="ml-2 flex flex-col items-start justify-start py-1">
        {children && (
          <span className={clsx("font-medium text-base block", !isSelected && "text-gray-700")}>
            {children}
          </span>
        )}
        {description && (
          <span className="text-gray-700 text-base block">
            {description}
          </span>
        )}
      </div>
    </Component>
  );
};

export default RadioButton;