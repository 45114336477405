import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeAuthorizedRequest, makeRequest } from '../../../http/Client';
import { FigmaFlowReplayer } from './replay';
import clsx from 'clsx';
import Loader from '../../Loader';
import { IconPause, IconPlay, IconPointerDesktop } from '../../../icons';

import { isMobile } from '../utils';
import { CanvasSize } from '../../../models/Response';
import { IRecordEvent } from './types';


export default function NativePrototypeVideoPlayer(props: {
  recordingId: string,
  sharingToken?: string,
  pixelRatio: number,
  size?: CanvasSize,
  userAgent: string | undefined,
}) {
  // ref for pointer
  const pointerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isPlayerLoading, setIsPlayerLoading] = useState(true);

  const videoUrl = `/api/v1/answers/${props.recordingId}/video?sharingToken=${props.sharingToken}`;

  // вычисляем масштаб на основе размера канваса, т.к. иногда соотношение размеров записи видео и координат может отличаться. Например видео=Х2, а координаты=Х3
  const actualPixelRatio = props.size ? props.size.canvasRealSize.width / props.size.canvasDisplaySize.width : props.pixelRatio;

  const clicksQuery = useQuery({
    queryKey: ['clicks', props.recordingId],
    queryFn: () => makeRequest(`/api/v1/answers/${props.recordingId}/clicks?sharingToken=${props.sharingToken}`).then(res => res.json() as Promise<{ events: IRecordEvent[][], eventsTS: number, eventsStopTs: number, videoTS: number, videoStopTs: number }>),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const clicks = (clicksQuery.data?.events || clicksQuery.data || []) as IRecordEvent[][];
  const startTs = clicksQuery.data?.eventsTS || 0;
  const endTs = clicksQuery.data?.eventsStopTs || 0;
  const player = useRef<{ replayer: FigmaFlowReplayer | null }>({ replayer: null });
  let indentX = 0;
  let indentY = 0;
  if (props.size && props.size.bodySize?.width > props.size.canvasDisplaySize.width) {
    // calculate indent from left side
    indentX = (props.size.bodySize.width - props.size.canvasDisplaySize.width) / 2;
  }
  if (props.size && props.size.bodySize?.height > props.size.canvasDisplaySize.height) {
    // calculate indent from top side
    indentY = (props.size.bodySize.height - props.size.canvasDisplaySize.height) / 2;
  }

  useEffect(() => {
    if (clicks.length === 0) return;
    if (!pointerRef.current) return;
    if (!videoRef.current) return;

    const replayer = new FigmaFlowReplayer(videoRef.current, pointerRef.current, { pixelRatio: actualPixelRatio, isMobile: isMobile(props.userAgent) });
    replayer.replay(clicks, indentX, indentY, startTs, endTs, videoUrl || "")

    player.current.replayer = replayer;
  }, [clicks, pointerRef.current, videoRef.current]);

  useEffect(() => {
    if (!containerRef.current) return;
    if (!videoRef.current) return;

    videoRef.current.addEventListener('loadedmetadata', (e) => {
      const scaledVideoWidth = videoRef.current!.videoWidth / actualPixelRatio;
      const scaledVideoHeight = videoRef.current!.videoHeight / actualPixelRatio;

      const containerWidth = containerRef.current!.getBoundingClientRect().width;
      const containerHeight = containerRef.current!.getBoundingClientRect().height;
      const scaleWidth = containerWidth / scaledVideoWidth;
      const scaleHeight = containerHeight / scaledVideoHeight;
      const scale = (scaleHeight < 1 || scaleWidth < 1) ? Math.min(scaleWidth, scaleHeight) : 1;

      wrapperRef.current!.style.transform = `scale(${scale}) translate(-50%, -50%)`; //
      wrapperRef.current!.style.width = `${scaledVideoWidth}px`;
      wrapperRef.current!.style.height = `${scaledVideoHeight}px`;

      setIsPlayerLoading(false);
    });

  }, [containerRef.current, videoRef.current]);

  return (
    <div className="w-full h-full">
      {isPlayerLoading && <Loader />}
      <div className={clsx("w-full h-full relative", isPlayerLoading ? "invisible" : "visible")}>
        <div
          id="replayContainer"
          className="video-replayer relative overflow-hidden bg-black"
          style={{ height: "calc(100% - 96px)" }}
          ref={containerRef}
        >
          <div
            className="video-replayer__wrapper absolute"
            ref={wrapperRef}
            style={{
              top: "50%",
              left: "50%",
              transformOrigin: "left top",
            }}
          >
            <video className="video-replayer__video" ref={videoRef} preload='auto'>
              <source src={videoUrl} type="video/webm" />
            </video>
            <div className='pointer-layer absolute w-full h-full top-0 left-0'>
              <Pointer pointerRef={pointerRef} isMobile={isMobile(props.userAgent)} />
            </div>
          </div>
        </div>
        {!isPlayerLoading && <>
          <div className="flex items-center mt-8 px-6 gap-4">
            <PlayPauseButton videoRef={videoRef} />
            <SeekBar videoRef={videoRef} />
          </div>
          <TimeDisplay videoRef={videoRef} />
        </>}
      </div>
    </div>
  );
}

type PlayPauseButtonProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

const PlayPauseButton = ({ videoRef }: PlayPauseButtonProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const handlePause = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    const videoElement = videoRef.current;
    videoElement?.addEventListener('ended', handlePause);
    videoElement?.addEventListener('pause', handlePause);
    videoElement?.addEventListener('play', handlePlay);

    return () => {
      videoElement?.removeEventListener('ended', handlePause);
      videoElement?.removeEventListener('pause', handlePause);
      videoElement?.removeEventListener('play', handlePlay);
    };
  }, [videoRef]);

  useEffect(() => {
    const handleSpaceKey = (e: KeyboardEvent) => {
      if (e.key === ' ') togglePlayPause();
    };
    window.addEventListener('keypress', handleSpaceKey);
    return () => window.removeEventListener('keypress', handleSpaceKey);
  }, []);

  const togglePlayPause = () => {
    if (videoRef.current!.paused) {
      videoRef.current!.play();
    } else {
      videoRef.current!.pause();
    }
  };

  return (
    <button onClick={togglePlayPause}>
      {isPlaying ? <IconPause className="fill-current text-gray-800 hover:opacity-75 transition-opacity" /> : <IconPlay className="fill-current text-gray-800 hover:opacity-75 transition-opacity" />}
    </button>
  );
};

type SeekBarProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
};


const SeekBar = ({ videoRef }: SeekBarProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const updateValue = () => {
      setValue(videoRef.current!.currentTime / videoRef.current!.duration);
    };

    videoRef.current?.addEventListener('timeupdate', updateValue);
    return () => videoRef.current?.removeEventListener('timeupdate', updateValue);
  }, [videoRef]);

  const changeValue = (e: any) => {
    videoRef.current!.currentTime = videoRef.current!.duration * e.target.value;
    setValue(e.target.value);
  };

  const progress = value * 100;

  return (
    <input
      type="range"
      min="0"
      max="1"
      step="any"
      className="video-seek-bar w-full cursor-pointer video-replayer__seek-bar h-2"
      value={value}
      onChange={changeValue}
      style={{
        background: `linear-gradient(to right, #0066FF 0%, #0066FF ${progress}%, #F0F0F0 ${progress}%, #F0F0F0 100%)`,
      }}
    />
  );
};

type TimeDisplayProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

const TimeDisplay = ({ videoRef }: TimeDisplayProps) => {
  const [currentTime, setCurrentTime] = useState(0);

  const duration = videoRef.current?.duration;

  useEffect(() => {
    const updateTime = () => {
      setCurrentTime(videoRef.current!.currentTime);
    };

    videoRef.current!.addEventListener('timeupdate', updateTime);
    return () => videoRef.current?.removeEventListener('timeupdate', updateTime);
  }, [videoRef]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='video-timer w-full text-xs text-center'>
      {formatTime(currentTime)} / {!duration ? '--:--' : formatTime(duration)}
    </div>
  );
};

type PointerProps = {
  pointerRef: any;
  isMobile: boolean;
};

const Pointer = ({ pointerRef, isMobile }: PointerProps) => {

  if (!isMobile) {
    return (
      <IconPointerDesktop className="video-replayer__pointer w-8 h-8 -m-4 absolute opacity-0" id="pointer" ref={pointerRef} />
    )
  }

  return (
    <div
      className="video-replayer__pointer w-6 h-6 rounded-full bg-red-500 -m-3 absolute transition-all ease-linear duration-[10ms] opacity-0"
      id="pointer"
      ref={pointerRef}
    ></div>
  )
}