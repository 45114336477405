import clsx from 'clsx';
import React, { PropsWithChildren } from "react";
import Button from '../../../Button';

export interface ButtonProps extends PropsWithChildren<{ disabled?: boolean; onClick?: () => void; className?: string; dataTestId?: string; }> { }

export function SignInButton(props: ButtonProps) {

  return (
    <button
      className={clsx("my-2 py-2 px-5 rounded-full object-center mx-auto text-xl font-medium flex items-center transition-all duration-150 ease-in",
        {
          "bg-white hover:bg-gray-100 text-gray-800 cursor-pointer": !props.disabled,
          "bg-gray-300 text-gray-600 cursor-not-allowed": !!props.disabled
        },
        props.className
      )}
      onClick={props.onClick}
      disabled={props.disabled}
      data-testid={props.dataTestId}
    >
      {props.children}
    </button>
  );
}
