import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import appContext from "../../../../AppContext";

import { ReactComponent as IconWave } from "../../../../icons/wave.svg";
import TextDropdown from "../../../TextDropdown";
import SmartInput from "../../../SmartInput";
import Button from "../../../Button";
import Logo from "../../../Logo";
import { setUserData } from "../../../../actions";
import SignOut from "../../../SignOut";
import { useTranslation } from "react-i18next";

const Questionnaire = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(appContext);
  const history = useHistory();

  const [role, setRole] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [source, setSource] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(state.user.displayName);

  const questionnaireValidator = () => {
    return role?.length && company?.length && source?.length && name?.length;
  };

  const handleQuestionnaireSubmit = async () => {
    dispatch(
      await setUserData(state.user.userId, {
        displayName: name,
        questionnaireData: {
          role: role,
          company: company,
          source: source,
        },
        questionnaireCompleted: true,
      })
    );
    window.Intercom('update', { isQuestionnaireCompleted: true });
  };

  return (
    <div className="container mx-auto flex flex-col min-h-full pb-20">
      <div className="container mx-auto flex pt-10 pb-10 items-center justify-between px-6">
        <Logo clickable={false} />
        <SignOut />
      </div>
      <div className="container mx-auto h-full flex justify-center items-center flex-1">
        <div className="max-w-[580px]">
          <div>
            <span className="flex">
              <IconWave className="fill-current text-green-700 mr-2" width={32} height={32} />
              <div className="font-bold font-display text-3xl leading-tight">{t("Hi, nice to meet you.")}</div>
            </span>
            <div className="font-bold font-display text-3xl leading-tight">{t("Please, tell us a bit more about yourself:")}</div>
          </div>
          <div>
            <div className="my-4">
              <span className="block caption">{t("What is your name?")}</span>
              <SmartInput
                id={"questionnaire_name"}
                className="w-full default-input"
                initialValue={name || undefined}
                placeholder={t("Enter your name here")}
                onChange={(value) => {
                  setName(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="block caption">{t("Your current role")}</span>
              <TextDropdown
                className="w-64"
                items={[
                  { name: t("UX Researcher"), value: "researcher" },
                  { name: t("Product Designer"), value: "designer" },
                  { name: t("Product Manager"), value: "product" },
                  { name: t("Marketing specialist"), value: "marketing" },
                  { name: t("Other"), value: "other" },
                ]}
                value={role || undefined}
                placeholder={t("Choose your role")}
                onChange={(value) => {
                  setRole(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="block caption">{t("Where do you work?")}</span>
              <SmartInput
                id={"questionnaire_company"}
                className="w-full default-input"
                initialValue={""}
                placeholder={t("Enter name of the company here")}
                onChange={(value) => {
                  setCompany(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="block caption">{t("How did you know about Pathway?")}</span>
              <SmartInput
                id={"questionnaire_source"}
                className="w-full default-input"
                initialValue={""}
                placeholder={t("How did you find us?")}
                onChange={(value) => {
                  setSource(value);
                }}
              />
            </div>
            <Button
              className="mt-8"
              id="submitQuestionnaire"
              disabled={!questionnaireValidator()}
              type="primary"
              fullWidth
              name={t("Continue")}
              large
              handler={async () => {
                await handleQuestionnaireSubmit();
                history.push("/");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
