import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import { useAuth } from "../../hooks";

export default function PageNotFound() {
  const { t } = useTranslation();
  const history = useHistory();
  const userAuth = useAuth();

  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  useEffect(() => {
    if (userAuth) {
      setIsAuthorized(true);
    }

    if (userAuth === false) {
      setIsAuthorized(false);
    }
  }, [userAuth]);

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="text-center">
        <div className="mb-4 text-3xl">{t("Page Not Found")}</div>
        <div>{t("Sorry, nothing here")}</div>
        {isAuthorized && (
          <Button
            className="mt-8"
            fullWidth
            name={t("Go to homepage")}
            type="primary"
            handler={() => history.push(`/`)}
          />
        )}
      </div>
    </div>
  );
}