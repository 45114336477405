import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

/**
 * Рендретит список с разделителями (серой полоской между элементами списка)
 * @param props В качестве настройки можно указать отступы между полоской и элементом списка в параметре indent
 * @returns 
 */
export function ListWithSeparators(props: PropsWithChildren<{ className?: string; indent?: number; }>) {
	const itemVIndent = props.indent || 3;

	return <div className={clsx("items-list")}>
		{React.Children.toArray(props.children)?.map((child, i) => <>
			{i > 0 && <Separator key={i + 'sep'} className={clsx("")} />}
			<div key={i} className={clsx("items-list__item", {
				"py-4": itemVIndent === 4,
				"py-3": itemVIndent === 3,
				"py-2": itemVIndent === 2,
				"py-1": itemVIndent === 1,
			})}>{child}</div>
		</>)}
	</div>;
}

export function Separator(props: { className?: string }) {
	return <div className={clsx("separator h-[1px] bg-gray-200", props.className)} />
}
