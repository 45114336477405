import React, { useState, useContext } from "react";
import { makeAuthorizedRequest } from "../../../../http/Client";

import AppContext from "../../../../AppContext";

import { Trans } from "react-i18next";

import Button from "../../../Button";
import Input from "../../../Input";
import Banner from "../../../Banner";

import { IconFigma } from "../../../../icons";
import { checkIfValidFigmaPrototypeLink } from "../../utils";
import usePrototypeImport from "../../hooks/usePrototypeImport";
import { useSendNotification } from "../../../../hooks";
import { useAccount } from '../../../UserAccount/Hooks/useAccount';
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/config";

import { Timestamp } from "../../../../firebase";
import { getHelpCenterLink } from "../../../../utils/help";

type FigmaImportWithLinkProps = {
  blockId: string;
  updateTestBlock: (data: (block: any) => any, blockId: string) => void;
  setIsImportModalOpen: (value: boolean) => void;
}

const FigmaImportWithLink = ({ blockId, updateTestBlock, setIsImportModalOpen }: FigmaImportWithLinkProps) => {
  const { t } = useTranslation();
  const account = useAccount();
  const sendNotification = useSendNotification();

  const [figmaPrototypeLink, setFigmaPrototypeLink] = useState<string>("");

  const figmaAuthToken = account.currentUser.data?.user.figmaAuthToken;
  const figmaAuthTokenExpiresAt = account.currentUser.data?.user?.figmaAuthTokenExpiresAt;

  const { importPrototype, isImporting, error } = usePrototypeImport({ onPrototypeImported, sendNotification });

  const handleRedirectToFigma = async () => {
    localStorage.removeItem("figmaConnectAppRedirectUrl");
    const appRedirectUrl = new URL(window.location.href);
    appRedirectUrl.searchParams.set("blockId", blockId);
    localStorage.setItem("figmaConnectAppRedirectUrl", appRedirectUrl.toString());
    const response = await makeAuthorizedRequest("/api/v1/figma/oauth/getAuthRedirectUrl");
    const data = await response.json();
    window.location = data.redirectUrl;
  };

  const url = new URL(window.location.href);
  const figmaConnected = url.searchParams.get('figmaConnected');
  const connectionError = figmaConnected === 'false';

  async function onImportPrototypeClick() {
    if (!figmaAuthToken) return;
    await importPrototype(figmaPrototypeLink, figmaAuthToken);
  }

  async function onPrototypeImported(result: any) {
    updateTestBlock((block: any) => ({ ...block, ...result, importedAt: Timestamp.now() }), blockId);
    setIsImportModalOpen(false);
  }

  if (!figmaAuthToken) {
    return (
      <div className="w-full flex flex-col my-4">
        {connectionError && <Banner type="error" header={t("We failed to connect your Figma account")} text={t("We weren't able to connect your account. Please, try again.")} className="mt-4" />}
        <IconFigma className="w-12 h-12 mx-auto" />
        <div className="text-center font-medium mt-4">{t("Connect your Figma account")}</div>
        <div className="text-center text-gray-700 mt-2 max-w-sm mx-auto">{t("We need to connect to your Figma account to import your prototype.")}</div>
        <Button
          name={t("Continue")}
          className="mt-4 mx-auto"
          type="primary"
          handler={handleRedirectToFigma}
        />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <Banner
        type="info"
        header={t("How to import your Figma prototype?")}
        text={
          <>
            <p>
              <Trans i18nKey="Before importing your prototype: please, use a dedicated file with a single page, remove all of the unnecessary elements.">
                Before importing your prototype: please, use a dedicated file with a single page, remove all of the
                unnecessary elements.
              </Trans>
            </p>
            <p>
              <span className="text-base block mt-4">
                {t("Your file permissions should be set to")}
                <span className="font-medium text-gray-900"> {t("Anyone with the link")}</span> {t("and")}{" "}
                <span className="font-medium text-gray-900">{t("Can view")}</span>.
              </span>
            </p>
            <a
              href={getHelpCenterLink(t("/articles/8935073-how-to-prepare-a-figma-prototype-for-import-via-a-link"))}
              type="default"
              className="mt-4 block"
              target="_blank"
              rel="noreferrer"
            >
              {t("Tips: how to prepare your Figma prototype? ↗")}
            </a>
          </>
        }
        className="mb-4"
      />
      <div className="flex items-center justify-start my-2">
        <IconFigma className="w-4 h-4 mr-1" />
        <span className="block font-medium">{t("Prototype link")}</span>
      </div>
      <Input
        initialValue={figmaPrototypeLink}
        className="default-input"
        id={`${blockId}_figmaPrototypeLink`}
        placeholder={t("Paste your prototype link here")}
        onChange={(value: string) => {
          setFigmaPrototypeLink(value);
        }}
        disabled={isImporting}
      />
      {error && (
        <Banner
          type="error"
          header={t("We failed to import your prototype")}
          text={
            <Trans i18nKey={(error as any) || "We weren't able to import your prototype. Please, try again."}>
              We weren't able to import your prototype. Please, try again. <a className='text-blue-500' onClick={handleRedirectToFigma}> </a> 
            </Trans>
          }
          className="mt-4"
        />
      )}
      <Button
        name={t("Import")}
        className="mt-4"
        type="primary"
        disabled={!checkIfValidFigmaPrototypeLink(figmaPrototypeLink)}
        handler={onImportPrototypeClick}
        isLoading={isImporting}
      />
      {/* <a type="default" href="" target="_blank" rel="noopener noreferrer" className="text-base mt-4">
				{t("Learn more about importing your prototype")}
			</a> */}
    </div>
  );
}

export default FigmaImportWithLink;