import React from "react";
import { motion } from "framer-motion";
import useIsMobile from "../hooks/useIsMobile";

import { IconLeft, IconUp } from "../../../../../icons";

interface ShowTaskButtonProps {
  openTaskModal: () => void;
  buttonName: string;
}

const ShowTaskButton: React.FC<ShowTaskButtonProps> = ({ openTaskModal, buttonName }) => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.85 }}
      transition={{ delay: 0.75, duration: 0.2 }}
      whileHover={{ opacity: 0.75, transition: { duration: 0.2 } }}
      className={`pl-[2px] pr-[8px] py-[4px] h-12 absolute z-[99] rounded-r-lg bg-gray-800 backdrop-blur-md flex items-center space-x-1 cursor-pointer hover:opacity-50 ${
        "bottom-24"
      }`}
      onClick={openTaskModal}
    >
        <IconLeft width={24} height={24} className="fill-current text-white" />

      <motion.span
        initial={{ opacity: 1, x: 0 }}
        animate={{ opacity: 0, x: -10, display: 'none' }}
        transition={{ delay: 3, duration: 0.2 }}
       className="text-white text-base font-medium h-8 flex items-center">{buttonName}
       </motion.span>
    </motion.div>
  );
};

export default ShowTaskButton;