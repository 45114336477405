import { ICardSortResponse } from "../../../models/Response";
import i18n from "../../../i18n/config";
import { CardSortCategory } from "../../../models/Test";

export function getUserDefinedCategories(responses: ICardSortResponse[], withUnsorted?: boolean): { id: string, value: string | undefined, isCustom?: boolean }[] {
	let userDefinedCategories: { id: string, value: string | undefined, isCustom?: boolean }[] = [];

	responses.forEach(response => {
		response.sorting.forEach(category => {
			if (category.isCustom || (withUnsorted && category.id === "unsorted")) {
				userDefinedCategories.push({ id: category.id, value: category.value || i18n.t("Unsorted"), isCustom: category.isCustom });
			}
		});
	});

	return userDefinedCategories;
}

export function getUserDefinedCategoriesForIndividualResponse(response: ICardSortResponse, blockCategories: CardSortCategory[]): { id: string, value: string | undefined }[] {
	let userDefinedCategories: { id: string, value: string | undefined }[] = [];
	const blockCategoryValues = blockCategories.map(category => category.value);
	response.sorting.forEach(category => {
		if (category.isCustom || category.id === "unsorted") {
			const value = category.value ? category.value : category.id === "unsorted" ? i18n.t("Unsorted") : undefined;
			userDefinedCategories.push({ id: category.id, value: value });
		}

		//проверка если было изменено название категории, чтобы старое название тоже добавить
		const isExistInCurrentCategories = blockCategoryValues.includes(category.value);
		if (category.id !== "unsorted" && category.id !== "custom" && !isExistInCurrentCategories ) {
			//такой кейс может происходить в открытой сортировке
			const categoryExists = userDefinedCategories.some(
				existingCategory => existingCategory.id === category.id && existingCategory.value === category.value);

			if (!categoryExists) {
				userDefinedCategories.push({ id: category.id, value: category.value });
			}
		}
	});

	userDefinedCategories.sort((a, b) => {
		if (a.id === "unsorted") return 1;
		if (b.id === "unsorted") return -1;
		return 0;
	});

	return userDefinedCategories;
}


export const getShare = (number: number, totalResponses: number) => {
	if (!totalResponses || !number) return undefined;
	const share = ((number / totalResponses) * 100).toFixed(1);
	return parseFloat(share);
}