import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import Tooltip from "./Tooltip";
import { IconQuestion } from "../icons";

export default function TooltipWithIcon(props: { text: React.ReactNode, size?: number, className?: string }) {
  return (
    <Tooltip anchorClassName={clsx("fill-current", props.className)} position="top" content={<div className="w-48">{props.text}</div>}>
      <IconQuestion width={props.size || 20} height={props.size || 20} />
    </Tooltip>
  );
}