import React, { useContext } from 'react';
import { IconAddTag, IconTag } from '../../../../icons';
import { ChatMessageBaseProps, UserMessage, AssistantMessage } from './ChatMessage';
import { TagSelect } from './TagSelect';
import { useTags } from '../useTags';
import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import Popover from '../../../Popover2';
import { TestBlockReportContext } from '../../ReportContext';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export function TaggableChatMessage(props: ChatMessageBaseProps & { showDate?: boolean }) {
	const { t } = useTranslation();
	const reportContext = useContext(TestBlockReportContext);
	const tags = useTags({ testId: reportContext?.testId || '', blockId: reportContext?.block.blockId || '' });

	const canAddOrDeleteTags = !reportContext?.sharedByLink;

	function onTagSelect(tag: { id?: number, name: string, color: string }) {
		tags.tagMessage.mutate({ messageId: props.message.id, tagId: tag.id, name: tag.name, color: tag.color });
	}

	function untagMessage(tag: IMessageTag) {
		tags.untagMessage.mutate({ tagId: tag.id, request: { messageId: props.message.id } });
	}

	if (!props.message.isUser) return <AssistantMessage {...props} />;

	return <div className='w-full taggable-message__container'>
		<div className="taggable-message flex flex-row items-center group/taggablemsg gap-1 relative w-fit-content">
				<UserMessage
					className='w-full'
					message={props.message}
					onTagDelete={untagMessage}
					canDeleteTags={canAddOrDeleteTags}
					tempTag={tags.tagMessage.isPending ? tags.tagMessage.variables : undefined}
				/>
			{canAddOrDeleteTags && <div className='absolute -bottom-4 -right-4 opacity-0 group-hover/taggablemsg:opacity-100 transition-opacity duration-200 ease-in-out'>
				<Popover
					position='bottom-end'
					content={
						<div className="context-menu-items-list w-56 text-base rounded-md">
							<TagSelect excludeTags={props.message.tags} onTagSelect={onTagSelect} />
						</div>
					}
					anchorClassName="context-menu__anchor hover:opacity-75 cursor-pointer transition-opacity duration-75 ease-in"
				>
					<button className="tag-button rounded-full flex items-center justify-center border-solid border border-gray-200 shadow-xs cursor-pointer bg-white p-1">
						<IconAddTag className='size-6 fill-current text-gray-700' />
					</button>
				</Popover>
			</div>}
		</div>
	</div>;
}
