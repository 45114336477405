import { useEffect, useState } from "react";

export const useDownloadRecording = ({
  blockId,
  answerId,
}: {
  blockId: string;
  answerId: string;
}) => {
  const [events, setEvents] = useState<any>(null);
  const [error, setError] = useState<string | null>("");

  useEffect(() => {
    fetch(`/api/storage/getRecording?blockId=${blockId}&answerId=${answerId}`, {
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          const events = await response.json();
          setEvents(events);
          return;
        }

        setError("UNABLE_TO_LOAD_RECORDING");
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        setError("UNABLE_TO_LOAD_RECORDING");
      })
  }, [blockId, answerId]);

  return { events, error };
};
