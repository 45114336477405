import React from 'react';
import { Block } from '../../models/Test';


export interface ITestBlockReportContext {
	testId: string;
	block: Block;
	sharedByLink: boolean;
	sharingToken?: string;
}
export const TestBlockReportContext = React.createContext<ITestBlockReportContext | null>(null);
