import React, { useTransition } from "react";
import "../../../tailwind.generated.css";
import _ from "lodash";

import Condition from "./ReportFilterCondition";
import Button from "../../Button";
import Dropdown, { DropdownOption } from "../../Dropdown";

import Popover from "../../Popover";

import { IconFilter, IconPlus } from "../../../icons";

import { IconHire, IconTag } from "../../../icons";

import { generateId } from "../../../utils";
import { getBlockName } from "../../TestEditor/utils";

import { IReportTestData, IReportFilter, FilterType, FilterConditionOperator, IReportFilterCondition, PanalAnswerStatuses } from "../../../models/Report";
import { blockTypeMap } from "../../Common";
import { Trans, useTranslation } from "react-i18next";

export function getPanelStatusBaseCondition() {
  return {
    id: generateId(),
    type: FilterType.panelRespondentStatus,
    createdAt: _.now(),
    operator: FilterConditionOperator.and,
    value: PanalAnswerStatuses.completed,
  }
}

function dropdownValueToFilterType(value: string) {
  switch (value) {
    case "source":
      return FilterType.source;
    case "urlParameter":
      return FilterType.urlParameter;
    case FilterType.panelRespondentStatus:
      return FilterType.panelRespondentStatus;
    default:
      return FilterType.block;
  }
}

export interface IFiltersProps {
  data: IReportTestData;
  filter: IReportFilter;
  handleUpdateFilter: (update: (current: { conditions: { [key: string]: IReportFilterCondition } }) => void) => void;
}

const blockIconSize = { width: "24px", height: "24px" };

const Filters = ({ data, filter, handleUpdateFilter }: IFiltersProps) => {
  const { t } = useTranslation();
  const handleAddCondition = (type: FilterType, blockId?: string) => {
    const conditionId = generateId() as string;

    if (!blockId && type === FilterType.block) {
      return;
    }

    const condition: IReportFilterCondition = {
      id: conditionId,
      type: type,
      operator: FilterConditionOperator.and,
      ...(type === FilterType.block && {
        blockId: blockId,
        condition: null,
      }),
      ...(type === FilterType.urlParameter && {
        tag: undefined,
        condition: null,
      }),
      value: undefined,
      createdAt: _.now(),
    };

    if (type) {
      handleUpdateFilter((current) => {
        return {
          ...current,
          conditions: {
            ...current.conditions,
            [conditionId]: condition,
          },
        };
      });
    }
  };

  const handleUpdateCondition = (id: string, update: { [key: string]: string }) => {
    handleUpdateFilter((current) => {
      return {
        ...current,
        conditions: {
          ...current.conditions,
          [id]: {
            ...current.conditions[id],
            ...update,
          },
        },
      };
    });
  };

  const handleDeleteCondition = (id: string) => {
    handleUpdateFilter((current) => {
      const conditions = { ...current.conditions };
      delete conditions[id];
      return {
        ...current,
        conditions,
      };
    });
  };

  function isDefined<T>(argument: T | undefined): argument is T {
    return argument !== undefined;
  }

  const questionFilterItems: DropdownOption[] = data.publishedContent
    ?.filter(
      (block) =>
        block.type !== "context" && block.type !== "fiveseconds" && _.get(data, ["answers", block.blockId, "responses"], []).length > 0
    )
    .map((block, i) => {
      const blockTitle = blockTypeMap[block.type];
      if (!blockTitle) return undefined;
      return {
        name: (
          <div>
            <span className="flex items-center">
              <div className="mr-2">{blockTitle.getIcon(blockIconSize)}</div>
              <div className="text-ellipsis overflow-hidden max-w-[200px]"><Trans>{getBlockName(block)}</Trans></div>
            </span>
          </div>
        ),
        value: block.blockId,
      };
    })
    .filter(isDefined) || [];

  const respondentsFilterItems: DropdownOption[] = [
    {
      name: (
        <span className="flex items-center justify-start">
          <div className="mr-2">
            <IconHire width={24} height={24} className="fill-current text-gray-800" />
          </div>
          {t("Source")}
        </span>
      ),
      value: "source",
    },
    {
      name: (
        <span id="filterUrlTag" className="flex items-center justify-start">
          <div className="mr-2">
            <IconTag width={24} height={24} className="fill-current text-gray-800" />
          </div>
          {t("URL Tag")}
        </span>
      ),
      value: "urlParameter",
    }
  ];

  const isNonCompletedStatusPresent = Object.keys(data.answersMetaData).some((answerId) => (
    data.answersMetaData[answerId].panelRespondentStatus && 
    data.answersMetaData[answerId].panelRespondentStatus !== PanalAnswerStatuses.completed
  ));

  const hasPanelRespondentStatus = Object.values(filter.conditions).some(
    ({ type }) => type === FilterType.panelRespondentStatus
  )

  if (isNonCompletedStatusPresent || hasPanelRespondentStatus) {
    respondentsFilterItems.push({
      name: (
        <span className="flex items-center justify-start">
          <div className="mr-2">
            <IconFilter width={24} height={24} className="fill-current text-gray-800" />
          </div>
          {t("Panel Status")}
        </span>
      ),
      value: FilterType.panelRespondentStatus,
    })
  }

  const noFilters = Object.keys(filter.conditions).length === 0;
  const filtersCount = Object.keys(filter.conditions).length;

  return (
    <>
      <div className="my-2">
        <div className="filters__wrapper">
          <Popover
            styleMode="light"
            position="bottom-left"
            anchorClassName="w-fit-content"
            getContent={(setIsVisible: any) => (
              <div className="py-1 px-2 min-w-[380px] w-fit-content">
                <div className="font-medium">{t("Filters")}</div>
                {noFilters && <div className="text-base text-gray-700 py-2">{t("No filters applied.")}</div>}
                {Object.keys(filter.conditions)
                  .sort((keyA, keyB) => filter.conditions[keyA].createdAt - filter.conditions[keyB].createdAt)
                  .map((conditionId, i) => {
                    return (
                      <Condition
                        i={i}
                        filter={filter}
                        id={conditionId}
                        key={conditionId}
                        data={data}
                        handleUpdateCondition={(key: string, value: string) => {
                          handleUpdateCondition(conditionId, { [key]: value });
                        }}
                        handleDeleteCondition={() => {
                          handleDeleteCondition(conditionId);
                        }}
                      />
                    );
                  })}
                <div className="flex items-center justify-between">
                  <Dropdown
                    type="single"
                    items={["divider", ...respondentsFilterItems, "divider", ...questionFilterItems]}
                    handler={(value) => {
                      const type = dropdownValueToFilterType(value);
                      handleAddCondition(type, type === FilterType.block ? value : undefined);
                    }}
                    header={undefined}
                    light={false}
                  >
                    <Button
                      id="addFilter"
                      type="secondary"
                      name={t("Add filter")}
                      extrasmall
                      className="my-2"
                      icon={<IconPlus className="fill-current text-gray-800 w-5 h-5 mr-2" />}
                    />
                  </Dropdown>
                  <span
                    id="filterClearAll"
                    onClick={() => {
                      handleUpdateFilter(() => ({ conditions: {} }));
                      setIsVisible(false);
                    }}
                    className="text-gray-700 text-xs font-medium cursor-pointer transition-all duration-75 ease-in hover:opacity-75"
                  >
                    {t("Clear all")}
                  </span>
                </div>
              </div>
            )}
          >
            <Button
              id="filter"
              type="primary"
              name={
                <>
                  {t("Filters")}
                  {filtersCount > 0 && <span className="text-base text-white opacity-75 ml-1">{filtersCount}</span>}
                </>
              }
              className="my-2"
              // icon={<IconFilter className="fill-current text-white w-5 h-5 mr-1" />}
            />
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Filters;
