import React from "react";
import { CodeBlock } from "./FinalStep";

import { IconStop } from "../../../../../icons";

import { locale, interfaceText } from "../../../../../helpers";

const AnswersLimitExceeded = (props: { verificationCode?: string }) => {
  return (
    <div className="answers-limit-exceeded container mx-auto h-full flex justify-center items-center">
      <div className="text-center">
        <IconStop className="mb-4 mx-auto" width={32} height={32} />
        <div className="answers-limit-exceeded__title mb-4">{interfaceText.testIsntAcceptingNewResponses[locale() as 'ru' | 'en']}</div>
        {props.verificationCode && <CodeBlock verificationCode={props.verificationCode} />}
      </div>
    </div>
  );
};

export default AnswersLimitExceeded;
