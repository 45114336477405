import React, { useEffect, useState, useRef } from "react";
import "../../tailwind.generated.css";
import clsx from "clsx";

import { blockTypeMap } from "../Common";
import { Block, BlockType } from "../../models/Test";

import { getBlockName } from "../TestEditor/utils";

import { Trans } from "react-i18next";

type ReportContentListProps = {
  blocks: Block[];
  selectedBlockId: string | null;
  selectBlock: (blockId: string) => void;
};

const ReportContentList = ({ blocks, selectBlock, selectedBlockId }: ReportContentListProps) => {

  const reportContentListRef = useRef<HTMLDivElement>(null);
  const [showBorders, setShowBorders] = useState<{ top: boolean, bottom: boolean }>({ top: false, bottom: false });
  const [reportContentListHeight, setReportContentListHeight] = useState<number>(0);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        const currentIndex = blocks.findIndex(block => block.blockId === selectedBlockId);
        if (currentIndex !== -1) {
          const nextIndex = event.key === "ArrowDown" ? currentIndex + 1 : currentIndex - 1;
          if (nextIndex >= 0 && nextIndex < blocks.length) {
            selectBlock(blocks[nextIndex].blockId);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [blocks, selectedBlockId, selectBlock]);

  useEffect(() => {

    const scrollContainer = document.getElementById('test-page__wrapper');

    const handleScroll = () => {
      if (!reportContentListRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = reportContentListRef.current;
      const isScrolled = scrollTop > 0;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      const reportContentListHeight = window.innerHeight - reportContentListRef.current.getBoundingClientRect().top;
      setReportContentListHeight(reportContentListHeight);

      // console.log(window.innerHeight);
      // console.log(reportContentListRef.current?.getBoundingClientRect().top);

      setShowBorders({
        top: isScrolled,
        bottom: isScrolled && !isScrolledToBottom,
      });
    };

    const listElement = reportContentListRef.current;
    listElement?.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    // Initial check in case the list is already scrolled when the component mounts
    handleScroll();

    return () => {
      listElement?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [blocks]);

  if (!blocks.length || !selectedBlockId) {
    return null;
  }


  return (
    <div ref={reportContentListRef} style={{ height: reportContentListHeight }} className={clsx("report-content-list__wrapper flex flex-col gap-2 overflow-y-auto hideScrollbar overscroll-contain", showBorders.top && "border-t-1 border-t-gray-300", showBorders.bottom && "border-b-1 border-b-gray-300")}>
      {blocks.map((block, i) => {

        const isSelected = selectedBlockId === block.blockId;
        return (
          <div
            key={`report-content-list-item-${block.blockId}`}
            className={clsx("report-content-list__item flex flex-col gap-1 p-2 rounded-lg cursor-pointer transition-all duration-75 ease-in", isSelected && "bg-white shadow-xs", !isSelected && "hover:bg-gray-300")}
            onClick={() => selectBlock(block.blockId)}
          >
            <div className={clsx("report-content-list__item-title flex items-center gap-2 justify-start font-medium", isSelected ? "text-gray-800" : "text-gray-700")}>
              <span className={clsx("w-4 shrink-0")}>{i + 1}.</span>
              {blockTypeMap[block.type].getIcon({ width: 24, height: 24, className: "shrink-0" })}
              <span className=""><Trans>{blockTypeMap[block.type].name}</Trans></span>
            </div>
            <div className={clsx("report-content-list__item-subtitle block overflow-hidden text-ellipsis whitespace-nowrap max-w-[240px] text-gray-700")} title={block.text || ''}>{getBlockName(block as Block)}</div>
          </div>
        );

      })}
    </div>
  );
};

export default ReportContentList;