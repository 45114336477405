import React, { useState, useEffect } from "react";
import NodeInHtml from "./NodeInHtml";
import lodash from "lodash";
import { FigmaDisplayMode } from './NodeInHtml/NodeInHtmlUtils';
import { IPrototypeClickEvent } from '../../models/Figma/IClickEvent';
import { IFormattedPrototype, isNodeAction, isValidNavigateAction, isValidOverlayAction } from '../../utils/figma';

function performScrollTo(element: HTMLDivElement, top: number, left: number) {
  element.scrollTo(left, top);

  /**
   * It's hotfix because of render bug in Safari
   */
  if (element.style.webkitTransform === "scale(1)") {
    element.style.webkitTransform = "translateZ(0)";
  } else {
    element.style.webkitTransform = "scale(1)";
  }
}

export interface IFigmaTestViewProps {
  onLoad: () => void;
  onClick: (event: IPrototypeClickEvent) => void;
  startNodeId: string;
  prototype: IFormattedPrototype,
  showDefaultCursor?: boolean;
  className?: string;
  style?: any;
}

export default function FigmaTestView(props: IFigmaTestViewProps) {

  const [state, setState] = useState({
    activeNodeId: props.startNodeId,
  });

  function isActiveNode(nodeId: string) {
    return nodeId === state.activeNodeId;
  }

  const dropScrollPositions = (previousNodeId: string) => {
    const previousActiveNode = document.querySelector(`div[data-id="${previousNodeId}"]`);
    const previousScrollableNodes = previousActiveNode?.querySelectorAll('div[data-has-scroll="true"]') as HTMLDivElement[] | null | undefined;

    if (previousScrollableNodes && previousScrollableNodes.length > 0) {
      previousScrollableNodes.forEach((scrollableNode) => {
        performScrollTo(scrollableNode.children[0] as any, 0, 0);
      });
    }
  };

  const preserveScrollPositions = (destinationId: string) => {
    const currentActiveNode = document.querySelector(`div[data-id="${state.activeNodeId}"]`);

    const newActiveNode = document.querySelector(`div[data-id="${destinationId}"]`);

    const currentScrollableNodes = currentActiveNode?.querySelectorAll('div[data-has-scroll="true"]');
    const newScrollableNodes = newActiveNode?.querySelectorAll('div[data-has-scroll="true"]');

    if (!!currentScrollableNodes?.length && !!newScrollableNodes?.length) {
      currentScrollableNodes.forEach((currentScrollableNode) => {
        newScrollableNodes.forEach((newScrollableNode) => {
          if (matchNodes(currentScrollableNode, newScrollableNode)) {
            performScrollTo(
              newScrollableNode.children[0] as any,
              currentScrollableNode.children[0].scrollTop,
              currentScrollableNode.children[0].scrollLeft
            );
          }
        });
      });
    }

    function matchNodes(nodeA: any, nodeB: any) {
      if (nodeA.getAttribute("data-name") === nodeB.getAttribute("data-name")) {
        return true;
      }
      if (nodeA.parentNode.style?.top === nodeB.parentNode.style?.top &&
        nodeA.parentNode.style?.left === nodeB.parentNode.style?.left &&
        nodeA.parentNode.style?.width === nodeB.parentNode.style?.width &&
        nodeA.parentNode.style?.height === nodeB.parentNode.style?.height) {
        return true;
      }
      return false;
    }
  };

  function setActiveNode(activeNodeId: string) {
    setState((state) => lodash.merge(lodash.cloneDeep(state), { activeNodeId }));
  }

  useEffect(() => {
    const loadingImages = props.prototype.images.map((url: string) => new Promise((resolve) => {
      const image = new Image();
      image.onload = resolve;
      image.src = url;
    })
    );
    Promise.all(loadingImages).then(props.onLoad);
  }, []);

  function handlePrototypeClick(data: IPrototypeClickEvent) {
    const currentNodeId = lodash.clone(state.activeNodeId);

    if (isValidNavigateAction(data.action)) {
      const destinationId = data.action.destinationId;
      // preserve scroll positions
      const transitionType = data.action.transition?.type;
      const preserveScrollPosition = data.action.preserveScrollPosition;

      if (preserveScrollPosition || transitionType === "SMART_ANIMATE") {
        preserveScrollPositions(destinationId);
      }

      // set new active node
      setActiveNode(destinationId);

      // always dropping old scroll positions
      if (currentNodeId !== destinationId) {
        dropScrollPositions(currentNodeId);
      }
    }

    props.onClick(data);
  }

  return (
    <div className={`relative ${props.className || ''}`} style={props.style}>
      {lodash.values(props.prototype.nodesForHtml).map((node, i) => (
        <div
          key={`node-${node.id}-${i}`}
          style={{
            visibility: isActiveNode(node.id) ? "visible" : "hidden",
            zIndex: isActiveNode(node.id) ? 10 : 0,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <NodeInHtml
            visible={isActiveNode(node.id)}
            display={FigmaDisplayMode.fitWidth}
            onClick={handlePrototypeClick}
            imageScale={props.prototype.settings.imageScale}
            node={node}
            prototype={props.prototype}
            showDefaultCursor={props.showDefaultCursor}
          />
        </div>
      ))}
    </div>
  );
}
