import axios from 'axios';
import { getAuthToken } from '../firebase';
import { ChatDTO } from '../Common/schema/Chats/ChatDTO';
import { CreateMessageTagDTO, DeleteMessageTagDTO, IMessageTag } from '../Common/schema/Chats/UpdateChatMessageDTO';

const basePath = '/api/v1/report';

export class ReportChatAPI {

	public static async getChats(testId: string, blockId: string) {
		const headers = await ReportChatAPI.getAuthHeader();
		const response = await axios.get<ChatDTO[]>(`${basePath}/${testId}/${blockId}/chats`, headers);
		return response.data;
	}

	public static async getTags(testId: string, blockId: string) {
		const headers = await ReportChatAPI.getAuthHeader();
		const response = await axios.get<IMessageTag[]>(`${basePath}/${testId}/${blockId}/tags`, headers);
		return response.data;
	}

	public static async tagMessage(testId: string, blockId: string, request: CreateMessageTagDTO) {
		const headers = await ReportChatAPI.getAuthHeader()
		const response = await axios.post<IMessageTag>(`${basePath}/${testId}/${blockId}/tags`, request, headers);

		return response.data;
	}

	public static async unTagMessage(testId: string, blockId: string, tagId: number, request: DeleteMessageTagDTO) {
		const headers = await ReportChatAPI.getAuthHeader();
		const urlSearchParams = new URLSearchParams(request as any);
		const response = await axios.delete<IMessageTag>(`${basePath}/${testId}/${blockId}/tags/${tagId}?${urlSearchParams.toString()}`, headers);

		return response.data;
	}

	private static async getAuthHeader() {
		const authToken = await getAuthToken();
		return { headers: { Authorization: `Bearer ${authToken}` } };
	}
}
