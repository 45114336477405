import { useState, useEffect } from 'react';

import { AssistantAiChatMessage, BaseAiChatMessage, UserAiChatMessage } from '../../../../../models/Test';

import { useMutation } from '@tanstack/react-query';
import { ChatsAPI } from '../../../../../actions/ChatsAPI';
import { ChatMessageDTO, ChatStatus, MessageDTO } from '../../../../../Common/schema/Chats/CreateChatRequestDTO';
import _ from 'lodash';

interface ChatState { chat: ChatMessageDTO, messages: BaseAiChatMessage[] }

function useAIChat(options: { testId: string, answerId: string, blockId: string, isPreview?: boolean }) {
    const [chat, setChat] = useState<null | ChatState>(null);
    const [firstUserMessage, setFirstUserMessage] = useState<string | null>(null);

    useEffect(() => {
        if (chat && firstUserMessage) {
            sendMessage(firstUserMessage);
            setFirstUserMessage(null);
        }
    }, [chat, firstUserMessage]);

    const createChatQuery = useMutation<ChatMessageDTO, Error>({
        mutationKey: ['chats', 'start', options],
        mutationFn: () => ChatsAPI.createChat(options),
        onSuccess: (data) => {
            updateChat(data);
        }
    });

    const skipQuery = useMutation({
        mutationKey: ['chats', 'skip', options],
        mutationFn: () => ChatsAPI.skip(chat?.chat.id as number),
        onSuccess: (data) => {
            updateChat(data);
        }
    })

    const sendMessageQuery = useMutation({
        mutationKey: ['chats', 'sendMessage', options],
        mutationFn: (message: string) => {
            if (chat) {
                return ChatsAPI.addMessage(chat.chat.id, { text: message });
            }
            // TODO: handle error
            return Promise.reject('chat is not initialized');
        },
        onSuccess(data) {
            handleSendMessageQuerySuccess(data);
        },
    })

    function handleSendMessageQuerySuccess(assistantResponse: ChatMessageDTO) {
        if (assistantResponse.message !== null) {
            addAssistantMessage(assistantResponse as ChatMessageDTO & { message: MessageDTO });
        }
        if (assistantResponse.status === ChatStatus.Completed) {
            updateChat(assistantResponse);
        }
        return assistantResponse;
    }

    function addAssistantMessage(chat: ChatMessageDTO & { message: MessageDTO }) {
        const assistantMessage = new AssistantAiChatMessage(chat.message.text);
        updateChat(chat);
        addMessage(assistantMessage);
    }

    async function createChat() {
        await createChatQuery.mutateAsync();
    }

    function updateChat(chat: ChatMessageDTO) {
        setChat(current => {
            const newState = current ? _.cloneDeep(current) : { chat, messages: [] } as ChatState;
            newState.chat = chat;
            return newState;
        })
    }

    function addUserMessage(message: string) {
        const userMessage = new UserAiChatMessage(message);
        addMessage(userMessage);
    }

    function addMessage(message: BaseAiChatMessage) {
        setChat(current => {
            if (!current) {
                console.warn('Adding chat message while chat is not initialized');
                return current;
            }

            const newState = _.cloneDeep(current);
            newState.messages.push(message);
            return newState;
        });
    }

    async function skipChat() {
        await skipQuery.mutateAsync();
    }

    function sendMessage(message: string) {
        if (chat) {
            addUserMessage(message);
            sendMessageQuery.mutate(message);
        }
        else {
            setFirstUserMessage(message);
        }
    }

    return {
        chat,
        sendMessage,
        isAIMessageLoading: createChatQuery.isPending || sendMessageQuery.isPending,
        createChat,
        skipChat
    };
}

export default useAIChat;