import React, { useEffect, useRef, useState } from "react";
import { getNodeParams } from "../../../utils/figma";
import lodash from "lodash";

const getChildrenSize = (parent) => {
  const { children, x, y, width, height } = getNodeParams(parent);

  let x0 = x;
  let x1 = x + width;
  let y0 = y;
  let y1 = y + height;

  children.forEach(node => {
    const { x, y, width, height } = getNodeParams(node);
    x0 = Math.min(x0, x);
    x1 = Math.max(x1, x + width);
    y0 = Math.min(y0, y);
    y1 = Math.max(y1, y + height);
  });

  return { 
    width: x1 - x0, 
    height: y1 - y0,
  };
}

const getBackground = ({ fills = [] }) => {
  let background = "";

  fills.forEach(({ color: { r, g, b, a }, type, visible }) => {
    if (type === "SOLID" && visible !== false) {
      background = `rgba(${r * 255},${g * 255},${b * 255},${a})`;
    }
  });
  
  return background;
}

const WithScroll = ({ children, number }) => {
  const scaleRate = 2;
  const style = {
    transform: `scale(${scaleRate})`
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div 
        className="absolute inset-0 flex items-center justify-center" 
        style={{ background: `rgb(0,0,0,0.8)`, zIndex: 20 }}
      >
        <span style={style}>{number}</span>
      </div>
      {children}
    </div>
  );
}

const ChildrenNodes = ({
  parentNode: parent,
  rootNode: root,
  scaleRate,
  node,
  onClick,
  numbersMap,
  setRef,
}) => {
  const rootNode = getNodeParams(root);
  const parentNode = getNodeParams(parent);
  const thisNode =  getNodeParams(node);

  const wrapperParams = {
    style: {
      position: 'absolute',
      width: thisNode.width,
      height: thisNode.height,
    }
  };

  const blockParams = {
    style: {
      position: 'absolute',
      width: thisNode.width * scaleRate,
      height: thisNode.height * scaleRate,
      overflow: 'hidden',
    }
  };

  if (!thisNode.isFixed) {
    lodash.set(blockParams, "style.left", `${(thisNode.x - parentNode.x) * scaleRate}px`);
    lodash.set(blockParams, "style.top", `${(thisNode.y - parentNode.y) * scaleRate}px`);
  }

  if (thisNode.isFixed) {
    lodash.set(blockParams, "style.transform", `scale(${1 / scaleRate}`);
    lodash.set(blockParams, "style.transformOrigin", "0 0");
    
    if (thisNode.horizontal === 'LEFT') lodash.set(wrapperParams, "style.left", `${(thisNode.x - parentNode.x)}px`);
    else lodash.set(wrapperParams, "style.right", `${(parentNode.width - (thisNode.x - parentNode.x) - thisNode.width)}px`);

    if (thisNode.vertical === 'TOP') lodash.set(wrapperParams, "style.top", `${(thisNode.y - parentNode.y)}px`);
    else lodash.set(wrapperParams, "style.bottom", `${(parentNode.height - (thisNode.y - parentNode.y) - thisNode.height)}px`);
  }

  if (thisNode.isFixed || thisNode.overflowDirection) {
    lodash.set(blockParams, "style.background", `url(${rootNode.image}) -${(thisNode.x - rootNode.x) * scaleRate}px -${(thisNode.y - rootNode.y) * scaleRate}px no-repeat`);
  }

  if (thisNode.image) {
    lodash.set(blockParams, "style.background", `url(${thisNode.image}) 0 0 no-repeat`)
  }

  if (thisNode.transitionNodeID) {
    lodash.set(blockParams, "style.cursor", "pointer");
  }

  let output = thisNode.children && thisNode.children.map((childNode) => (
    <ChildrenNodes 
      rootNode={root}
      parentNode={node}
      node={childNode}
      scaleRate={scaleRate}
      key={childNode.id}
      onClick={onClick}
      numbersMap={numbersMap}
      setRef={setRef}
    />
  ));

  if (thisNode.overflowDirection) {
    const scrollStyle = {
      position: 'relative',
      width: thisNode.width * scaleRate,
      height: thisNode.height * scaleRate,
      backgroundColor: "#fff",
    };

    const innerStyle = {
      position: 'relative',
      width: thisNode.width * scaleRate,
      height: thisNode.height * scaleRate,
    };

    thisNode.children && thisNode.children.forEach((childNode) => {
      const { width, height, x, y } = getNodeParams(childNode);

      const nodeWidth = x - thisNode.x + width;
      const nodeHeight = y - thisNode.y + height;

      if (innerStyle.width < nodeWidth) innerStyle.width = nodeWidth;
      if (innerStyle.height < nodeHeight) innerStyle.height = nodeHeight;
    });

    output = (
      <WithScroll number={numbersMap[node.id]} style={scrollStyle}>
        <div style={innerStyle}>
          {output}
        </div>
      </WithScroll>
    );
  }

  output = (
    <div ref={setRef(node.id)} {...blockParams}>
      {output}
    </div>
  );
  
  if (thisNode.isFixed) {
    output = (
      <div {...wrapperParams}>
        {output}
      </div>
    );
  }

  return output;
}

const Screen = ({ node, numbersMap, setRef }) => {
  const { image, children, width, height } = getNodeParams(node);
  
  const scaleRate = 2;
  const staticNodes = [];
  const fixedNodes = [];

  children.forEach(childNode => {
    if (childNode.isFixed) fixedNodes.push(childNode);
    else staticNodes.push(childNode);
  });

  const wrapperStyles = {
    width: `${width}px`,
    height: `${height}px`,
    position: "relative",
  };

  const staticNodesWrapperStyles = {
    ...wrapperStyles
  };

  const staticNodesStyles = {
    width: `${width * scaleRate}px`,
    height: `${height * scaleRate}px`,
    background: `url(${image}) 0 0 no-repeat`,
    transform: `scale(${1 / scaleRate})`,
    transformOrigin: "0 0",
  };

  if (node.overflowDirection) {
    const sizes = getChildrenSize(node);
    wrapperStyles.width = sizes.width;
    wrapperStyles.height = sizes.height;
    staticNodesWrapperStyles.left = 0;
    staticNodesWrapperStyles.bottom = 0;
    staticNodesWrapperStyles.position = "absolute";
  }

  return (
    <div style={wrapperStyles}>
      <div style={staticNodesWrapperStyles}>
        <div style={staticNodesStyles}>
          {staticNodes.map((childNode) => (
            <ChildrenNodes 
              rootNode={node}
              parentNode={node}
              node={childNode}
              scaleRate={scaleRate}
              key={childNode.id}
              numbersMap={numbersMap}
              setRef={setRef}
            />
          ))}
        </div>
      </div>
      {fixedNodes.map((childNode) => (
        <ChildrenNodes 
          rootNode={node}
          parentNode={node}
          node={childNode}
          scaleRate={scaleRate}
          key={childNode.id}
          numbersMap={numbersMap}
          setRef={setRef}
        />
      ))}
    </div>
  );
};

const getScrollableBlocks = ({ children = [] }) => {
  const blocks = [];

  children.forEach(block => {
    if (block.overflowDirection) {
      blocks.push(block);
    }

    if (block.children) {
      blocks.push(...getScrollableBlocks(block.children));
    }
  });

  return blocks;
};

const getBlocks = (mainNode, setRef) => {
  const numbersMap = { [mainNode.id]: "№1" };
  
  const scrollableBlocks = getScrollableBlocks(mainNode);
  scrollableBlocks.forEach(({ id }, index) => {
    numbersMap[id] = `№${index + 2}`;
  });

  const blocksMap = { 
    [mainNode.id]: {
      label: numbersMap[mainNode.id],
      nodeId: mainNode.id,
      view: (
        <Screen
          numbersMap={numbersMap}
          node={mainNode}
          setRef={setRef}
        />
      ),
    }
  };

  scrollableBlocks.forEach(({ id, ...block }, index) => {
    blocksMap[id] = {
      label: numbersMap[id],
      nodeId: id,
      view: (
        <Screen
          numbersMap={numbersMap}
          node={block}
          setRef={setRef}
        />
      ),
    };
  });

  return blocksMap;
};

const getImages = (node, images = []) => {
  if (node.image) images.push(node.image);
  if (node.children) node.children.forEach((node) => getImages(node, images));
  return images;
}

/** @deprecated */
function FigmaHtml({ onLoad, nodeForHtml }) {
  const refs = useRef({});
  const setRef = name => (
    element => refs.current[name] = element
  );

  const [loadedImagesNum, setLoadedImagesNum] = useState(0);
  const nodeImages = getImages(nodeForHtml);

  useEffect(() => {
    if (!onLoad) return;
    if (loadedImagesNum === Object.keys(nodeImages).length) {
      const { root, ...nodes } = refs.current;
      const { clientWidth, clientHeight } = root;
      const nodePositions = {};
  
      const { left: rootLeft, top: rootTop } = root.getBoundingClientRect();
  
      Object.keys(nodes).forEach(nodeId => {
        const { left, top } = nodes[nodeId].getBoundingClientRect();
        nodePositions[nodeId] = {
          left: left - rootLeft,
          top: top - rootTop,
        };
      });

      onLoad({
        width: clientWidth,
        height: clientHeight,
        nodePositions,
      });
    }
  }, [loadedImagesNum]);

  const blocksMap = getBlocks(nodeForHtml, setRef);
  const blocksMapKeys = Object.keys(blocksMap);
  const background = getBackground(nodeForHtml);

  const images = (
    <div className="invisible inset-0 w-px h-px overflow-hidden absolute">
      {nodeImages.map((imageSrc, index) => (
        <img 
          className="invisible"
          key={`figma-htmlview-image-${index}`}
          src={imageSrc} 
          onLoad={() => {
            setLoadedImagesNum((num) => num + 1);
          }} 
        />
      ))}
    </div>
  );

  if (blocksMapKeys.length === 1) {
    return (
      <div className="flex flex-col items-center gap-3 w-fit-content relative" ref={setRef("root")}>
        {images}
        <div ref={setRef(blocksMapKeys[0])} style={{ background }}>
          {blocksMap[blocksMapKeys[0]].view}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-3 w-fit-content relative" ref={setRef("root")}>
      {images}
      {blocksMapKeys.map(nodeId => {
        const node = blocksMap[nodeId];
        return (
          <div>
            <div>{node.label}</div>
            <div ref={setRef(nodeId)} style={{ background }}>
              {node.view}
            </div>
          </div>
        );
      })}
    </div>
  );
} 

export default FigmaHtml;
