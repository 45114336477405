import { useState } from "react";
import { makeAuthorizedRequest } from "../../../http/Client";

import { generateId } from "../../../utils";

type useSuggestCategoriesForMergeArgs = {
	testId: string;
	blockId: string;
	categories: {
		name: string;
		id: string;
	}[];
};

type SuggestionResult = { [key: string]: string[] };

function parseJson(str: string): SuggestionResult | null {
	try {
		return JSON.parse(str) as SuggestionResult;
	} catch {
		return null;
	}
}

export const useSuggestCategoriesForMerge = (options: useSuggestCategoriesForMergeArgs) => {
	const { categories } = options;
	const [result, setResult] = useState<
		| {
			name: string;
			ids: string[];
		}[]
		| null
	>(null);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const suggestCategoriesForMerge = async () => {
		setIsLoading(true);
		setResult(null);
		setError(null);


		if (categories.length > 300) {
			setIsLoading(false);
			setResult([]);
			return;
		}

		const chunkSize = 35;
		let groupSuggestions: { name: string; ids: string[]; suggestionId: string }[] = [];

		for (let i = 0; i < categories.length; i += chunkSize) {
			const categoriesChunk = categories.slice(i, i + chunkSize);

			try {
				const response = await makeAuthorizedRequest(
					`/api/v1/report/${options.testId}/${options.blockId}/ai/category_suggestions`, //`/api/v1/reporting/suggestCategoriesForOpenCardSortMerge`,
					"POST",
					{
						groupSuggestions: groupSuggestions,
						categories: categoriesChunk
					}
				);

				if (!response.ok) {
					const errorData = await response.json();
					console.error(errorData);
					continue; // Skip to the next chunk
				}

				const data = await response.json();
				const resultJson = parseJson(data.content);

				if (!resultJson) {
					continue; // Skip to the next chunk
				}

				for (const [groupName, ids] of Object.entries(resultJson)) {
					const existingGroupIndex = groupSuggestions.findIndex(group => group.name === groupName);
					if (existingGroupIndex !== -1) {
						// Merge with existing group
						groupSuggestions[existingGroupIndex].ids = Array.from(
							new Set([...groupSuggestions[existingGroupIndex].ids, ...ids])
						);
					} else {
						// Add new group
						groupSuggestions.push({ name: groupName, ids, suggestionId: generateId() });
					}
				}
			} catch (error) {
				console.error(error);
				setError("UNABLE_TO_GET_SUGGESTIONS");
				// Optionally break out of the loop or handle the error differently
			}
		}
		// return only group suggestions with 2 or more categories
		setResult(groupSuggestions.filter(group => group.ids.length >= 2));
		setIsLoading(false);
	};

	return { result, suggestCategoriesForMerge, error, isLoading };
};
