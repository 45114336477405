import * as yup from 'yup';
import { ChatStatus } from './CreateChatRequestDTO';

export enum ChatMessageRole {
	User = 'user',
	Assistant = 'assistant',
}

export interface ChatDTO {
	id: number;
	answerId: string;
	testId: string;
	blockId: string;
	status: ChatStatus;
	reason: string | null;
	createdAt: Date;
	messages: ChatMessageDTO[] | null;
}

export interface ChatMessageDTO {
	id: number;
	text: string;
	role: ChatMessageRole;
	functionCall: { name: string; args: any; } | null;
	createdAt: Date;
}

export const ChatMessageDTOSchema = yup.object<ChatMessageDTO>().shape({
	id: yup.number().required(),
	text: yup.string().required(),
	role: yup.mixed<ChatMessageRole>().oneOf(Object.values(ChatMessageRole)).required(),
	functionCall: yup.object().shape({
		name: yup.string().required(),
		args: yup.mixed()
	}).nullable().default(null),
	createdAt: yup.date().required()
});

export const ChatDTOSchema = yup.object<ChatDTO>().shape({
	id: yup.number().required(),
	answerId: yup.string().required(),
	testId: yup.string().required(),
	blockId: yup.string().required(),
	status: yup.mixed<ChatStatus>().oneOf(Object.values(ChatStatus)).required(),
	reason: yup.string().nullable().default(null),
	createdAt: yup.date().required(),
	messages: yup.array<ChatMessageDTO>(ChatMessageDTOSchema).nullable().default(null),
});
