import React, { useEffect, useRef, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import useReportExport, { ExportFormat } from "./hooks/useReportExport";
import { useSendNotification } from "../../hooks";

type ExportModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  testId: string;
  sharingToken?: string;
};

const options = [
  {id: ExportFormat.CSV, name: "CSV"}, 
  {id: ExportFormat.XLSX, name: "XLSX"}
];

const ExportModal = ({ isOpen, setIsOpen, testId, sharingToken }: ExportModalProps) => {

  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(options[0].id);

  const isOpenRef = useRef(isOpen);

  const sendNotification = useSendNotification();
  const reportExport = useReportExport(testId, sharingToken, {
    onSuccess: (message) => {
      if (!isOpenRef.current) return;
      setIsOpen(false);
      sendNotification("success", message);
    },
    onError: (message) => {
      if (!isOpenRef.current) return;
      sendNotification("error", message);
    },
  })

  const isLoading = reportExport.isLoading(ExportFormat.CSV) || reportExport.isLoading(ExportFormat.XLSX);

  useEffect(() => {
    if (!isOpen) reportExport.abortExport();
    isOpenRef.current = isOpen;
  }, [isOpen])

  return (
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        width="320px"
      >
        <div className={clsx("p-4 flex flex-col gap-4", isLoading && "opacity-50")}>
          <p className="header3">{t("Export to...")}</p>
          <div className="flex flex-col gap-3">
              <div className="text-gray-700 text-base">{t("Select file type")}</div>
              <div className="flex items-center justify-center w-full gap-4">
                  {options.map((option) => (
                    <div 
                      key={option.id}
                      className={clsx("border-1 p-1 rounded-md w-full flex flex-1 items-center justify-center text-base cursor-pointer hover:border-blue-600 hover:ring-1 hover:ring-blue-600 transition-all duration-75 ease-in", selectedOption === option.id ? "border-blue-600 ring-1 ring-blue-600 bg-blue-100" : "border-gray-300 bg-white", isLoading && "cursor-not-allowed")} 
                      onClick={() => setSelectedOption(option.id)}
                    >
                        <p className="font-medium">{option.name}</p>
                    </div>
                  ))}
              </div>
          </div>
          <Button
            isLoading={isLoading}
            name={t("Export report")}
            handler={() => reportExport.exportReport(selectedOption)}
            fullWidth
            className="mt-1"
          />
        </div>
      </Modal>
  );
};

export default ExportModal;