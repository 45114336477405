import { format, Locale } from 'date-fns';
import { enUS, ru } from 'date-fns/locale'

interface FormatDateOptions {
  formatStr: string;
  locale: Locale;
}

const locales: { [key: string]: Locale } = {
  en: enUS,
  ru: ru
};

export function formatDate(date: Date, language: string, options?: Partial<FormatDateOptions>) {
  const { formatStr = "d MMM, HH:mm", locale = locales[language] } = { ...options };

  return format(date, formatStr, { locale });
}