import React, { useState, useEffect } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import Modal from "../../../../Modal";
import Button from "../../../../Button";

import _, { delay } from "lodash";

import { locale, interfaceText } from "../../../../../helpers";

import { IconInfo, IconQuestionMark } from "../../../../../icons";
import { motion } from "framer-motion";

import TestBranding from "./TestBranding";
import ShowTaskButton from "./ShowTask";

const Task = ({ text, description, onStart, onGiveUp, isLoading, blockId }) => {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [isMissionInProgress, setIsMissionInProgress] = useState(false);

  useEffect(() => {
    setIsTaskModalOpen(true);
    setIsMissionInProgress(false);
  }, [blockId]);

  const isMobile = window.screen.width <= 480;

  return (
    <>
      {isMissionInProgress && (
        <ShowTaskButton
          openTaskModal={() => setIsTaskModalOpen(true)}
          buttonName={interfaceText.test[locale()].showTask}
        />
      )}
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="480px"
        disallowClickOutside
        background
        sidebarMode
      >
        <div className="flex flex-col flex-1 h-full p-5">
          <div className={clsx("flex h-full flex-col gap-6")}>
            <div className="task__instructions flex flex-1 flex-col h-full gap-3">
              <TestBranding />
                <div>
                  <div className="flex">
                    <div className="flex flex-col gap-2">
                      <span className="block text-lg mt-2 font-medium">{text}</span>
                      <span className="block mt-2">{description}</span>
                    </div>
                  </div>
                </div>
            </div>
              <div
                className={clsx(`figma-task__controls flex items-center`, {
                  "justify-between": isMissionInProgress,
                  "justify-end": !isMissionInProgress,
                  "flex-col gap-4": isMobile,
                })}
              >
                {isMissionInProgress && (
                  <Button
                    large
                    type="secondary"
                    handler={() => {
                      onGiveUp();
                    }}
                    name={interfaceText.figmaTesting[locale()].giveUpButton}
                    fullWidth={isMobile}
                  />
                )}
                <Button
                  className={`figma-task__button-start ${isLoading ? "" : "active"}`}
                  name={
                    isLoading
                      ? interfaceText.figmaTestingMission[locale()].loading
                      : isMissionInProgress
                      ? interfaceText.figmaTestingMission[locale()].buttonWhenMissionStarted
                      : interfaceText.figmaTestingMission[locale()].button
                  }
                  handler={() => {
                    if (!isLoading) {
                      setIsTaskModalOpen(false);
                      setIsMissionInProgress(true);
                      if (!isMissionInProgress) {
                        onStart();
                      }
                    }
                  }}
                  large
                  disabled={isLoading}
                  type="primary"
                  fullWidth={isMobile}
                />
              </div>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default Task;

