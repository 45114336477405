import React from "react";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";

import PluginInstructions from "./PluginInstructions";

import Button from "../../../Button";
import Banner from "../../../Banner";

import { fetchPrototype } from "../../../../actions";
import { IFigmaPrototype } from "../../../../Common/schema/prototype";

import { getHelpCenterLink } from "../../../../utils/help";

interface IImportFormValues {
	code: string;
}
interface IPrototypeImportCodeFormProps {
	onSubmit: (value: { prototypeId: string, nodeNames: Record<string, string> }) => void;
}
const FigmaImportWithCode = (props: IPrototypeImportCodeFormProps) => {
	const initialValues = { code: "" } as IImportFormValues;
	const { t } = useTranslation();

	function codeValidator(value: IImportFormValues) {
		if (!value.code) return { code: t("Please, paste an import code") };
		if (!/^[a-zA-Z0-9]*$/.test(value.code))
			return { code: t("Invalid import code, check the correctness of this one") };
		return {};
	}

	async function onSubmit(values: IImportFormValues, helpers: FormikHelpers<IImportFormValues>) {
		const response = await fetchPrototype(values.code);

		if (!response.ok) {
			helpers.setErrors({
				code: t("Failed to load the prototype, сheck the correctness of the code"),
			});
			helpers.setSubmitting(false);
			return;
		}

		const prototype: IFigmaPrototype = await response.json();
		const nodeNames: Record<string, string> = {};

		for (const key in prototype.nodesForHtml) {
			nodeNames[key] = prototype.nodesForHtml[key].name;
		}

		await props.onSubmit({ prototypeId: values.code, nodeNames });
		helpers.setSubmitting(true);
	}

	return (
    <>
      <p className="text-base text-gray-700">
        {t(
          "When you import your prototype with our plugin, we convert it into a lightweight version for safe testing on any device, including various mobile devices. However, this method has some limitations."
        )}
      </p>
      <a
        href={getHelpCenterLink(t("/articles/1702485052-importing-figma-prototype-via-link-vs-via-plugin"))}
        type="default"
        className="mt-4 block text-base"
        target="_blank"
        rel="noreferrer"
      >
        {t("Read more: import with a link and using the Pathway plugin ↗")}
      </a>
      <PluginInstructions />
      <Formik initialValues={initialValues} validate={codeValidator} onSubmit={onSubmit}>
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form className="my-4" onSubmit={handleSubmit}>
            <div className="caption">{t("Prototype code")}</div>
            <>
              <input
                name="code"
                className="w-full default-input"
                placeholder={t("Please, paste your import code here")}
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <button type="submit">
                <Button
                  type="primary"
                  className="mt-4"
                  disabled={!!errors.code || isSubmitting}
                  name={t("Import")}
                  isLoading={isSubmitting}
                />
              </button>
            </>
          </form>
        )}
      </Formik>
    </>
  );
}

export default FigmaImportWithCode;