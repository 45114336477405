import React from "react";
import { v4 as uuidv4 } from "uuid";

export const replyTypes = [
  { name: "Free text input", type: "freeform" },
  { name: "Single Choise", type: "single" },
  { name: "Multiple Choise", type: "multi" },
  { name: "Scale 0 to 5", type: "scale" },
];

export function isGlobalApp() {
  return window.pwconfig.firebase_config.projectId === "pathway-global";
}

function generateId() {
  return uuidv4();
}

export const initialContent = () => {
  const getReplies = (withImage: boolean) => {
    return [
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
    ];
  };

  return {
    openquestion: {
      image: "",
      text: "",
      type: "openquestion",
      isOptional: false,
    },
    choice: {
      image: "",
      replies: getReplies(false),
      replyType: "single",
      randomized: false,
      other: false,
      isOptional: false,
      text: "",
      type: "choice",
    },
    preference: {
      replies: getReplies(true),
      replyType: "single",
      randomized: true,
      text: "",
      type: "preference",
      doNotShowZoomedImage: false,
    },
    firstclick: {
      image: "",
      text: "",
      type: "firstclick",
    },
    fiveseconds: {
      image: "",
      time: 5,
      withCustomInstruction: false,
      type: "fiveseconds",
    },
    figma: {
      fileId: "",
      text: "",
      goalNode: [],
      type: "figma",
      nodeImages: {},
      nodeNames: {},
      scaling: "scale-down-width",
    },
    context: {
      image: "",
      text: "",
      type: "context",
    },
    cardsort: {
      text: "",
      cards: [],
      categories: [],
      shuffleCards: true,
      shuffleCategories: true,
      doNotRequireToSortAll: false,
      withImages: false,
      withDescription: false,
      type: "cardsort",
    },
    matrix: {
      text: "",
      rows: [],
      columns: [],
      replyType: "single",
      type: "matrix",
    },
    scale: {
      image: "",
      text: "",
      replyType: "numeric",
      from: "1",
      to: "5",
      selections: "5",
      startLabel: "",
      endLablel: "",
      isOptional: false,
      type: "scale",
    },
    ai: {
      type: "ai",
      text: "",
      instruction: "",
      isOptional: false,
    }
  };
};

export function getLocale() {
  const sessionLanguage = window.sessionStorage.getItem("language");
  if (sessionLanguage && sessionLanguage.length > 0 && sessionLanguage !== "auto") {
    return sessionLanguage;
  } else {
    const lang = navigator.language;
    if (lang.includes("ru")) {
      return "ru";
    } else if (lang.includes("uz")) {
      return "uz"; 
    } else if (lang.includes("hi")) {
      return "hi";
    } else if (lang.includes("es")) {
      return "es";
    } else if (lang.includes("fr")) {
      return "fr";
    } else if (lang.includes("pt")) {
      return "pt";
    } else if (lang.includes("de")) {
      return "de";
    } else if (lang.includes("jv")) {
      return "jv";
    } else if (lang.includes("tr")) {
      return "tr";
    } else if (lang.includes("ar")) {
      return "ar";
    } else if (lang.includes("kk")) {
      return "kk";
    } else if (lang.includes("id")) {
      return "id";
    } else if (lang.includes("en" || "UK" || "US")) {
      return "en";
    } else {
      return "en";
    }
  }
}

export const locale = getLocale;

export const interfaceText: Record<string, any> = {
  preferenceTesting: {
    ru: {
      showImages: "Показать изображения",
    },
    en: {
      showImages: "Show images",
    },
    uz: {
      showImages: "Rasmni ko'rsatish",
    },
    hi: {
      showImages: "छवियाँ दिखाएं",
    },
    es: {
      showImages: "Mostrar imágenes",
    },
    fr: {
      showImages: "Afficher les images",
    },
    pt: {
      showImages: "Mostrar imagens",
    },
    de: {
      showImages: "Bilder anzeigen",
    },
    jv: {
      showImages: "Tampilake gambar",
    },
    tr: {
      showImages: "Görüntüleri göster",
    },
    ar: {
      showImages: "عرض الصور",
    },
    kk: {
      showImages: "Суреттерді көрсету",
    },
  },
  firstclickTesting: {
    ru: {
      confirmButton: "Подтвердить",
      cancelButton: "Отмена",
      showImage: "Показать изображение",
      task: "Задание",
    },
    en: {
      confirmButton: "Confirm click",
      cancelButton: "Cancel", 
      showImage: "Show image",
      task: "Task",
    },
    id: {
      confirmButton: "Konfirmasi klik",
      cancelButton: "Batal",
      showImage: "Tampilkan gambar",
      task: "Tugas",
    },
    uz: {
      confirmButton: "Tasdiqlash",
      cancelButton: "Bekor qilish",
      showImage: "Rasmni ko'rsatish",
    },
    hi: {
      confirmButton: "क्लिक की पुष्टि करें",
      cancelButton: "रद्द करें",
      showImage: "छवि दिखाएं",
      task: "कार्य",
    },
    es: {
      confirmButton: "Confirmar clic",
      cancelButton: "Cancelar",
      showImage: "Mostrar imagen",
      task: "Tarea",
    },
    fr: {
      confirmButton: "Confirmer le clic",
      cancelButton: "Annuler",
      showImage: "Afficher l'image",
      task: "Tâche",
    },
    pt: {
      confirmButton: "Confirmar clique",
      cancelButton: "Cancelar",
      showImage: "Mostrar imagem",
      task: "Tarefa",
    },
    de: {
      confirmButton: "Klick bestätigen",
      cancelButton: "Abbrechen",
      showImage: "Bild anzeigen",
      task: "Aufgabe",
    },
    jv: {
      confirmButton: "Konfirmasi klik",
      cancelButton: "Batal",
      showImage: "Tampilake gambar",
      task: "Tugas",
    },
    tr: {
      confirmButton: "Tıklamayı onayla",
      cancelButton: "İptal",
      showImage: "Görüntüyü göster",
      task: "Görev",
    },
    ar: {
      confirmButton: "تأكيد النقر",
      cancelButton: "إلغاء",
      showImage: "عرض الصورة",
      task: "مهمة",
    },
    kk: {
      confirmButton: "Басуды растау",
      cancelButton: "Бас тарту",
      showImage: "Суретті көрсету",
      task: "Тапсырма",
    },
  },
  figmaTestingIntro: {
    ru: {
      header: "Задание с прототипом",
      instruction: "Инструкция",
      text: "Сейчас вы увидите макеты с элементами, на которые можно нажимать. Некоторые элементы на макетах могут не работать или быть недоступными для нажатия.",
      button: "Хорошо",
    },
    en: {
      header: "Prototype task",
      instruction: "Instruction",
      text: "You'll see a prototype with interactive elements. It’s not a final product, some elements may not work or be clickable.",
      button: "Got it",
    },
    id: {
      header: "Tugas prototipe",
      instruction: "Instruksi",
      text: "Anda akan melihat prototipe dengan elemen interaktif. Ini bukan produk akhir, beberapa elemen mungkin tidak berfungsi atau tidak dapat diklik.",
      button: "Mengerti",
    },
    uz: {
      header: "Prototip bilan vazifa",
      instruction: "Qo'llanma",
      text: "Siz interaktiv elementlarga ega prototipni ko'rasiz. Bu yakuniy mahsulot emas, ba'zi elementlar ishlamaydi yoki bosib bo'lmaydi.",
      button: "Tushunarli",
    },
    hi: {
      header: "प्रोटोटाइप कार्य",
      instruction: "निर्देश",
      text: "आप एक प्रोटोटाइप देखेंगे जिसमें इंटरैक्टिव तत्व हैं। यह अंतिम उत्पाद नहीं है, कुछ तत्व काम नहीं कर सकते हैं या क्लिक करने योग्य नहीं हो सकते हैं।",
      button: "समझ गया",
    },
    es: {
      header: "Tarea de prototipo",
      instruction: "Instrucción",
      text: "Verás un prototipo con elementos interactivos. No es un producto final, algunos elementos pueden no funcionar o no ser clicables.",
      button: "Entendido",
    },
    fr: {
      header: "Tâche de prototype",
      instruction: "Instruction",
      text: "Vous verrez un prototype avec des éléments interactifs. Ce n'est pas un produit final, certains éléments peuvent ne pas fonctionner ou être cliquables.",
      button: "Compris",
    },
    pt: {
      header: "Tarefa de protótipo",
      instruction: "Instrução",
      text: "Você verá um protótipo com elementos interativos. Não é um produto final, alguns elementos podem não funcionar ou não ser clicáveis.",
      button: "Entendi",
    },
    de: {
      header: "Prototyp-Aufgabe",
      instruction: "Anleitung",
      text: "Sie sehen einen Prototyp mit interaktiven Elementen. Es ist kein Endprodukt, einige Elemente funktionieren möglicherweise nicht oder sind nicht anklickbar.",
      button: "Verstanden",
    },
    jv: {
      header: "Tugas prototipe",
      instruction: "Instruksi",
      text: "Sampeyan bakal weruh prototipe kanthi unsur interaktif. Iki dudu produk pungkasan, sawetara unsur bisa uga ora bisa digunakake utawa ora bisa diklik.",
      button: "Ngerti",
    },
    tr: {
      header: "Prototip görevi",
      instruction: "Talimat",
      text: "Etkileşimli unsurlara sahip bir prototip göreceksiniz. Bu nihai ürün değil, bazı unsurlar çalışmayabilir veya tıklanabilir olmayabilir.",
      button: "Anladım",
    },
    ar: {
      header: "مهمة النموذج الأولي",
      instruction: "تعليمات",
      text: "سترى نموذجًا أوليًا بعناصر تفاعلية. إنه ليس منتجًا نهائيًا، قد لا تعمل بعض العناصر أو لا يمكن النقر عليها.",
      button: "فهمت",
    },
    kk: {
      header: "Прототип тапсырмасы",
      instruction: "Нұсқаулық",
      text: "Сіз интерактивті элементтері бар прототипті көресіз. Бұл соңғы өнім емес, кейбір элементтер жұмыс істемеуі немесе басылмауы мүмкін.",
      button: "Түсіндім",
    },
  },
  figmaTestingMission: {
    ru: {
      header: "Ваше задание",
      button: "Начать",
      buttonWhenMissionStarted: "Продолжить",
      loading: "Подождите немного...",
    },
    en: {
      header: "Your task",
      button: "Let's start",
      buttonWhenMissionStarted: "Continue",
      loading: "Hold on for a second...",
    },
    id: {
      header: "Tugas Anda",
      button: "Mari mulai",
      buttonWhenMissionStarted: "Lanjutkan",
      loading: "Tunggu sebentar...",
    },
    uz: {
      header: "Sizning vazifangiz",
      button: "Boshlash",
      buttonWhenMissionStarted: "Vazifaga qaytish",
      loading: "Bir ozgina kuting...",
    },
    hi: {
      header: "आपका कार्य",
      button: "शुरू करें",
      buttonWhenMissionStarted: "जारी रखें",
      loading: "एक सेकंड रुकें...",
    },
    es: {
      header: "Tu tarea",
      button: "Empecemos",
      buttonWhenMissionStarted: "Continuar",
      loading: "Espera un segundo...",
    },
    fr: {
      header: "Votre tâche",
      button: "Commençons",
      buttonWhenMissionStarted: "Continuer",
      loading: "Attendez une seconde...",
    },
    pt: {
      header: "Sua tarefa",
      button: "Vamos começar",
      buttonWhenMissionStarted: "Continuar",
      loading: "Espere um segundo...",
    },
    de: {
      header: "Ihre Aufgabe",
      button: "Lass uns anfangen",
      buttonWhenMissionStarted: "Fortsetzen",
      loading: "Warten Sie eine Sekunde...",
    },
    jv: {
      header: "Tugas sampeyan",
      button: "Ayo miwiti",
      buttonWhenMissionStarted: "Terusake",
      loading: "Tunggu sedhela...",
    },
    tr: {
      header: "Göreviniz",
      button: "Başlayalım",
      buttonWhenMissionStarted: "Devam et",
      loading: "Bir saniye bekleyin...",
    },
    ar: {
      header: "مهمتك",
      button: "لنبدأ",
      buttonWhenMissionStarted: "استمر",
      loading: "انتظر لحظة...",
    },
    kk: {
      header: "Сіздің тапсырмаңыз",
      button: "Бастайық",
      buttonWhenMissionStarted: "Жалғастыру",
      loading: "Бір секунд күтіңіз...",
    },
  },
  figmaTesting: {
    ru: {
      showTaskTip: "Если вы забыли задание или хотите сдаться — нажмите на синюю полоску",
      showTaskTipButton: "Хорошо",
      giveUpButton: "Не могу выполнить задание",
      missionButton: "Показать задание",
    },
    en: {
      showTaskTip: "In case you forgot the task or want to give up — click on this blue stripe.",
      showTaskTipButton: "Ok",
      giveUpButton: "Can't complete the task",
      missionButton: "Show mission",
    },
    id: {
      showTaskTip: "Jika Anda lupa tugas atau ingin menyerah — klik pada garis biru ini.",
      showTaskTipButton: "Ok",
      giveUpButton: "Tidak bisa menyelesaikan tugas",
      missionButton: "Tampilkan misi",
    },
    uz: {
      showTaskTip: "Agar vazifani unutib qolsangiz yoki vazifani bajaramasangiz — ko'k chiziqni bosing.",
      showTaskTipButton: "Tushunarli",
      giveUpButton: "Vazifani bajara olmayman",
      missionButton: "Vazifani ko'rsatish",
    },
    hi: {
      showTaskTip: "यदि आप कार्य भूल गए हैं या हार मानना चाहते हैं — इस नीली पट्टी पर क्लिक करें।",
      showTaskTipButton: "ठीक है",
      giveUpButton: "कार्य पूरा नहीं कर सकता",
      missionButton: "मिशन दिखाएं",
    },
    es: {
      showTaskTip: "En caso de que olvides la tarea o quieras rendirte — haz clic en esta franja azul.",
      showTaskTipButton: "Ok",
      giveUpButton: "No puedo completar la tarea",
      missionButton: "Mostrar misión",
    },
    fr: {
      showTaskTip: "En cas d'oubli de la tâche ou si vous souhaitez abandonner — cliquez sur cette bande bleue.",
      showTaskTipButton: "D'accord",
      giveUpButton: "Je ne peux pas terminer la tâche",
      missionButton: "Afficher la mission",
    },
    pt: {
      showTaskTip: "Caso você esqueça a tarefa ou queira desistir — clique nesta faixa azul.",
      showTaskTipButton: "Ok",
      giveUpButton: "Não consigo completar a tarefa",
      missionButton: "Mostrar missão",
    },
    de: {
      showTaskTip: "Falls Sie die Aufgabe vergessen haben oder aufgeben möchten — klicken Sie auf diesen blauen Streifen.",
      showTaskTipButton: "Ok",
      giveUpButton: "Kann die Aufgabe nicht abschließen",
      missionButton: "Mission anzeigen",
    },
    jv: {
      showTaskTip: "Yen sampeyan lali tugas utawa pengin nyerah — klik ing garis biru iki.",
      showTaskTipButton: "Ok",
      giveUpButton: "Ora bisa ngrampungake tugas",
      missionButton: "Tampilake misi",
    },
    tr: {
      showTaskTip: "Görevi unuttuysanız veya pes etmek istiyorsanız — bu mavi şeride tıklayın.",
      showTaskTipButton: "Tamam",
      giveUpButton: "Görevi tamamlayamıyorum",
      missionButton: "Görevi göster",
    },
    ar: {
      showTaskTip: "في حال نسيت المهمة أو أردت الاستسلام — انقر على هذا الشريط الأزرق.",
      showTaskTipButton: "حسنا",
      giveUpButton: "لا أستطيع إكمال المهمة",
      missionButton: "عرض المهمة",
    },
    kk: {
      showTaskTip: "Егер тапсырманы ұмытып қалсаңыз немесе бас тартқыңыз келсе — осы көк жолаққа басыңыз.",
      showTaskTipButton: "Жақсы",
      giveUpButton: "Тапсырманы орындай алмаймын",
      missionButton: "Миссияны көрсету",
    },
  },
  figmaTestingSuccess: {
    ru: {
      header: "Поздравляем, вы справились с заданием",
      button: "Далее →",
    },
    en: {
      header: "Congratulations, you've completed the task",
      button: "Next →",
    },
    id: {
      header: "Selamat, Anda telah menyelesaikan tugas",
      button: "Berikutnya →",
    },
    uz: {
      header: "Tabriklaymiz!",
      button: "Keyingi →",
      text: "Siz vazifani muvaffaqiyatli bajardingiz.",
    },
    hi: {
      header: "बधाई हो, आपने कार्य पूरा कर लिया है",
      button: "अगला →",
    },
    es: {
      header: "Felicidades, has completado la tarea",
      button: "Siguiente →",
    },
    fr: {
      header: "Félicitations, vous avez terminé la tâche",
      button: "Suivant →",
    },
    pt: {
      header: "Parabéns, você completou a tarefa",
      button: "Próximo →",
    },
    de: {
      header: "Herzlichen Glückwunsch, Sie haben die Aufgabe abgeschlossen",
      button: "Weiter →",
    },
    jv: {
      header: "Sugeng, sampeyan wis ngrampungake tugas",
      button: "Sabanjure →",
    },
    tr: {
      header: "Tebrikler, görevi tamamladınız",
      button: "Sonraki →",
    },
    ar: {
      header: "تهانينا، لقد أكملت المهمة",
      button: "التالي →",
    },
    kk: {
      header: "Құттықтаймыз, сіз тапсырманы орындадыңыз",
      button: "Келесі →",
    },
  },
  thankYou: {
    ru: "Спасибо за участие",
    en: "Thank you for participating",
    id: "Terima kasih telah berpartisipasi",
    uz: "Qatnashganingiz uchun rahmat",
    hi: "भाग लेने के लिए धन्यवाद",
    es: "Gracias por participar",
    fr: "Merci de participer",
    pt: "Obrigado por participar",
    de: "Danke für Ihre Teilnahme",
    jv: "Matur nuwun kanggo melu",
    tr: "Katıldığınız için teşekkürler",
    ar: "شكرًا لمشاركتك",
    kk: "Қатысқаныңыз үшін рахмет",
  },
  thankYouButton: {
    ru: "Завершить тест",
    en: "Complete the test",
    id: "Selesaikan tes",
    uz: "Testni yakunlash",
    hi: "परीक्षण पूरा करें",
    es: "Completar la prueba",
    fr: "Terminer le test",
    pt: "Concluir o teste",
    de: "Test abschließen",
    jv: "Rampungake tes",
    tr: "Testi tamamla",
    ar: "أكمل الاختبار",
    kk: "Тесті аяқтау",
  },
  pageNotFound: {
    ru: { header: "Страница не найдена", text: "Простите, здесь ничего нет" },
    en: { header: "Page Not Found", text: "Sorry, nothing here" },
    id: { header: "Halaman Tidak Ditemukan", text: "Maaf, tidak ada apa-apa di sini" },
    uz: { header: "Sahifa topilmadi", text: "Kechirasiz, bu erda hech narsa yo'q" },
    hi: { header: "पृष्ठ नहीं मिला", text: "माफ करें, यहाँ कुछ नहीं है" },
    es: { header: "Página no encontrada", text: "Lo siento, no hay nada aquí" },
    fr: { header: "Page non trouvée", text: "Désolé, rien ici" },
    pt: { header: "Página não encontrada", text: "Desculpe, nada aqui" },
    de: { header: "Seite nicht gefunden", text: "Entschuldigung, nichts hier" },
    jv: { header: "Kaca ora ditemokake", text: "Nuwun sewu, ora ana apa-apa ing kene" },
    tr: { header: "Sayfa Bulunamadı", text: "Üzgünüz, burada bir şey yok" },
    ar: { header: "الصفحة غير موجودة", text: "عذرًا، لا يوجد شيء هنا" },
    kk: { header: "Бет табылмады", text: "Кешіріңіз, мұнда ештеңе жоқ" },
  },
  testIsntAcceptingNewResponses: {
    ru: "Этот тест больше не принимает новые ответы.",
    en: "This test isn't accepting new responses.",
    id: "Tes ini tidak menerima tanggapan baru.",
    uz: "Bu test yangi javoblar qabul qilmaydi.",
    hi: "यह परीक्षण अब नए उत्तर स्वीकार नहीं कर रहा है।",
    es: "Esta prueba no está aceptando nuevas respuestas.",
    fr: "Ce test n'accepte plus de nouvelles réponses.",
    pt: "Este teste não está aceitando novas respostas.",
    de: "Dieser Test akzeptiert keine neuen Antworten mehr.",
    jv: "Tes iki ora nampa tanggapan anyar.",
    tr: "Bu test yeni yanıtlar kabul etmiyor.",
    ar: "هذا الاختبار لا يقبل ردود جديدة.",
    kk: "Бұл тест жаңа жауаптарды қабылдамайды.",
  },
  requestFailed: {
    ru: { header: "Не удалось загрузить данные", button: "Попробовать еще" },
    en: { header: "Failed to load data", button: "Try again" },
    id: { header: "Gagal memuat data", button: "Coba lagi" },
    uz: { header: "Ma'lumotlar yuklanmadi", button: "Qayta urinib ko'ring" },
    hi: { header: "डेटा लोड करने में विफल", button: "फिर से प्रयास करें" },
    es: { header: "Error al cargar los datos", button: "Intentar de nuevo" },
    fr: { header: "Échec du chargement des données", button: "Réessayer" },
    pt: { header: "Falha ao carregar dados", button: "Tente novamente" },
    de: { header: "Daten konnten nicht geladen werden", button: "Erneut versuchen" },
    jv: { header: "Gagal ngunggah data", button: "Coba maneh" },
    tr: { header: "Veri yüklenemedi", button: "Tekrar dene" },
    ar: { header: "فشل في تحميل البيانات", button: "حاول مرة أخرى" },
    kk: { header: "Деректерді жүктеу сәтсіз аяқталды", button: "Қайтадан көріңіз" },
  },
  verificationCode: {
    ru: "Код подтверждения:",
    en: "Verification code:",
    id: "Kode verifikasi:",
    uz: "Tasdiqlash kodi:",
    hi: "सत्यापन कोड:",
    es: "Código de verificación:",
    fr: "Code de vérification:",
    pt: "Código de verificação:",
    de: "Bestätigungscode:",
    jv: "Kode verifikasi:",
    tr: "Doğrulama kodu:",
    ar: "رمز التحقق:",
    kk: "Растау коды:",
  },
  deviceIsNotAllowed: {
    ru: {
      mobile: "Этот тест доступен для прохождения только на мобильном устройстве. Пожалуйста, откройте его на телефоне.",
      desktop: "Этот тест доступен для прохождения только на десктопном устройстве. Пожалуйста, откройте его на компьютере.",
    },
    en: {
      mobile: "This test is available only on mobile devices. Please, open it on your phone.",
      desktop: "This test is available only on desktop devices. Please, open it on your PC.",
    },
    id: {
      mobile: "Tes ini hanya tersedia di perangkat seluler. Silakan buka di ponsel Anda.",
      desktop: "Tes ini hanya tersedia di perangkat desktop. Silakan buka di PC Anda.",
    },
    uz: {
      mobile: "Bu testni faqat mobil qurilmada o'tish mumkin. Iltimos, uni telefonda oching.",
      desktop: "Bu testni faqat desktop qurilmada o'tish mumkin. Iltimos, uni kompyuterda oching.",
    },
    hi: {
      mobile: "यह परीक्षण केवल मोबाइल उपकरणों पर उपलब्ध है। कृपया इसे अपने फोन पर खोलें।",
      desktop: "यह परीक्षण केवल डेस्कटॉप उपकरणों पर उपलब्ध है। कृपया इसे अपने पीसी पर खोलें।",
    },
    es: {
      mobile: "Esta prueba está disponible solo en dispositivos móviles. Por favor, ábrelo en tu teléfono.",
      desktop: "Esta prueba está disponible solo en dispositivos de escritorio. Por favor, ábrelo en tu PC.",
    },
    fr: {
      mobile: "Ce test est disponible uniquement sur les appareils mobiles. Veuillez l'ouvrir sur votre téléphone.",
      desktop: "Ce test est disponible uniquement sur les appareils de bureau. Veuillez l'ouvrir sur votre PC.",
    },
    pt: {
      mobile: "Este teste está disponível apenas em dispositivos móveis. Por favor, abra-o no seu telefone.",
      desktop: "Este teste está disponível apenas em dispositivos de desktop. Por favor, abra-o no seu PC.",
    },
    de: {
      mobile: "Dieser Test ist nur auf mobilen Geräten verfügbar. Bitte öffnen Sie es auf Ihrem Telefon.",
      desktop: "Dieser Test ist nur auf Desktop-Geräten verfügbar. Bitte öffnen Sie es auf Ihrem PC.",
    },
    jv: {
      mobile: "Tes iki mung kasedhiya ing piranti seluler. Mangga, bukak ing telpon sampeyan.",
      desktop: "Tes iki mung kasedhiya ing piranti desktop. Mangga, bukak ing PC sampeyan.",
    },
    tr: {
      mobile: "Bu test yalnızca mobil cihazlarda kullanılabilir. Lütfen telefonunuzda açın.",
      desktop: "Bu test yalnızca masaüstü cihazlarda kullanılabilir. Lütfen bilgisayarınızda açın.",
    },
    ar: {
      mobile: "هذا الاختبار متاح فقط على الأجهزة المحمولة. يرجى فتحه على هاتفك.",
      desktop: "هذا الاختبار متاح فقط على أجهزة سطح المكتب. يرجى فتحه على جهاز الكمبيوتر الخاص بك.",
    },
    kk: {
      mobile: "Бұл тест тек мобильді құрылғыларда қол жетімді. Оны телефоныңызда ашыңыз.",
      desktop: "Бұл тест тек жұмыс үстелі құрылғыларында қол жетімді. Оны компьютеріңізде ашыңыз.",
    },
  },
  recording: {
    ru: "Запись...",
    en: "Recording...",
    id: "Merekam...",
    uz: "Yozish...",
    hi: "रिकॉर्डिंग...",
    es: "Grabando...",
    fr: "Enregistrement...",
    pt: "Gravando...",
    de: "Aufnahme...",
    jv: "Rekaman...",
    tr: "Kayıt...",
    ar: "تسجيل...",
    kk: "Жазу...",
  },
  typeMessage: {
    ru: "Введите текст ответа",
    en: "Type message here...",
    id: "Ketik pesan di sini...",
    uz: "Javob matnini kiriting",
    hi: "यहां संदेश टाइप करें...",
    es: "Escribe el mensaje aquí...",
    fr: "Tapez le message ici...",
    pt: "Digite a mensagem aqui...",
    de: "Nachricht hier eingeben...",
    jv: "Ketik pesen ing kene...",
    tr: "Mesajı buraya yazın...",
    ar: "اكتب الرسالة هنا...",
    kk: "Хабарды осында теріңіз...",
  },
  test: {
    ru: {
      recordAudioTooltip: "Нажмите, чтобы записать ответ голосом",
      makeSureToViewAllImagesBeforeChoosingOne: "Посмотрите все изображения, прежде чем выбрать одно из них",
      tryPathway: "Попробуйте Pathway →",
      aboutPathway: "Этот тест создан при помощи Pathway — платформы, которая помогает современным продуктовым командам быстро получать инсайты от своих пользователей.",
      makeSureToViewAllOfTheOptions: "Просмотрите все изображения, прежде чем выбрать",
      showTask: "Показать задание",
      followUpQuestion: "Дополнительный вопрос",
      fiveSecondsHeader: "Сосредоточьтесь на изображении",
      fiveSecondsText:
        "Вы будете просматривать изображение в течение ограниченного времени. Постарайтесь понять, о чем это изображение, и запомните как можно больше информации.",
      inputPlaceholder: "Введите текст ответа",
      aiQuestionInputPlaceholder: "Введите текста ответа или запишите аудио",
      nextButton: "Далее →",
      preferenceNextButton: "Выбрать",
      preferenceZoomedImageCloseButton: "Закрыть",
      multipleChoiceTip: "Вы можете выбрать несколько вариантов ответа.",
      multipleChoiceWithLimitTip: "Вы можете выбрать до {{count}} вариантов ответа.",
      other: "Другое",
      otherPlaceholder: "Введите текст ответа",
      cardSortFinished: "Все карточки отсортированы",
      cardSortCategories: "Категории",
      cardSortCards: "Карточки",
      cardSortLeft: "осталось",
      cardSortNextButton: "Завершить сортировку",
      cardSortStart: "Начать",
      cardSortTask: (
        <>
          Пожалуйста, отсортируйте карточки по представленным категориям. <br />
          <br />В этом задании нет правильных или неправильных ответов, просто распределите карточки так, как вам кажется наиболее
          подходящим.
        </>
      ),
      cardSort: "Сортировка карточек",
      dragCardsToCreateCategories: "Перетащите карточки, чтобы создать категорию",
      dragCardHereToCreateCategory: "Перетащите сюда, чтобы создать категорию",
      enterCategoryName: "Введите название категории",
      dragCardHereToAddToCategory: "Перетащите сюда, чтобы добавить в категорию",
      cardSortTaskTitle: "Задание",
      cards: "Карточки",
      allSorted: "Все карточки отсортированы",
      allDone: "Готово",
      openSortInstuction: "Отсортируйте каждую карточку в категорию, которая вам кажется наиболее подходящей. Перетащите карточки в правую часть страницы, чтобы создать категории.",
      closedSortInstuction: "Отсортируйте каждую карточку в категорию, которая вам кажется наиболее подходящей.",
      doWhatComesNaturally: "Просто делайте то, что кажется вам наиболее подходящим, нет правильных или неправильных ответов.",
      start: "Начать",
      instruction: "Инструкция",
      task: "Задание",
      categoryNameRequired: "Введите название категории"
    },
    en: {
      recordAudioTooltip: "Click to record voice reply",
      makeSureToViewAllImagesBeforeChoosingOne: "Make sure to view all of the images before choosing one",
      tryPathway: "Try Pathway →",
      aboutPathway: "This test was created with Pathway, a tool for modern product teams to swiftly get insights from their users.",
      makeSureToViewAllOfTheOptions: "Make sure to view all of the images before choosing one",
      showTask: "Show task",
      followUpQuestion: "Follow-up question",
      fiveSecondsHeader: "Focus on the image",
      fiveSecondsText:
        "You will view an image for a limited time. Try to understand what the image is about and remember as much information as you can.",
      inputPlaceholder: "Enter reply here",
      aiQuestionInputPlaceholder: "Enter reply here or record audio...",
      nextButton: "Next →",
      preferenceNextButton: "Choose this one",
      preferenceZoomedImageCloseButton: "Сlose",
      multipleChoiceTip: "You can select multiple options.",
      multipleChoiceWithLimitTip: "You can select up to {{count}} options.",
      other: "Other",
      otherPlaceholder: "Enter reply here",
      cardSortFinished: "You've sorted all of the cards",
      cardSortCategories: "Categories",
      cardSortCards: "Cards",
      cardSortLeft: "left",
      cardSortNextButton: "Finish sorting",
      cardSortStart: "Let's start",
      cardSortTask: (
        <>
          Sort each card into the category that makes sense to you.
          <br />
          <br />
          Do what comes naturally, there is no right or wrong answers.
        </>
      ),
      cardSort: "Card sort",
      dragCardsToCreateCategories: "Drag cards here to create categories",
      dragCardHereToCreateCategory: "Drag card here to create category",
      enterCategoryName: "Enter category name",
      dragCardHereToAddToCategory: "Drag card here to add to category",
      cardSortTaskTitle: "Task",
      cards: "Cards",
      allSorted: "You've sorted all of the cards",
      allDone: "I'm done",
      openSortInstuction: "Sort each card into the category that makes sense to you. Drag and drop cards to the right part of the page to create categories.",
      closedSortInstuction: "Sort each card into the category that makes sense to you.",
      doWhatComesNaturally: "Do what comes naturally, there is no right or wrong answers.",
      start: "Start",
      instruction: "Instruction",
      task: "Task",
      preferenceTitle: "Image choice",
      categoryNameRequired: "Enter category name"
    },
    id: {
      recordAudioTooltip: "Klik untuk merekam balasan suara",
      makeSureToViewAllImagesBeforeChoosingOne: "Pastikan untuk melihat semua gambar sebelum memilih satu",
      tryPathway: "Coba Pathway →",
      aboutPathway: "Tes ini dibuat dengan Pathway, alat untuk tim produk modern untuk dengan cepat mendapatkan wawasan dari pengguna mereka.",
      makeSureToViewAllOfTheOptions: "Pastikan untuk melihat semua gambar sebelum memilih satu",
      showTask: "Tampilkan tugas",
      followUpQuestion: "Pertanyaan lanjutan",
      fiveSecondsHeader: "Fokus pada gambar",
      fiveSecondsText:
        "Anda akan melihat gambar untuk waktu yang terbatas. Cobalah untuk memahami tentang apa gambar itu dan ingat sebanyak mungkin informasi.",
      inputPlaceholder: "Masukkan balasan di sini",
      aiQuestionInputPlaceholder: "Masukkan balasan di sini atau rekam audio...",
      nextButton: "Berikutnya →",
      preferenceNextButton: "Pilih ini",
      preferenceZoomedImageCloseButton: "Tutup",
      multipleChoiceTip: "Anda dapat memilih beberapa opsi.",
      multipleChoiceWithLimitTip: "Anda dapat memilih hingga {{count}} opsi.",
      other: "Lainnya",
      otherPlaceholder: "Masukkan balasan di sini",
      cardSortFinished: "Anda telah mengurutkan semua kartu",
      cardSortCategories: "Kategori",
      cardSortCards: "Kartu",
      cardSortLeft: "tersisa",
      cardSortNextButton: "Selesaikan pengurutan",
      cardSortStart: "Mari mulai",
      cardSortTask: (
        <>
          Urutkan setiap kartu ke dalam kategori yang masuk akal bagi Anda.
          <br />
          <br />
          Lakukan apa yang alami, tidak ada jawaban yang benar atau salah.
        </>
      ),
      cardSort: "Pengurutan kartu",
      dragCardsToCreateCategories: "Seret kartu ke sini untuk membuat kategori",
      dragCardHereToCreateCategory: "Seret kartu ke sini untuk membuat kategori",
      enterCategoryName: "Masukkan nama kategori",
      dragCardHereToAddToCategory: "Seret kartu ke sini untuk menambahkan ke kategori",
      cardSortTaskTitle: "Tugas",
      cards: "Kartu",
      allSorted: "Anda telah mengurutkan semua kartu",
      allDone: "Saya selesai",
      openSortInstuction: "Urutkan setiap kartu ke dalam kategori yang masuk akal bagi Anda. Seret dan lepas kartu ke bagian kanan halaman untuk membuat kategori.",
      closedSortInstuction: "Urutkan setiap kartu ke dalam kategori yang masuk akal bagi Anda.",
      doWhatComesNaturally: "Lakukan apa yang alami, tidak ada jawaban yang benar atau salah.",
      start: "Mulai",
      instruction: "Instruksi",
      task: "Tugas",
      preferenceTitle: "Pilihan gambar",
      categoryNameRequired: "Masukkan nama kategori"
    },
    uz: {
      fiveSecondsHeader: "Rasmga e'tibor bering",
      fiveSecondsText:
        "Siz rasmni cheklangan vaqt davomida ko'rasiz. Ushbu rasm nima haqida ekanligini tushunishga harakat qiling va iloji boricha ko'proq ma'lumotni eslab qoling.",
      inputPlaceholder: "Javob matnini kiriting",
      nextButton: "Keyingi →",
      preferenceNextButton: "Tanlash",
      preferenceZoomedImageCloseButton: "Yopish",
      multipleChoiceTip: "Siz bir nechta javob variantlarini tanlashingiz mumkin.",
      multipleChoiceWithLimitTip: "Siz {{count}} tanovchi tanlashingiz mumkin.",
      other: "Boshqa",
      otherPlaceholder: "Javob matnini kiriting",
      cardSortFinished: "Barcha kartochkalar saralangan",
      cardSortCategories: "Toifalar",
      cardSortCards: "Kartochkalar",
      cardSortLeft: "qoldi",
      cardSortNextButton: "Saralashni yakunlash",
      cardSortStart: "Boshlash",
      cardSortTask: (
        <>
          Iltimos, kartochkalarni ko'rsatilgan toifalar bo'yicha saralang. <br />
          <br />Bu vazifada to'g'ri yoki noto'g'ri javoblar yo'q, shunchaki kartalarni sizga eng mos keladigan tarzda taqsimlang.
        </>
      ),
      cardSort: "Kartochkalar saralash",
      dragCardsToCreateCategories: "Toifa yaratish uchun kartochkalarni bir joydan ikkinchi joyga olib o’ting",
      dragCardHereToCreateCategory: "Toifa yaratish uchun kartochkani bu joyga olib o’ting",
      enterCategoryName: "Toifa nomini kiriting",
      dragCardHereToAddToCategory: "Toifaga qo’shish uchun kartochkani bu joyga olib o’ting",
      cardSortTaskTitle: "Vazifa",
      cards: "Kartochkalar",
      allSorted: "Barcha kartochkalar saralangan",
      allDone: "Tayyor",
      openSortInstuction: "Har bir kartochkani sizga eng mos keladigan toifaga saralang. Toifalar yaratish uchun kartochkalarni sahifани o’ng qismiga olib o’ting.",
      closedSortInstuction: "Har bir kartochkani sizga eng mos keladigan toifага saralang.",
      doWhatComesNaturally: "Sizga eng mos keladigan narsani qiling, to'g'ri yoki noto'g'ri javoblar yo'q.",
      start: "Boshlash",
      instruction: "Qo’llanma",
      task: "Vazifa",
      categoryNameRequired: "Kategoriya nomini kiriting"
    },
    hi: {
      recordAudioTooltip: "वॉयस उत्तर रिकॉर्ड करने के लिए क्लिक करें",
      makeSureToViewAllImagesBeforeChoosingOne: "किसी एक को चुनने से पहले सभी छवियों को देखना सुनिश्चित करें",
      tryPathway: "Pathway आज़माएं →",
      aboutPathway: "यह परीक्षण Pathway के साथ बनाया गया था, जो आधुनिक उत्पाद टीमों के लिए अपने उपयोगकर्ताओं से तेजी से अंतर्दृष्टि प्राप्त करने का एक उपकरण है।",
      makeSureToViewAllOfTheOptions: "किसी एक को चुनने से पहले सभी छवियों को देखना सुनिश्चित करें",
      showTask: "कार्य दिखाएं",
      followUpQuestion: "अनुवर्ती प्रश्न",
      fiveSecondsHeader: "छवि पर ध्यान केंद्रित करें",
      fiveSecondsText: "आप सीमित समय के लिए एक छवि देखेंगे। यह समझने की कोशिश करें कि छवि किस बारे में है और जितना संभव हो उतना जानकारी याद रखें।",
      inputPlaceholder: "यहां उत्तर दर्ज करें",
      aiQuestionInputPlaceholder: "यहां उत्तर दर्ज करें या ऑडियो रिकॉर्ड करें...",
      nextButton: "अगला →",
      preferenceNextButton: "इसे चुनें",
      preferenceZoomedImageCloseButton: "बंद करें",
      multipleChoiceTip: "आप कई विकल्प चुन सकते हैं।",
      multipleChoiceWithLimitTip: "आप अधिकतम {{count}} विकल्प चुन सकते हैं।",
      other: "अन्य",
      otherPlaceholder: "यहां उत्तर दर्ज करें",
      cardSortFinished: "आपने सभी कार्ड्स को सॉर्ट कर लिया है",
      cardSortCategories: "श्रेणियाँ",
      cardSortCards: "कार्ड्स",
      cardSortLeft: "बचे",
      cardSortNextButton: "सॉर्टिंग समाप्त करें",
      cardSortStart: "चलो शुरू करें",
      cardSortTask: (
        <>
          प्रत्येक कार्ड को उस श्रेणी में सॉर्ट करें जो आपके लिए समझ में आता है।
          <br />
          <br />
          जो स्वाभाविक रूप से आता है, वही करें, कोई सही या गलत उत्तर नहीं हैं।
        </>
      ),
      cardSort: "कार्ड सॉर्ट",
      dragCardsToCreateCategories: "श्रेणियाँ बनाने के लिए कार्ड्स को यहां खींचें",
      dragCardHereToCreateCategory: "श्रेणी बनाने के लिए कार्ड को यहां खींचें",
      enterCategoryName: "श्रेणी का नाम दर्ज करें",
      dragCardHereToAddToCategory: "श्रेणी में जोड़ने के लिए कार्ड को यहां खींचें",
      cardSortTaskTitle: "कार्य",
      cards: "कार्ड्स",
      allSorted: "आपने सभी कार्ड्स को सॉर्ट कर लिया है",
      allDone: "मैंने कर लिया",
      openSortInstuction: "प्रत्येक कार्ड को उस श्रेणी में सॉर्ट करें जो आपके लिए समझ में आता है। श्रेणियाँ बनाने के लिए कार्ड्स को पृष्ठ के दाईं ओर खींचें और छोड़ें।",
      closedSortInstuction: "प्रत्येक कार्ड को उस श्रेणी में सॉर्ट करें जो आपके लिए समझ में आता है।",
      doWhatComesNaturally: "जो स्वाभाविक रूप से आता है, वही करें, कोई सही या गलत उत्तर नहीं हैं।",
      start: "शुरू करें",
      instruction: "निर्देश",
      task: "कार्य",
      categoryNameRequired: "श्रेणी का नाम दर्ज करें"
    },
    es: {
      recordAudioTooltip: "Haga clic para grabar respuesta de voz",
      makeSureToViewAllImagesBeforeChoosingOne: "Asegúrese de ver todas las imágenes antes de elegir una",
      tryPathway: "Prueba Pathway →",
      aboutPathway: "Esta prueba fue creada con Pathway, una herramienta para que los equipos de producto modernos obtengan rápidamente información de sus usuarios.",
      makeSureToViewAllOfTheOptions: "Asegúrese de ver todas las imágenes antes de elegir una",
      showTask: "Mostrar tarea",
      followUpQuestion: "Pregunta de seguimiento",
      fiveSecondsHeader: "Concéntrate en la imagen",
      fiveSecondsText: "Verás una imagen por un tiempo limitado. Intenta entender de qué se trata la imagen y recuerda tanta información como puedas.",
      inputPlaceholder: "Ingrese respuesta aquí",
      aiQuestionInputPlaceholder: "Ingrese respuesta aquí o grabe audio...",
      nextButton: "Siguiente →",
      preferenceNextButton: "Elige este",
      preferenceZoomedImageCloseButton: "Cerrar",
      multipleChoiceTip: "Puede seleccionar múltiples opciones.",
      multipleChoiceWithLimitTip: "Puede seleccionar hasta {{count}} opciones.",
      other: "Otro",
      otherPlaceholder: "Ingrese respuesta aquí",
      cardSortFinished: "Has ordenado todas las tarjetas",
      cardSortCategories: "Categorías",
      cardSortCards: "Tarjetas",
      cardSortLeft: "izquierda",
      cardSortNextButton: "Terminar de ordenar",
      cardSortStart: "Empecemos",
      cardSortTask: (
        <>
          Ordena cada tarjeta en la categoría que tenga sentido para ti.
          <br />
          <br />
          Haz lo que te parezca natural, no hay respuestas correctas o incorrectas.
        </>
      ),
      cardSort: "Ordenar tarjetas",
      dragCardsToCreateCategories: "Arrastra las tarjetas aquí para crear categorías",
      dragCardHereToCreateCategory: "Arrastra la tarjeta aquí para crear una categoría",
      enterCategoryName: "Ingrese el nombre de la categoría",
      dragCardHereToAddToCategory: "Arrastra la tarjeta aquí para agregarla a la categoría",
      cardSortTaskTitle: "Tarea",
      cards: "Tarjetas",
      allSorted: "Has ordenado todas las tarjetas",
      allDone: "He terminado",
      openSortInstuction: "Ordena cada tarjeta en la categoría que tenga sentido para ti. Arrastra y suelta tarjetas en la parte derecha de la página para crear categorías.",
      closedSortInstuction: "Ordena cada tarjeta en la categoría que tenga sentido para ti.",
      doWhatComesNaturally: "Haz lo que te parezca natural, no hay respuestas correctas o incorrectas.",
      start: "Comenzar",
      instruction: "Instrucción",
      task: "Tarea",
      categoryNameRequired: "Ingrese el nombre de la categoría"
    },
    fr: {
      recordAudioTooltip: "Cliquez pour enregistrer la réponse vocale",
      makeSureToViewAllImagesBeforeChoosingOne: "Assurez-vous de voir toutes les images avant d'en choisir une",
      tryPathway: "Essayez Pathway →",
      aboutPathway: "Ce test a été créé avec Pathway, un outil pour que les équipes produit modernes obtiennent rapidement des informations de leurs utilisateurs.",
      makeSureToViewAllOfTheOptions: "Assurez-vous de voir toutes les images avant d'en choisir une",
      showTask: "Afficher la tâche",
      followUpQuestion: "Question de suivi",
      fiveSecondsHeader: "Concentrez-vous sur l'image",
      fiveSecondsText: "Vous verrez une image pendant un temps limité. Essayez de comprendre de quoi parle l'image et souvenez-vous de autant d'informations que possible.",
      inputPlaceholder: "Entrez la réponse ici",
      aiQuestionInputPlaceholder: "Entrez la réponse ici ou enregistrez l'audio...",
      nextButton: "Suivant →",
      preferenceNextButton: "Choisissez celui-ci",
      preferenceZoomedImageCloseButton: "Fermer",
      multipleChoiceTip: "Vous pouvez sélectionner plusieurs options.",
      multipleChoiceWithLimitTip: "Vous pouvez sélectionner jusqu'à {{count}} options.",
      other: "Autre",
      otherPlaceholder: "Entrez la réponse ici",
      cardSortFinished: "Vous avez trié toutes les cartes",
      cardSortCategories: "Catégories",
      cardSortCards: "Cartes",
      cardSortLeft: "gauche",
      cardSortNextButton: "Terminer le tri",
      cardSortStart: "Commençons",
      cardSortTask: (
        <>
          Triez chaque carte dans la catégorie qui a du sens pour vous.
          <br />
          <br />
          Faites ce qui vous semble naturel, il n'y a pas de bonnes ou de mauvaises réponses.
        </>
      ),
      cardSort: "Tri de cartes",
      dragCardsToCreateCategories: "Faites glisser les cartes ici pour créer des catégories",
      dragCardHereToCreateCategory: "Faites glisser la carte ici pour créer une catégorie",
      enterCategoryName: "Entrez le nom de la catégorie",
      dragCardHereToAddToCategory: "Faites glisser la carte ici pour l'ajouter à la catégorie",
      cardSortTaskTitle: "Tâche",
      cards: "Cartes",
      allSorted: "Vous avez trié toutes les cartes",
      allDone: "J'ai fini",
      openSortInstuction: "Triez chaque carte dans la catégorie qui a du sens pour vous. Faites glisser et déposez les cartes dans la partie droite de la page pour créer des catégories.",
      closedSortInstuction: "Triez chaque carte dans la catégorie qui a du sens pour vous.",
      doWhatComesNaturally: "Faites ce qui vous semble naturel, il n'y a pas de bonnes ou de mauvaises réponses.",
      start: "Commencer",
      instruction: "Instruction",
      task: "Tâche",
      categoryNameRequired: "Entrez le nom de la catégorie"
    },
    pt: {
      recordAudioTooltip: "Clique para gravar resposta de voz",
      makeSureToViewAllImagesBeforeChoosingOne: "Certifique-se de ver todas as imagens antes de escolher uma",
      tryPathway: "Experimente Pathway →",
      aboutPathway: "Este teste foi criado com Pathway, uma ferramenta para que as equipes de produto modernas obtenham rapidamente insights de seus usuários.",
      makeSureToViewAllOfTheOptions: "Certifique-se de ver todas as imagens antes de escolher uma",
      showTask: "Mostrar tarefa",
      followUpQuestion: "Pergunta de acompanhamento",
      fiveSecondsHeader: "Concentre-se na imagem",
      fiveSecondsText: "Você verá uma imagem por um tempo limitado. Tente entender do que se trata a imagem e lembre-se de tantas informações quanto possível.",
      inputPlaceholder: "Insira a resposta aqui",
      aiQuestionInputPlaceholder: "Insira a resposta aqui ou grave áudio...",
      nextButton: "Próximo →",
      preferenceNextButton: "Escolha este",
      preferenceZoomedImageCloseButton: "Fechar",
      multipleChoiceTip: "Você pode selecionar várias opções.",
      multipleChoiceWithLimitTip: "Você pode selecionar até {{count}} opções.",
      other: "Outro",
      otherPlaceholder: "Insira a resposta aqui",
      cardSortFinished: "Você classificou todas as cartas",
      cardSortCategories: "Categorias",
      cardSortCards: "Cartas",
      cardSortLeft: "esquerda",
      cardSortNextButton: "Concluir classificação",
      cardSortStart: "Vamos começar",
      cardSortTask: (
        <>
          Classifique cada carta na categoria que faz sentido para você.
          <br />
          <br />
          Faça o que parecer natural, não há respostas certas ou erradas.
        </>
      ),
      cardSort: "Classificação de cartas",
      dragCardsToCreateCategories: "Arraste as cartas aqui para criar categorias",
      dragCardHereToCreateCategory: "Arraste a carta aqui para criar uma categoria",
      enterCategoryName: "Insira o nome da categoria",
      dragCardHereToAddToCategory: "Arraste a carta aqui para adicionar à categoria",
      cardSortTaskTitle: "Tarefa",
      cards: "Cartas",
      allSorted: "Você classificou todas as cartas",
      allDone: "Terminei",
      openSortInstuction: "Classifique cada carta na categoria que faz sentido para você. Arraste e solte as cartas na parte direita da página para criar categorias.",
      closedSortInstuction: "Classifique cada carta na categoria que faz sentido para você.",
      doWhatComesNaturally: "Faça o que parecer natural, não há respostas certas ou erradas.",
      start: "Começar",
      instruction: "Instrução",
      task: "Tarefa",
      categoryNameRequired: "Insira o nome da categoria"
    },
    de: {
      recordAudioTooltip: "Klicken Sie, um die Sprachantwort aufzuzeichnen",
      makeSureToViewAllImagesBeforeChoosingOne: "Stellen Sie sicher, dass Sie alle Bilder ansehen, bevor Sie eines auswählen",
      tryPathway: "Probieren Sie Pathway aus →",
      aboutPathway: "Dieser Test wurde mit Pathway erstellt, einem Tool für moderne Produktteams, um schnell Einblicke von ihren Benutzern zu erhalten.",
      makeSureToViewAllOfTheOptions: "Stellen Sie sicher, dass Sie alle Bilder ansehen, bevor Sie eines auswählen",
      showTask: "Aufgabe anzeigen",
      followUpQuestion: "Folgefrage",
      fiveSecondsHeader: "Konzentrieren Sie sich auf das Bild",
      fiveSecondsText: "Sie werden ein Bild für eine begrenzte Zeit sehen. Versuchen Sie zu verstehen, worum es bei dem Bild geht, und merken Sie sich so viele Informationen wie möglich.",
      inputPlaceholder: "Antwort hier eingeben",
      aiQuestionInputPlaceholder: "Antwort hier eingeben oder Audio aufnehmen...",
      nextButton: "Weiter →",
      preferenceNextButton: "Wählen Sie dieses",
      preferenceZoomedImageCloseButton: "Schließen",
      multipleChoiceTip: "Sie können mehrere Optionen auswählen.",
      multipleChoiceWithLimitTip: "Sie können bis zu {{count}} Optionen auswählen.",
      other: "Andere",
      otherPlaceholder: "Antwort hier eingeben",
      cardSortFinished: "Sie haben alle Karten sortiert",
      cardSortCategories: "Kategorien",
      cardSortCards: "Karten",
      cardSortLeft: "links",
      cardSortNextButton: "Sortierung abschließen",
      cardSortStart: "Lass uns anfangen",
      cardSortTask: (
        <>
          Sortieren Sie jede Karte in die Kategorie, die für Sie sinnvoll ist.
          <br />
          <br />
          Tun Sie, was sich natürlich anfühlt, es gibt keine richtigen oder falschen Antworten.
        </>
      ),
      cardSort: "Kartensortierung",
      dragCardsToCreateCategories: "Ziehen Sie die Karten hierher, um Kategorien zu erstellen",
      dragCardHereToCreateCategory: "Ziehen Sie die Karte hierher, um eine Kategorie zu erstellen",
      enterCategoryName: "Kategoriename eingeben",
      dragCardHereToAddToCategory: "Ziehen Sie die Karte hierher, um sie zur Kategorie hinzuzufügen",
      cardSortTaskTitle: "Aufgabe",
      cards: "Karten",
      allSorted: "Sie haben alle Karten sortiert",
      allDone: "Ich bin fertig",
      openSortInstuction: "Sortieren Sie jede Karte in die Kategorie, die für Sie sinnvoll ist. Ziehen Sie die Karten auf den rechten Teil der Seite, um Kategorien zu erstellen.",
      closedSortInstuction: "Sortieren Sie jede Karte in die Kategorie, die für Sie sinnvoll ist.",
      doWhatComesNaturally: "Tun Sie, was sich natürlich anfühlt, es gibt keine richtigen oder falschen Antworten.",
      start: "Start",
      instruction: "Anleitung",
      task: "Aufgabe",
      categoryNameRequired: "Kategoriename eingeben"
    },
    jv: {
      recordAudioTooltip: "Klik kanggo ngrekam wangsulan swara",
      makeSureToViewAllImagesBeforeChoosingOne: "Priksa manawa kanggo ndeleng kabeh gambar sadurunge milih siji",
      tryPathway: "Coba Pathway →",
      aboutPathway: "Tes iki digawe karo Pathway, alat kanggo tim produk modern kanggo entuk wawasan saka pangguna kanthi cepet.",
      makeSureToViewAllOfTheOptions: "Priksa manawa kanggo ndeleng kabeh gambar sadurunge milih siji",
      showTask: "Tampilake tugas",
      followUpQuestion: "Pitakonan tindak lanjut",
      fiveSecondsHeader: "Fokus ing gambar",
      fiveSecondsText: "Sampeyan bakal ndeleng gambar kanggo wektu winates. Coba ngerti apa gambar kasebut lan elinga informasi sabisa-bisa.",
      inputPlaceholder: "Lebokake wangsulan ing kene",
      aiQuestionInputPlaceholder: "Lebokake wangsulan ing kene utawa ngrekam audio...",
      nextButton: "Sabanjure →",
      preferenceNextButton: "Pilih iki",
      preferenceZoomedImageCloseButton: "Tutup",
      multipleChoiceTip: "Sampeyan bisa milih sawetara pilihan.",
      multipleChoiceWithLimitTip: "Sampeyan bisa milih nganti {{count}} pilihan.",
      other: "Liyane",
      otherPlaceholder: "Lebokake wangsulan ing kene",
      cardSortFinished: "Sampeyan wis nyortir kabeh kertu",
      cardSortCategories: "Kategori",
      cardSortCards: "Kertu",
      cardSortLeft: "kiwa",
      cardSortNextButton: "Rampungake nyortir",
      cardSortStart: "Ayo miwiti",
      cardSortTask: (
        <>
          Sortir saben kertu menyang kategori sing masuk akal kanggo sampeyan.
          <br />
          <br />
          Tindakake apa sing alami, ora ana jawaban sing bener utawa salah.
        </>
      ),
      cardSort: "Sortir kertu",
      dragCardsToCreateCategories: "Seret kertu menyang kene kanggo nggawe kategori",
      dragCardHereToCreateCategory: "Seret kertu menyang kene kanggo nggawe kategori",
      enterCategoryName: "Lebokake jeneng kategori",
      dragCardHereToAddToCategory: "Seret kertu menyang kene kanggo nambah menyang kategori",
      cardSortTaskTitle: "Tugas",
      cards: "Kertu",
      allSorted: "Sampeyan wis nyortir kabeh kertu",
      allDone: "Aku wis rampung",
      openSortInstuction: "Sortir saben kertu menyang kategori sing masuk akal kanggo sampeyan. Seret lan selehake kertu menyang bagean tengen kaca kanggo nggawe kategori.",
      closedSortInstuction: "Sortir saben kertu menyang kategori sing masuk akal kanggo sampeyan.",
      doWhatComesNaturally: "Tindakake apa sing alami, ora ana jawaban sing bener utawa salah.",
      start: "Mulai",
      instruction: "Instruksi",
      task: "Tugas",
      categoryNameRequired: "Lebokake jeneng kategori"
    },
    tr: {
      recordAudioTooltip: "Sesli yanıt kaydetmek için tıklayın",
      makeSureToViewAllImagesBeforeChoosingOne: "Birini seçmeden önce tüm resimleri görüntülediğinizden emin olun",
      tryPathway: "Pathway'i deneyin →",
      aboutPathway: "Bu test, modern ürün ekiplerinin kullanıcılarından hızla içgörü elde etmeleri için bir araç olan Pathway ile oluşturulmuştur.",
      makeSureToViewAllOfTheOptions: "Birini seçmeden önce tüm resimleri görüntülediğinizden emin olun",
      showTask: "Görevi göster",
      followUpQuestion: "Takip sorusu",
      fiveSecondsHeader: "Resme odaklanın",
      fiveSecondsText: "Sınırlı bir süre için bir resim göreceksiniz. Resmin ne hakkında olduğunu anlamaya çalışın ve mümkün olduğunca çok bilgi hatırlayın.",
      inputPlaceholder: "Yanıtı buraya girin",
      aiQuestionInputPlaceholder: "Yanıtı buraya girin veya ses kaydedin...",
      nextButton: "Sonraki →",
      preferenceNextButton: "Bunu seç",
      preferenceZoomedImageCloseButton: "Kapat",
      multipleChoiceTip: "Birden fazla seçenek seçebilirsiniz.",
      multipleChoiceWithLimitTip: "En fazla {{count}} seçenek seçebilirsiniz.",
      other: "Diğer",
      otherPlaceholder: "Yanıtı buraya girin",
      cardSortFinished: "Tüm kartları sıraladınız",
      cardSortCategories: "Kategoriler",
      cardSortCards: "Kartlar",
      cardSortLeft: "kaldı",
      cardSortNextButton: "Sıralamayı bitir",
      cardSortStart: "Başlayalım",
      cardSortTask: (
        <>
          Her kartı sizin için anlamlı olan kategoriye sıralayın.
          <br />
          <br />
          Doğal olanı yapın, doğru veya yanlış cevaplar yoktur.
        </>
      ),
      cardSort: "Kart sıralama",
      dragCardsToCreateCategories: "Kategoriler oluşturmak için kartları buraya sürükleyin",
      dragCardHereToCreateCategory: "Kategori oluşturmak için kartı buraya sürükleyin",
      enterCategoryName: "Kategori adını girin",
      dragCardHereToAddToCategory: "Kategoriye eklemek için kartı buraya sürükleyin",
      cardSortTaskTitle: "Görev",
      cards: "Kartlar",
      allSorted: "Tüm kartları sıraladınız",
      allDone: "Bitti",
      openSortInstuction: "Her kartı sizin için anlamlı olan kategoriye sıralayın. Kategoriler oluşturmak için kartları sayfanın sağ tarafına sürükleyip bırakın.",
      closedSortInstuction: "Her kartı sizin için anlamlı olan kategoriye sıralayın.",
      doWhatComesNaturally: "Doğal olanı yapın, doğru veya yanlış cevaplar yoktur.",
      start: "Başla",
      instruction: "Talimat",
      task: "Görev",
      categoryNameRequired: "Kategori adını girin"
    },
    ar: {
      recordAudioTooltip: "انقر لتسجيل الرد الصوتي",
      makeSureToViewAllImagesBeforeChoosingOne: "تأكد من مشاهدة جميع الصور قبل اختيار واحدة",
      tryPathway: "جرب Pathway →",
      aboutPathway: "تم إنشاء هذا الاختبار باستخدام Pathway، وهي أداة للفرق الحديثة للحصول بسرعة على رؤى من مستخدميها.",
      makeSureToViewAllOfTheOptions: "تأكد من مشاهدة جميع الصور قبل اختيار واحدة",
      showTask: "عرض المهمة",
      followUpQuestion: "سؤال متابعة",
      fiveSecondsHeader: "ركز على الصورة",
      fiveSecondsText: "ستشاهد صورة لفترة محدودة. حاول فهم ما تدور حوله الصورة وتذكر أكبر قدر ممكن من المعلومات.",
      inputPlaceholder: "أدخل الرد هنا",
      aiQuestionInputPlaceholder: "أدخل الرد هنا أو سجل الصوت...",
      nextButton: "التالي →",
      preferenceNextButton: "اختر هذا",
      preferenceZoomedImageCloseButton: "إغلاق",
      multipleChoiceTip: "يمكنك اختيار خيارات متعددة.",
      multipleChoiceWithLimitTip: "يمكنك اختيار ما يصل إلى {{count}} خيارات.",
      other: "آخر",
      otherPlaceholder: "أدخل الرد هنا",
      cardSortFinished: "لقد قمت بفرز جميع البطاقات",
      cardSortCategories: "الفئات",
      cardSortCards: "البطاقات",
      cardSortLeft: "متبقي",
      cardSortNextButton: "إنهاء الفرز",
      cardSortStart: "لنبدأ",
      cardSortTask: (
        <>
          قم بفرز كل بطاقة في الفئة التي تبدو منطقية بالنسبة لك.
          <br />
          <br />
          افعل ما يبدو طبيعيًا، لا توجد إجابات صحيحة أو خاطئة.
        </>
      ),
      cardSort: "فرز البطاقات",
      dragCardsToCreateCategories: "اسحب البطاقات هنا لإنشاء فئات",
      dragCardHereToCreateCategory: "اسحب البطاقة هنا لإنشاء فئة",
      enterCategoryName: "أدخل اسم الفئة",
      dragCardHereToAddToCategory: "اسحب البطاقة هنا لإضافتها إلى الفئة",
      cardSortTaskTitle: "مهمة",
      cards: "البطاقات",
      allSorted: "لقد قمت بفرز جميع البطاقات",
      allDone: "انتهيت",
      openSortInstuction: "قم بفرز كل بطاقة في الفئة التي تبدو منطقية بالنسبة لك. اسحب البطاقات وأفلتها في الجزء الأيمن من الصفحة لإنشاء فئات.",
      closedSortInstuction: "قم بفرز كل بطاقة في الفئة التي تبدو منطقية بالنسبة لك.",
      doWhatComesNaturally: "افعل ما يبدو طبيعيًا، لا توجد إجابات صحيحة أو خاطئة.",
      start: "ابدأ",
      instruction: "تعليمات",
      task: "مهمة",
      categoryNameRequired: "أدخل اسم الفئة"
    },
    kk: {
      recordAudioTooltip: "Дауыс жауабын жазу үшін басыңыз",
      makeSureToViewAllImagesBeforeChoosingOne: "Біреуін таңдаудан бұрын барлық суреттерді көруді ұмытпаңыз",
      tryPathway: "Pathway-ді қолданып көріңіз →",
      aboutPathway: "Бұл тест Pathway көмегімен жасалған, бұл заманауи өнім топтарына пайдаланушыларынан тез арада түсініктер алуға арналған құрал.",
      makeSureToViewAllOfTheOptions: "Біреуін таңдаудан бұрын барлық суреттерді көруді ұмытпаңыз",
      showTask: "Тапсырманы көрсету",
      followUpQuestion: "Қосымша сұрақ",
      fiveSecondsHeader: "Суретке назар аударыңыз",
      fiveSecondsText: "Сіз шектеулі уақыт ішінде суретті көресіз. Сурет не туралы екенін түсінуге тырысыңыз және мүмкіндігінше көп ақпаратты есте сақтаңыз.",
      inputPlaceholder: "Жауапты осында енгізіңіз",
      aiQuestionInputPlaceholder: "Жауапты осында енгізіңіз немесе аудио жазыңыз...",
      nextButton: "Келесі →",
      preferenceNextButton: "Мұны таңдаңыз",
      preferenceZoomedImageCloseButton: "Жабу",
      multipleChoiceTip: "Сіз бірнеше нұсқаны таңдай аласыз.",
      multipleChoiceWithLimitTip: "Сіз {{count}} нұсқаға дейін таңдай аласыз.",
      other: "Басқа",
      otherPlaceholder: "Жауапты осында енгізіңіз",
      cardSortFinished: "Сіз барлық карталарды сұрыптадыңыз",
      cardSortCategories: "Санаттар",
      cardSortCards: "Карталар",
      cardSortLeft: "қалды",
      cardSortNextButton: "Сұрыптауды аяқтау",
      cardSortStart: "Бастайық",
      cardSortTask: (
        <>
          Әр картаны сізге мағыналы санатқа сұрыптаңыз.
          <br />
          <br />
          Табиғи түрде жасаңыз, дұрыс немесе қате жауаптар жоқ.
        </>
      ),
      cardSort: "Карталарды сұрыптау",
      dragCardsToCreateCategories: "Санаттар жасау үшін карталарды осында сүйреңіз",
      dragCardHereToCreateCategory: "Санат жасау үшін картаны осында сүйреңіз",
      enterCategoryName: "Санат атауын енгізіңіз",
      dragCardHereToAddToCategory: "Санатқа қосу үшін картаны осында сүйреңіз",
      cardSortTaskTitle: "Тапсырма",
      cards: "Карталар",
      allSorted: "Сіз барлық карталарды сұрыптадыңыз",
      allDone: "Мен аяқтадым",
      openSortInstuction: "Әр картаны сізге мағыналы санатқа сұрыптаңыз. Санаттар жасау үшін карталарды беттің оң жағына сүйреңіз және тастаңыз.",
      closedSortInstuction: "Әр картаны сізге мағыналы санатқа сұрыптаңыз.",
      doWhatComesNaturally: "Табиғи түрде жасаңыз, дұрыс немесе қате жауаптар жоқ.",
      start: "Бастау",
      instruction: "Нұсқаулық",
      task: "Тапсырма",
      categoryNameRequired: "Санат атауын енгізіңіз"
    },
  },
};

export function addBlockIdToUrl(url: string, blockId: string) {
  return addParamToUrl(url, 'pwBlockId', blockId);
}

export function addParamToUrl(url: string | undefined, paramName: string, paramValue: string): string | undefined {
  if (!url) {
    return url;
  }

  const urlObj = new URL(url);
  urlObj.searchParams.set(paramName, paramValue);
  return urlObj.toString();
}

export const supportLink = "https://help.pthwy.design/";
