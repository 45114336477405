import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';


export function CollapsibleContainer(props: PropsWithChildren<{ isCollapsed: boolean; minH: string | number; maxH: string | number; className?: string; }>) {
	const collapsibleRef = React.useRef<HTMLDivElement>(null);
	const style = {} as any;

	if (props.isCollapsed && typeof props.minH === 'number') {
		style.maxHeight = props.minH + 'px';
	}
	if (!props.isCollapsed && typeof props.maxH === 'number') {
		style.maxHeight = props.maxH + 'px';
	}

	const classes = {} as any;
	if (typeof props.minH === 'string') {
		classes[props.minH] = props.isCollapsed;
	}
	if (typeof props.maxH === 'string') {
		classes[props.maxH] = !props.isCollapsed;
	}


	return <div
		className={clsx(
			"collapsible overflow-hidden transition-all ease-in-out duration-300",
			props.className, classes
		)}
		style={style}
		ref={collapsibleRef}
	>
		{props.children}
	</div>;
}
