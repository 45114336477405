import React, { useContext } from "react";
import clsx from "clsx";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";

import { ScaleBlock } from "../../../models/Test";

import { getScaleRepliesList, findReplyNameById } from "../utils";
import { TestBlockReportContext } from "../ReportContext";
import ReportBlockHeader, { ReportHeaderMedia } from "../ReportBlockHeader";

interface IScaleResponse {
  selectedOption: string;
}

interface IScaleReportProps {
  responses: IScaleResponse[];
}

export default function ScaleReport(props: IScaleReportProps) {
  const { t } = useTranslation();

  const reportContext = useContext(TestBlockReportContext);
  const block = reportContext?.block as ScaleBlock;
  const { image, text, video } = block;

  const repliesList: { name: string | JSX.Element; value: string }[] =
    getScaleRepliesList(block);

  const data: any = {};
  const total = {
    totalSelectedOptions: 0,
  };

  total.totalSelectedOptions = 0;

  repliesList.forEach((reply) => {
    data[reply.value] = 0;
  });

  props.responses.forEach((response) => {
    if (!isNaN(data[response.selectedOption])) {
      data[response.selectedOption]++;
      total.totalSelectedOptions++;
    } else {
      return;
    }
  });

  // calculate average
  const average = () => {
    let sum = 0;
    let count = 0;

    Object.keys(data).forEach((key) => {
      sum += parseInt(key) * data[key];
      count += data[key];
    });
    
    return Math.round((sum / count) * 100) / 100;
  };

  return (
    <>
      <div className="scale-report__question">
        <span className="block caption">{t("Question")}</span>
        <ReportBlockHeader questionText={text} className="mb-4">
					<ReportHeaderMedia image={image} video={video} />
				</ReportBlockHeader>
      </div>
      {block.replyType === "numeric" && (
        <div>
          <span className="caption">{t("Average score")}</span>
          <div className="header3 py-2">{average()}</div>
        </div>
      )}
      <div className="scale-report__plot flex justify-between mt-8 overflow-x-auto">
        {repliesList.map((reply) => (
          <div key={reply.value} className="mx-4 flex flex-col items-center justify-center">
            <div className="bg-gray-300 rounded-full w-2 relative h-24">
              <div
                className="bg-yellow-400 w-2 rounded-full absolute bottom-0"
                style={{
                  height: `${(data[reply.value] / props.responses.length) * 100}%`,
                }}
              ></div>
            </div>
            <div className="mt-4 flex flex-col items-center">
              <div className={clsx(block.replyType === "emoji" && "text-2xl")}>
                {findReplyNameById(reply.value, repliesList)}
              </div>
              <div className="font-medium mt-2">
                {Math.round((data[reply.value] / props.responses.length) * 100) + "%"}
                <span className="font-normal text-gray-600 text-xs">&nbsp;({data[reply.value]})</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};