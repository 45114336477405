import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { IconMore, IconTag, IconTrash } from '../../../../icons';
import MessageTagContextMenu from './MessageTagContextMenu';

export interface MessageTagProps {
	className?: string,
	colorClassName?: string;
	withContextMenu?: boolean;
	onClick?: () => void;
	onDeleteClick?: () => void;
	listMode?: boolean;
}
export function MessageTag(props: PropsWithChildren<MessageTagProps>) {

	if (!!props.listMode) {
		return <div className={clsx(
			"flex flex-row gap-2 items-center font-medium",
			"group/tag",
			"flex flex-row gap-1 items-center",
			{ "bg-blue-200 hover:bg-blue-300": !props.colorClassName }
		)}
		>
				<IconTag width={20} height={20} className="fill-current text-gray-700" />
				<div className={clsx('w-3 h-3 rounded-full', props.colorClassName)} />
			<div className='message-tag__placeholder'>{props.children}</div>
		</div>
	}

	return <div className={clsx(
		"message-tag w-fit-content h-[20px] px-1 rounded-full text-sm font-normal",
		"group/tag",
		"flex flex-row gap-1 items-center",
		{ "bg-blue-200 hover:bg-blue-300": !props.colorClassName },
		props.className,
		props.colorClassName
	)}
		onClick={props.onClick}
	>
		<div className='message-tag__placeholder truncate flex-grow'>{props.children}</div>

		{!!props.withContextMenu && <div className={clsx("message-tag__contextmenu-placeholder",
			"w-0 group-hover/tag:w-auto transition-all duration-200 ease-in-out overflow-hidden",
			"cursor-pointer flex-shrink-0"
		)}>
			<IconTrash width={16} height={16} className="fill-current group-hover/tag:visible group-hover/tag:opacity-100 opacity-0 invisible" onClick={props.onDeleteClick} />
		</div>}
	</div>;
}
