import React, { useEffect, useRef, useState } from "react";
import { MediaPlayer, MediaProvider, VideoMimeType, useMediaStore, type MediaPlayerInstance } from '@vidstack/react';
import { PlyrControl, PlyrLayout, PlyrLayoutIcons } from '@vidstack/react/player/layouts/plyr';
import '@vidstack/react/player/styles/base.css';
import '@vidstack/react/player/styles/plyr/theme.css';
import clsx from 'clsx';

import { VidstackPlay, VidstackPause, VidstackMinimize, VidstackMaximize, VidstackMuted, VidstackVolume } from "../icons";
import { supportedVideoPlayerTypes } from "./TestEditor/constants";

const customIcons: any = {
  EnterFullscreen: VidstackMinimize,
  ExitFullscreen: VidstackMaximize,
  Pause: VidstackPause,
  Play: VidstackPlay,
  Muted: VidstackMuted,
  Volume: VidstackVolume,
};

interface IVideoPlayerProps {
  videoUrl: string;
  showControls?: boolean;
  autoplay?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
  loop?: boolean;
}

export default function VideoPlayer(props: IVideoPlayerProps) {
  const playerRef = useRef<MediaPlayerInstance>(null);

  const { started } = useMediaStore(playerRef);

  const [isMuted, setIsMuted] = useState(props.autoplay);
  const [videoType, setVideoType] = useState<VideoMimeType>("video/mp4");

  useEffect(() => {
    const originalVideoType = props.videoUrl.split("&contentType=")[1];

    if (supportedVideoPlayerTypes.includes(originalVideoType as VideoMimeType)) {
      setVideoType(originalVideoType as VideoMimeType);
    }
  }, [props.videoUrl]);

  return (
    <div
      className={clsx("video-player relative rounded-md group/videoplayer", props.className)}
    >
      <MediaPlayer
        aspectRatio="auto"
        className="video-player__element rounded-md"
        style={{
          ...props.style as any,
          width: 'auto',
          minWidth: '200px', // Минимальная ширина, при которой видны все контролы
          height: props.style?.maxHeight,
        }}
        playsInline
        load="eager"
        streamType="on-demand"
        ref={playerRef}
        onLoadedData={props.onLoad}
        src={{ src: props.videoUrl, type: videoType as VideoMimeType }}
        autoPlay={props.autoplay}
        loop={props.loop}
        muted={isMuted}
        onVolumeChange={(detail) => {
          if (detail.muted) {
            setIsMuted(true);
          } else {
            setIsMuted(false);
          }
        }}
      >
        <MediaProvider />
        <PlyrLayout
          icons={customIcons}
          controls={started ? [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'fullscreen',
          ] : ['play-large'] as PlyrControl[]}
        />
      </MediaPlayer>
    </div>
  );
}
