import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import lodash from "lodash";

import "../../../tailwind.generated.css";

import MatrixTable from "./MatrixReportTable";

import { IMatrixResponse } from "../../../models/Response";
import { MatrixBlock } from "../../../models/Test";

import { TestBlockReportContext } from "../ReportContext";
import ReportBlockHeader from "../ReportBlockHeader";

type Summary = Record<string, Record<string, number>>;

interface MatrixReportProps {
  responses: IMatrixResponse[];
}

export default function MatrixReport(props: MatrixReportProps) {
  const { t } = useTranslation();

  const reportContext = useContext(TestBlockReportContext);
  const block = reportContext?.block as MatrixBlock;

  const rows = [...block.rows];
  const columns = [...block.columns];

  const [summary, setSummary] = useState<Summary | undefined>();

  useEffect(() => {
    const newSummary: Summary = {};

    const rowIds = rows.map((row) => row.id);
    const columnIds = columns.map((column) => column.id);

    rows.forEach((row) => {
      columns.forEach((column) => {
        lodash.set(newSummary, [row.id, column.id], 0);
      });
    });

    props.responses.forEach((response) => {
      Object.keys(response.selectedOptions).forEach((rowId) => {
        if (!rowIds.includes(rowId)) return;
        response.selectedOptions[rowId].forEach((columnId) => {
          if (!columnIds.includes(columnId)) return;
          const currentValue = lodash.get(newSummary, [rowId, columnId]);
          lodash.set(newSummary, [rowId, columnId], currentValue + 1);
        });
      });
    });

    setSummary(newSummary);
  }, [block, props.responses]);

  return (
    <>
      <div>
        <div className="matrix-report__question">
          <span className="block caption">{t("Question")}</span>
          <ReportBlockHeader questionText={block.text} />
        </div>
        {summary && (
          <MatrixTable blockId={block.blockId} rows={rows} columns={columns} summary={summary} individual={false} />
        )}
      </div>
    </>
  );
};
