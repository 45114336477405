import axios from 'axios';
import { getAuthToken } from '../firebase';
import i18n from '../i18n/config';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';

export const authorizedClient = axios.create();

authorizedClient.interceptors.request.use(async function (config) {
	try {
		const authToken = await getAuthToken();
		config.headers['Authorization'] = `Bearer ${authToken}`;
	} catch (error) {
		console.error('Error fetching token:', error);
	}
	return config;
},
	function (error) {
		// Handle request errors
		console.error('Error making request:', error);

		return Promise.reject(i18n.t('Something went wrong. Please try again or contact support.'));
	}
);

export async function makeAuthorizedRequest(url: string, method: HttpMethod = 'GET', body?: any, signal?: AbortSignal) {
	const authToken = await getAuthToken();
	return fetch(url, {
		method,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Barrier ${authToken}`,
		},
		body: typeof (body) === 'undefined' ? undefined : JSON.stringify(body),
		signal
	});
}

export async function makeRequest(url: string, method: HttpMethod = 'GET', body?: any, signal?: AbortSignal) {
	return fetch(url, {
		method,
		headers: {
			"Content-Type": "application/json",
		},
		body: typeof (body) === 'undefined' ? undefined : JSON.stringify(body),
		signal
	});
}