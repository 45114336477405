import React from 'react';
import { BTextAccent } from '../../../Common/Text';
import { ReportChatMessage } from '../Models/ReportChatMessage';
import { MessageTag } from './MessageTag';
import clsx from 'clsx';
import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import { useTags } from '../useTags';
import { IconAI } from '../../../../icons';
import { useTranslation } from 'react-i18next';
export interface ChatMessageBaseProps { message: ReportChatMessage, className?: string, i?: number }

export function AssistantMessage(props: ChatMessageBaseProps) {

	const { t } = useTranslation();

	return (
    <div className={clsx("chat-message chat-message_mode_assistant", props.className)} id={props.message.id.toString()}>
      <div className="chat-message__content max-w-[448px] text-gray-700 flex flex-col flex-1 w-full gap-y-1">
        {/* {props.i !== 0 && (
          <div className="flex items-center gap-1">
            <IconAI className="w-4 h-4 text-blue-300 fill-current" />
            <span className="text-xs text-blue-400 font-normal">{t("Follow-up question")}</span>
          </div>
        )} */}
        {props.message.text}
      </div>
    </div>
  );
}

type UserMessageProps = ChatMessageBaseProps & {
	onTagDelete?: (tag: IMessageTag) => void,
	tempTag?: { name: string, color: string };
	canDeleteTags: boolean;
}

export function UserMessage(props: UserMessageProps) {
	const tags = useTags({ testId: '', blockId: '' });

	return <div className={clsx("chat-message chat-message_mode_user flex justify-start w-full flex-1",
		props.className)} id={props.message.id.toString()}>
			<div className='chat-message__content-wrapper flex flex-col'>
		<div className='chat-message__content text-gray-800 px-3 py-2 rounded-[20px] bg-[#F7F7F8] w-fit-content flex flex-col gap-y-1'>
			{props.message.text}
			{(!!props.message.tags?.length || !!props.tempTag) && <div className="chat-message__tags flex flex-row flex-wrap gap-1 mb-1">
			{props.message.tags?.map(tag => <MessageTag
				className='cursor-pointer'
				colorClassName={tags.getTagStyle(tag.color)}
				key={tag.id}
				withContextMenu={props.canDeleteTags}
				onDeleteClick={() => props.onTagDelete?.(tag)}>
				<span>{tag.name}</span>
			</MessageTag>)}
{/* 
			{!!props.tempTag && <MessageTag className='animate-pulse' colorClassName={tags.getTagStyle(props.tempTag.color)}>
				<span>{props.tempTag.name}</span>
			</MessageTag>} */}
		</div>}
		</div>
		</div>
	</div>;
}