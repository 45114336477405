import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button';
import Loader from '../Loader';
import { useTestsActions } from '../UserAccount/Hooks/useTestsActions';
import { AppContext } from '../../AppContext';
import { Timestamp } from 'firebase/firestore';
import lodash from "lodash";

export interface ITester {
	status: string;
	testerId: string;
	answerId: string;
	answers: ITestAnswer[];
}

export interface ITestAnswer {
	answerId?: string;
	blocks?: Record<string, any>;
	blocksNum: number;
	createdAt: Timestamp;
	deleted: boolean;
	invalid: boolean;
	testId: string;
	testOwnerId: string;
	meta?: any;
}

function BanButton({ onClick }: any) {
	return (
		<Button
			name='Ban&nbsp;🚫'
			type={'primary'}
			handler={onClick}
		/>
	)
}

function AproveButton({ onClick }: any) {
	return (
		<Button
			name='Aprove&nbsp;✨'
			type={'primary'}
			handler={onClick}
		/>
	)
}

export function Testers(props: { blockId: string, testId: string; sharingToken: string | null; }) {
	const { state } = useContext(AppContext);
	const { getTesters, areTestersFetching, banTester, approveTester } = useTestsActions({});
	const testers: ITester[] = state.testers[props.testId] || [];
	const [processedTesters, setProcessedTesters] = useState<string[]>([]);

	useEffect(() => { getTesters(props.testId) }, []);

	const ban = async (testerId: string) => {
		setProcessedTesters((testers) => {
			const newTesters = lodash.remove(testers, testerId);
			newTesters.push(testerId);
			return newTesters;
		})

		await banTester(props.testId, testerId)

		setProcessedTesters((testers) => {
			return lodash.remove(testers, testerId);
		})
	}

	const aprove = async (testerId: string) => {
		setProcessedTesters((testers) => {
			const newTesters = lodash.remove(testers, testerId);
			newTesters.push(testerId);
			return newTesters;
		})

		await approveTester(props.testId, testerId)

		setProcessedTesters((testers) => {
			return lodash.remove(testers, testerId);
		})
	}

	if (areTestersFetching) {
		return <Loader />
	}

	return <table className='table-fixed w-full'>
		<thead>
			<tr>
				<th className='p-2 w-[30px]'>#</th>
				<th className='p-2 w-[100px] overflow-x-visible break-word'>Tester ID</th>
				<th className='p-2 w-[70px]'>Status</th>
				<th className='p-2 w-[70px]'>Answer ID</th>
				<th className='p-2 w-[400px]'>Answer</th>
				<th className='p-2 w-[150px]'></th>
			</tr>
		</thead>
		<tbody>
			{testers.map((t, i) =>
				<tr key={i} className="hover:bg-blue-200">
					<td className='py-2 px-4 '>{i + 1}</td>
					<td className='py-2 px-4 overflow-x-hidden'>{t.testerId}</td>
					<td className='py-2 px-4 '>{t.status}</td>
					<td className='py-2 px-4 overflow-x-hidden'>{t.answerId}</td>
					<td className='py-2 px-4 '>{!!t.answers.length && t.answers[0].blocks && t.answers[0].blocks[props.blockId]?.textValue}</td>
					<td className='py-2 px-4 '>
						{processedTesters.includes(t.testerId) && "Loading..."}
						{!processedTesters.includes(t.testerId) && (
							<div className='flex gap-1'>
								<BanButton id={t.testerId} onClick={() => ban(t.testerId)} />
								<AproveButton id={t.testerId} onClick={() => aprove(t.testerId)} />
							</div>
						)}
					</td>
				</tr>
			)}
		</tbody>
	</table>
}
