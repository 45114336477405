import React from "react";
import { FigmaDisplayMode } from './NodeInHtmlUtils';
import { NodeView } from './NodeView';
import { IFormattedNode, IFormattedPrototype } from '../../../utils/figma';

export interface IOverlayNodeProps {
  overlay: IFormattedNode,
  parent: IFormattedNode,
  imageScale: number,
  windowHeight: number; windowWidth: number;
  horizontalCompressionCoef: number; scaleRate: number;
  onClick: any;
  prototype: IFormattedPrototype;
  overlayIndex?: number;
  showDefaultCursor?: boolean;
}

export function OverlayNode(props: IOverlayNodeProps) {

  const overlayStyle = {} as any;
  if (props.overlay.overlayBackground && props.overlay.overlayBackground.type === 'SOLID_COLOR') {
    const color = props.overlay.overlayBackground.color;
    overlayStyle.backgroundColor = `rgba(${255 * color.r.toFixed(3)}, ${255 * color.g.toFixed(3)}, ${255 * color.b.toFixed(3)}, ${color.a.toFixed(2)})`;
  }

  return <>
    <div className="overlay w-full h-full bg-transparent absolute top-0 z-[51] pointer-events-none fadein-overlay"
      style={overlayStyle}
    >
    </div>
    <NodeView
      className='fadein-overlay duration-75'
      node={props.overlay}
      parent={props.parent}
      onClick={props.onClick}
      imageScale={props.imageScale}
      horizontalCompressionCoef={props.horizontalCompressionCoef}
      windowHeight={props.windowHeight}
      scaleRate={1}
      display={FigmaDisplayMode.actualSize}
      fixed={false}
      zIndex={52 + (props.overlayIndex || 0)}
      prototype={props.prototype}
      showDefaultCursor={props.showDefaultCursor}
    />
  </>;
}
