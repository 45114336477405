import React from 'react';
import { ITestSettings } from '../../../../../models/TestEditor/ITestSettings';

interface TestBrandingContextType {
  avatarUrl: string | undefined;
  name: string | undefined;
}

const defaultBranding: TestBrandingContextType = {
  avatarUrl: undefined,
  name: undefined,
};

export const TestBrandingContext = React.createContext<TestBrandingContextType>(defaultBranding);

export const TestBrandingProvider: React.FC<{ settings: ITestSettings | undefined; children: React.ReactNode }> = ({
  settings,
  children,
}) => {
  const brandingValue: TestBrandingContextType = React.useMemo(() => ({
    avatarUrl: settings?.branding?.avatarUrl,
    name: settings?.branding?.name,
  }), [settings]);

  return <TestBrandingContext.Provider value={brandingValue}>{children}</TestBrandingContext.Provider>;
};

export const useTestBranding = () => React.useContext(TestBrandingContext);