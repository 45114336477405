import { useState } from 'react';
import useFigmaContext from '../../../../Figma/hooks/useFigmaContext';
import { IPrototypeRecordingOptions } from './useHtmlPrototypeRecording';

export default function useNativePrototypeRecording(options: IPrototypeRecordingOptions) {
	const recordingId = `${options.answerId}/${options.blockId}`;
	const figmaContext = useFigmaContext();
	const [isUploading, setIsUploading] = useState(false);

	function start() {
		figmaContext?.startRecording(recordingId);
	}

	async function finish() {
		return new Promise((resolve, reject) => {
			setIsUploading(true);
			
			const timeoutId = setTimeout(() => {
				setIsUploading(false);
				reject(new Error('video_upload_timeout'));
			}, 30 * 1000);

			figmaContext?.onRecordUploadComplete(() => {
				clearTimeout(timeoutId);
				setIsUploading(false);
				resolve(true);
			});

			figmaContext?.onRecordUploadFailed((recId: string, error: string) => {
				clearTimeout(timeoutId);
				setIsUploading(false);
				reject(error);
			});

			figmaContext?.stopRecording();
		});
	}

	function recordEvent() {

	}

	return {
		isUploading,
		start,
		finish,
		recordEvent
	}
}