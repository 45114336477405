export function useLocalStorage() {
	const prefix = "PATHWAY_";

	function setItem(key: string, item: any) {
		window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(item));
	}

	function getItem<T>(key: string) {
		const str = window.localStorage.getItem(`${prefix}${key}`);
		if (str !== null) {
			try {
				return JSON.parse(str) as T;
			} catch (error) {
				console.warn(`Error while parsing value from key ${key}, value is: \n\n ${str}`);
				return null;
			}
		}
		return str;
	}

	function removeItem(key: string) {
		window.localStorage.removeItem(`${prefix}${key}`);
	}

	return {
		getItem,
		setItem,
		removeItem
	}

}