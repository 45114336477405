import React, { ReactNode } from "react";
import { useDraggable, useDndMonitor } from "@dnd-kit/core";

interface DraggableProps {
  id: string;
  children: (
    attributes: object,
    listeners: object,
    setNodeRef: (node: HTMLElement | null) => void,
    transform: { x: number; y: number } | null,
    isDragging: boolean
  ) => ReactNode;
}

export default function Draggable({ id, children }: DraggableProps) {
  const { attributes, listeners = {}, setNodeRef, transform, isDragging } = useDraggable({ id });

  return <>{children(attributes, listeners, setNodeRef, transform, isDragging)}</>;
};