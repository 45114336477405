import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import {
  needFitToContainer, needDisplayInRealSize, FigmaDisplayMode,
  PX, fullSizeRelative, getChildren
} from './NodeInHtml/NodeInHtmlUtils';
import { WithScroll } from './WithScroll';
import { NodeView } from './NodeInHtml/NodeView';
import { IPrototypeClickEvent, IPrototypeNavigation } from '../../models/Figma/IClickEvent';
import { IFormattedNode, IFormattedPrototype, OverlayAction } from '../../utils/figma';
import usePrototypeEvents, { IOverlayInfo } from './hooks/usePrototypeEvents';
import { OverlayNode } from './NodeInHtml/OverlayNode';

export interface INodeInHtmlProps {
  parent?: any;
  node: IFormattedNode;
  prototype: IFormattedPrototype;
  onClick?: (e: IPrototypeClickEvent) => void;
  onNavigation?: (navigation: IPrototypeNavigation) => void;
  imageScale: number;
  horizontalCompressionCoef?: number;
  display: FigmaDisplayMode;
  windowHeight?: number;
  scaleRate?: number;
  fixed?: boolean;
  zIndex?: number;

  overlayActions?: OverlayAction[] | undefined;

  onResize?: (sizeParams: INodeInHtmlState) => void;

  visible?: boolean;
  showDefaultCursor?: boolean;
}

export interface INodeInHtmlState {
  windowHeight: number;
  windowWidth: number;
  /** 
   * this param is used in getClickData to send clicks correctly in case of window.Width < node.width
   */
  horizontalCompressionCoef: number;
  scaleRate: number;
}

export function NodeInHtml(props: INodeInHtmlProps) {
  const { node, imageScale, horizontalCompressionCoef } = props;
  const { fixedChildren } = getChildren(node);

  const [state, setState] = useState<INodeInHtmlState>({
    windowHeight: 0,
    windowWidth: 0,
    // this param is used in getClickData to send clicks correctly in case of window.Width < node.width
    horizontalCompressionCoef: horizontalCompressionCoef || 1,
    scaleRate: props.scaleRate ?? 1,
  });

  const layout = useRef<any>();
  const scaling = useRef<any>();
  const fixed = useRef<any>();
  const wrapper = useRef<any>();
  const overlayRef = useRef<any>();

  useEffect(() => {
    if (!!state.windowHeight && !!state.windowWidth) {
      props.onResize?.(state);
    }
  }, [layout.current, state]);

  const markFromUnderOverlay = getMarkEventFunc('fromUnderOverlay');

  const prototypeEvents = usePrototypeEvents({
    prototype: props.prototype,
    node,
    wrapper,
    fixed,
    onPrototypeClick: props.onClick,
    sizeParams: state,
    showOverlays: props.overlayActions
  })
  // to show overlays in correct order with correct zIndex in mobile safari
  const overlayLayers = prototypeEvents.overlayLayers;
  const fixedOverlays: IOverlayInfo[] = []; //overlayLayers.filter(o => o.type === 'fixed');
  const staticOverlays: IOverlayInfo[] = []; //overlayLayers.filter(o => o.type === 'manual');
  overlayLayers.forEach(o => {
    if (o.type === 'fixed') fixedOverlays.push(o);
    else if (fixedOverlays.length) fixedOverlays.push(o);
    else staticOverlays.push(o);
  });
  const isTopOverlayOnHover = !overlayLayers.length || overlayLayers[overlayLayers.length - 1].reaction.trigger?.type === 'ON_HOVER';

  // react when visible prop changes and call handleResize
  useEffect(() => {
    if (props.parent) return;
    if (!wrapper.current) return;
    const height = wrapper.current.clientHeight;
    wrapper.current.style.height = PX(height + 1);

    setTimeout(() => {
      if (wrapper.current) {
        wrapper.current.style.height = PX(height);
      }
    }, 100);
    console.log('visible changed');

  }, [props.visible]);

  useEffect(() => {
    if (props.parent) return;

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [layout.current, scaling.current, wrapper.current, fixed.current, overlayRef.current]);

  function handleResize() {
    let windowWidth = node.width;
    let windowHeight = node.height;

    if (needFitToContainer(props.display)) {
      windowWidth = layout.current?.parentNode?.clientWidth;
      windowHeight = layout.current.parentNode.clientHeight;
    }

    layout.current.style.height = PX(windowHeight);
    layout.current.style.width = PX(windowWidth);

    // used only to scale the main node
    let scaleRate = 1 / imageScale;
    let horizontalCompressionCoef = 1;
    if (windowWidth < node.width) {
      horizontalCompressionCoef = windowWidth / node.width;
      scaleRate = scaleRate * horizontalCompressionCoef;
    }
 
    setState({ windowHeight, windowWidth, horizontalCompressionCoef, scaleRate });

    scaling.current.style.position = "relative";
    scaling.current.style.transformOrigin = "0 0";
    // scaling happens only for the main node (!parent === true)
    // for the rest of nodes passing only horizontalCompressionCoef to use it inside getClickData method
    scaling.current.style.transform = `scale(${scaleRate})`;
    scaling.current.style.width = PX(windowWidth / scaleRate);
    scaling.current.style.height = PX(props.display === FigmaDisplayMode.actualSize ? (node.height / scaleRate) : (windowHeight / scaleRate));

    wrapper.current.style.position = "relative";
    wrapper.current.style.width = PX(node.width * imageScale);
    wrapper.current.style.height = PX(node.height * imageScale);
    if (overlayRef.current) {
      overlayRef.current.style.width = PX(node.width * imageScale);
      overlayRef.current.style.height = PX(node.height * imageScale);
    }

    fixed.current.style.position = "absolute";
    fixed.current.style.width = PX(node.width * imageScale);
    fixed.current.style.height = PX(Math.min(windowHeight / scaleRate, node.height * imageScale));
    // fixed.current.style.webkitTransform = 'translate3d(0,0,0)';

    if (props.display === FigmaDisplayMode.reportView && node.overflowParams) {
      wrapper.current.style.width = PX(node.overflowParams.width * imageScale);
      wrapper.current.style.height = PX(node.overflowParams.height * imageScale);
      scaling.current.style.width = PX(node.overflowParams.width * imageScale);
      scaling.current.style.height = PX(node.overflowParams.height * imageScale);
      fixed.current.style.width = PX(node.overflowParams.width * imageScale);
      fixed.current.style.height = PX(node.overflowParams.height * imageScale);
      layout.current.style.width = PX(node.overflowParams.width);
      layout.current.style.height = PX(node.overflowParams.height);
    }

    // margins for centering the node
    const marginTop = (windowHeight / scaleRate - node.height * imageScale) / 2;
    if (marginTop > 0) {
      wrapper.current.style.marginTop = PX(marginTop);
      if (overlayRef.current) overlayRef.current.style.marginTop = PX(marginTop);
      fixed.current.style.marginTop = PX(marginTop);
    }

    const marginLeft = (windowWidth / scaleRate - node.width * imageScale) / 2;
    if (marginLeft > 0) {
      wrapper.current.style.marginLeft = PX(marginLeft);
      fixed.current.style.marginLeft = PX(marginLeft);
    }
    if (!!state.windowHeight && !!state.windowWidth) {
      props.onResize?.(state);
    }
  }

  // attach on reaction handler to layout ref
  useEffect(() => {
    if (!layout.current || !wrapper.current) return;
    layout.current.addEventListener('reaction', prototypeEvents.handlePrototypeEvent);
    wrapper.current.addEventListener('reaction', markFromUnderOverlay);

    function fixSafariScrolling() {
      const height = wrapper.current.style.height;
      // wrapper.current.style.overflow = 'hidden';
      wrapper.current.style.height = 'auto';
      setTimeout(function () { if (wrapper.current) wrapper.current.style.height = height; }, 100);
    }

    fixSafariScrolling();

    return () => {
      layout.current?.removeEventListener('reaction', prototypeEvents.handlePrototypeEvent);
      wrapper.current?.removeEventListener('reaction', markFromUnderOverlay);
    }
  }, [layout.current, wrapper.current, overlayLayers]);

  function markEvent(e: any, prop: string) {
    e[prop] = true;
  }

  function getMarkEventFunc(prop: string) {
    return (e: any) => markEvent(e, prop);
  }

  if (props.parent) return <NodeView {...props} horizontalCompressionCoef={state.horizontalCompressionCoef} />;

  return (
    <div
      data-id={node.id}
      ref={layout}
      className="layout relative overflow-hidden node-in-html"
      onClick={prototypeEvents.handlePrototypeEvent as any}
    >
      <div ref={scaling} className="node-in-html__scaling-layout">
        <div ref={fixed} className="node-in-html__fixed-children">
          {fixedChildren.map((childNode: any, index) => (
            <NodeView
              key={childNode.id}
              display={FigmaDisplayMode.actualSize}
              parent={node}
              onClick={props.onClick}
              imageScale={imageScale}
              horizontalCompressionCoef={state.horizontalCompressionCoef}
              node={childNode}
              windowHeight={state.windowHeight}
              scaleRate={state.scaleRate}
              zIndex={index + 1}
              fixed={true}
              showDefaultCursor={props.showDefaultCursor}
            />
          ))}

          <div className={`overlay-placeholder w-full h-full absolute top-0 left-0 overflow-hidden 
          ${isTopOverlayOnHover ? 'pointer-events-none' : ''}
          ${fixedOverlays.length ? 'z-50' : ''}`}
            onClick={prototypeEvents.onOverlayPlaceholderClick}
          >
            {fixedOverlays.map((fo, i) => (
              <OverlayNode
                key={fo.overlay.id}
                overlay={fo.overlay}
                parent={node}
                imageScale={imageScale}
                windowHeight={state.windowHeight}
                windowWidth={state.windowWidth}
                horizontalCompressionCoef={state.horizontalCompressionCoef}
                scaleRate={state.scaleRate}
                onClick={props.onClick}
                prototype={props.prototype}
                overlayIndex={i + 1}
                showDefaultCursor={props.showDefaultCursor}
              />
            ))}
          </div>
        </div>

        {/* actual, reportView */}
        {needDisplayInRealSize(props.display) && (
          <div ref={wrapper} className="wrapper node-in-html__node-view-wrapper node-in-html__real-size">
            <NodeView
              {...props}
              horizontalCompressionCoef={state.horizontalCompressionCoef}
            />

            <div ref={overlayRef} className={`overlay-placeholder w-full h-full relative top-0 left-0 overflow-hidden
            ${isTopOverlayOnHover ? 'pointer-events-none' : ''}
            ${staticOverlays.length ? 'z-50' : ''}`}
              onClick={prototypeEvents.onOverlayPlaceholderClick}
            >
              {staticOverlays.map(mo => (
                <OverlayNode
                  key={mo.overlay.id}
                  overlay={mo.overlay}
                  parent={node}
                  imageScale={imageScale}
                  windowHeight={state.windowHeight}
                  windowWidth={state.windowWidth}
                  horizontalCompressionCoef={state.horizontalCompressionCoef}
                  scaleRate={state.scaleRate}
                  onClick={props.onClick}
                  prototype={props.prototype}
                  overlayIndex={overlayLayers.indexOf(mo) + 1}
                  showDefaultCursor={props.showDefaultCursor}
                />
              ))}
            </div>
          </div>
        )}

        {/* fitToWidth */}
        {needFitToContainer(props.display) && (
          <WithScroll data-has-scroll style={{ ...fullSizeRelative, height: scaling.current?.style.height }}>
            <div ref={wrapper}
              className="wrapper node-in-html__node-view-wrapper node-in-html__fit-to-width"
              onClick={markFromUnderOverlay}
            >
              <NodeView
                {...props}
                horizontalCompressionCoef={state.horizontalCompressionCoef}
              />

              <div ref={overlayRef} className={`overlay-placeholder w-full h-full relative top-0 left-0 overflow-hidden 
              ${isTopOverlayOnHover ? 'pointer-events-none' : ''}
              ${staticOverlays.length ? 'z-50' : ''}`} style={{ transform: 'translate3d(0,0,100px)' }}
                onClick={prototypeEvents.onOverlayPlaceholderClick}
              >

                {staticOverlays.map(mo => (
                  <OverlayNode
                    key={mo.overlay.id}
                    overlay={mo.overlay}
                    parent={node}
                    imageScale={imageScale}
                    windowHeight={state.windowHeight}
                    windowWidth={state.windowWidth}
                    horizontalCompressionCoef={state.horizontalCompressionCoef}
                    scaleRate={state.scaleRate}
                    onClick={props.onClick}
                    prototype={props.prototype}
                    overlayIndex={overlayLayers.indexOf(mo) + 1}
                    showDefaultCursor={props.showDefaultCursor}
                  />
                ))}
              </div>
            </div>
          </WithScroll>
        )}
      </div>
    </div>
  );
}

export default NodeInHtml;


