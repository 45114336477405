import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import RenderIfVisible from "react-render-if-visible";

import "../../tailwind.generated.css";

import { useSummarize } from "./hooks/useSummarize";

import Button from "../Button";
import Tooltip from "../Tooltip";
import Loader from "../Loader";

import { IconAI } from "../../icons";

import { TestBlockReportContext } from './ReportContext';
import { OpenQuestionBlock } from "../../models/Test";
import { IOpenQuestionResponse } from "../../models/Response";
import ReportBlockHeader, { ReportHeaderMedia } from "./ReportBlockHeader";

type AISummaryProps = {
	summary: string | null;
	isLoading: boolean;
	error: string | null;
};

function AISummary (props : AISummaryProps)  {
	const { t } = useTranslation();
	if (!props.summary && !props.isLoading && !props.error) {
		return null;
	}

	if (props.error) {	
		<div className="text-red-600">{t("Something went wrong. Please, try again.")}</div>;
	}

	return (
		<div className="mt-6 mb-4 rounded-md bg-blue-200 p-6">
			<div className="font-medium flex items-center gap-2">
				<IconAI className="fill-current text-gray-800" />
				{t("SummaryAi")}
			</div>	
			<div className="mt-6">
				{props.summary && props.summary.split("\n").map((line, index) => <p key={index}>{line}</p>)}
				{props.isLoading && <Loader color="blue" className="my-4" />}
			</div>
		</div>
	);
};

type OpenQuestionReportProps = {
	responses: IOpenQuestionResponse[];
};

export default function OpenQuestionReport (props: OpenQuestionReportProps) {
	const { t } = useTranslation();

	const reportContext = useContext(TestBlockReportContext);
	const block = reportContext?.block as OpenQuestionBlock;
	const { image, text, video } = block;

	const responsesForSummary = props.responses.map((response) => {
		return response.textValue;
	});

	const testBlockReportContext = useContext(TestBlockReportContext);

	const { summary, getSummary, isLoading, error } = useSummarize({
		testId: testBlockReportContext?.testId as string,
		blockId: testBlockReportContext?.block.blockId as string,
		question: text,
		responses: responsesForSummary,
	});

	return (
		<>
			<div className="open-question-report__question">
				<span className="block caption">{t("Question")}</span>
				<ReportBlockHeader questionText={text} className="mb-4">
					<ReportHeaderMedia image={image} video={video} />
				</ReportBlockHeader>
			</div>
			<Tooltip
				anchorClassName="fit-content inline-block mb-4"
				content={<div className="w-48">{t("Summarizing is only available for blocks with more than 5 responses.")}</div>}
				showOnlyIf={props.responses?.length <= 5}
			>
				<Button
					icon={<IconAI className="fill-current text-gray-800 mr-[6px]" />}
					type="secondary"
					name={
						<span className="flex items-center">
							{t("Summarize")}
							<span className="px-1 py-[2px] bg-blue-300 text-blue-800 text-xs leading-none font-medium ml-[6px] rounded-full">{t("Beta")}</span>
						</span>
					}
					handler={async () => {
						await getSummary();
					}}
					disabled={props.responses?.length <= 5}
				/>
			</Tooltip>

			<AISummary summary={summary} isLoading={isLoading} error={error} />

			<div className="relative">
				<div className="pt-4">
					{props.responses.map((response, index) => (
						<RenderIfVisible defaultHeight={64} stayRendered={true} key={`freeform-response-${index}`}>
							<div className="mb-4">
								<div className="">{response.textValue}</div>
								<div className="text-xs text-gray-600">{response.answerId}</div>
							</div>
						</RenderIfVisible>
					))}
				</div>
			</div>
		</>
	);
};