import React, { PropsWithChildren } from "react";
import { t } from "i18next";

import { IArea } from "../models";
import Tooltip from "../../Tooltip";

export default function Clickarea(props: PropsWithChildren<{
	onClick: (area: IArea) => void,
	area: IArea,
	scaleRatio: number,
}>) {
	const area = props.area;

	return <div
		onClick={() => props.onClick(area)}
		className="clickarea absolute border-2 bg-opacity-25 bg-blue-400 border-blue-600 rounded-md cursor-pointer select-none"
		style={{
			borderWidth: Math.round(2 / props.scaleRatio),
			top: area.top + 'px',
			left: area.left + 'px',
			width: area.width + 'px',
			height: area.height + 'px',
			position: "fixed",
		}}
	>
		<Tooltip
			content={<div className="flex flex-col p-2">
				<p>{`${t("Clicks")}: ${area.clicksCount}`}</p>
			{!!area.missedClicksCount && <p>{`${t("Missed")}: ${area.missedClicksCount}`}</p>}
			{!!area.respondentsCount && <p>{`${t("Respondents")}: ${area.respondentsCount} (${props.area.respondentsPercent}%)`}</p>}
			{!!area.averageTime && <p>{`${t("Average")}: ${area.averageTime} ${t("s")}`}</p>}
			{!!area.medianTime && <p>{`${t("Median")}: ${area.medianTime} ${t("s")}`}</p>}
			</div>}
		>
			<div className="clickarea__info absolute text-xs text-white rounded-md p-1 m-1 font-bold" style={{ transform: `scale(${1 / props.scaleRatio})`, transformOrigin: "0 0" }}>
				{props.children}
			</div>
		</Tooltip>
	</div>
}