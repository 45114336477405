import { useContext, useEffect, useState } from "react";
import {
  SplitGroupAPI,
  SplitGroupErrorCodes,
  SplitGroupUpdate,
} from "../../../actions/SplitGroupAPI";
import { AppContext, IActionResult } from "../../../AppContext";
import { useSendNotification } from "../../../hooks";
import { ISplitGroup, ITest } from "../../../models/Test";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18n from "../../../i18n/config";

export interface IUseSplitGroupActionsArgs {
  /**
   * ИД папки для которой подгрузится список сплит-групп
   *
   * Загрузка произойдет только если параметр указан
   */
  folderId?: string;

  /**
   * ИД сплит-группы, которая подгрузится
   *
   * Загрузка произойдет только если параметр указан
   */
  splitGroupId?: number;
}

export function useSplitGroupActions({
  folderId,
  splitGroupId,
}: IUseSplitGroupActionsArgs) {
  const { dispatch } = useContext(AppContext);
  const setNotification = useSendNotification();

  const queryClient = useQueryClient();


  const handleError = (e: Error) => {
    if (e instanceof DOMException && e.name === "AbortError") return;
    setNotification("error", "Oops, something went wrong");
  };

  const getSplitGroup = useQuery<IActionResult<ISplitGroup>, Error>({
    queryKey: ['splitGroup', splitGroupId],
    queryFn: async ({ signal }) => {
      try {
        const data = await SplitGroupAPI.getSplitGroup(splitGroupId as number, signal);
        dispatch(data);
        return data;
      } catch (error) {
        handleError(error as Error);
        throw error;
      }
    },
    enabled: !!splitGroupId,
    retry: (failureCount, error) => {
      if (error.cause === "splitgroup_not_found" || error.cause === "access_denied") {
        return false;
      }
      return true;
  },
  });

  const create = useMutation<IActionResult<ISplitGroup | any>[], Error, { projectId: string, folderId: string, name: string, testIds?: string[] }>({
    mutationKey: ['splitGroup', 'create'],
    mutationFn: (data) => SplitGroupAPI.createSplitGroup(data.projectId, data.folderId, data.name, data.testIds),
    onSuccess: (results) => {
      results.forEach(dispatch);
    },
    onError: handleError
  });

  const duplicate = useMutation<IActionResult<(ISplitGroup | Record<string, ITest>)>[], Error, { splitGroupId: number, onSuccess?: () => void }>(
    {
      mutationKey: ['splitGroup', 'duplicate'],
      mutationFn: (data) => SplitGroupAPI.duplicateSplitGroup(data.splitGroupId),
      onSuccess: (results, variables) => {
        results.forEach(result => dispatch(result));
        if (variables.onSuccess) variables.onSuccess();
      },
      onError: (error) => {
        if (error.message === SplitGroupErrorCodes.limitExceeded) {
          setNotification("error", i18n.t("Limit of tests has been reached"));
          return;
        }

        setNotification("error", i18n.t("Oops, something went wrong."));
      }
    }
  );

  /** Получить список сплит групп для текущей папки */
  const list = useQuery<IActionResult<ISplitGroup[]>, Error>({
    queryKey: ['splitGroups', folderId],
    queryFn: async ({ queryKey, signal }) => {
      const [, folderId] = queryKey;
      try {
        const data = await SplitGroupAPI.listSplitGroups(folderId as string, undefined, signal);
        dispatch(data);
        return data;
      } catch (error) {
        handleError(error as Error);
        throw error;
      }
    },
    enabled: !!folderId
  });

  const update = useMutation<
    IActionResult<ISplitGroup>,
    Error,
    { splitGroupId: number, projectId: string, update: SplitGroupUpdate }
  >({
    mutationKey: ['splitGroup', 'update'],
    mutationFn: (data) => SplitGroupAPI.updateSplitGroup(data.splitGroupId, data.projectId, data.update),
    onSuccess: (result) => {
      dispatch(result)
    },
    onError: handleError
  });

  const deleteSplitGroup = useMutation<IActionResult<number>, Error, { splitGroupId: number, name: string }>({
    mutationKey: ['splitGroup', 'delete'],
    mutationFn: (data) => SplitGroupAPI.deleteSplitGroup(data.splitGroupId),
    onSuccess: (result, data) => {
      dispatch(result);
      setNotification("warning", `Split group ${data.name} deleted.`);
      //queryClient.invalidateQueries('user');
    },
    onError: handleError
  });

  useEffect(() => {
    if (getSplitGroup.isSuccess) {
      dispatch(getSplitGroup.data);
    }
  }, [getSplitGroup.isSuccess])

  useEffect(() => {
    if (list.isSuccess) {
      dispatch(list.data);
    }
  }, [list.isSuccess])

  useEffect(() => {
    if (getSplitGroup.isError) {
      handleError(getSplitGroup.error);
    }
    if (list.isError) {
      handleError(list.error);
    }
  }, [getSplitGroup.isError, list.isError]);

  /**
   * Optionally take action in the event of a failed call
   */

  return {
    list,
    getSplitGroup,
    create,
    update,
    delete: deleteSplitGroup,
    duplicate,
  };
}
