import React, { ReactNode } from "react";
import { useDroppable } from "@dnd-kit/core";

interface DroppableProps {
  id: string;
  children: (
    setNodeRef: (node: HTMLElement | null) => void,
    isOver: boolean,
    active: any
  ) => ReactNode;
}

export default function Droppable({ id, children }: DroppableProps) {
  const { setNodeRef, isOver, active } = useDroppable({ id });

  return <>{children(setNodeRef, isOver, active)}</>;
};
