import { useQuery } from '@tanstack/react-query';
import { PrototypesAPI } from '../../../actions/PrototypesAPI';
import { INativePrototypeData } from '../../../models/Test';
import { useContext, useEffect } from 'react';
import { PrototypesEditorContext } from '../../TestEditor/contexts/PrototypesEditorContext';

export default function useNativePrototypeData(prototypeDataFileUrl?: string) {
	const { setNativePrototypes } = useContext(PrototypesEditorContext);

	const getPrototypeDataQuery = useQuery<INativePrototypeData>({
		queryKey: ['prototypeData', prototypeDataFileUrl],
		queryFn: () => PrototypesAPI.loadFromUrl(prototypeDataFileUrl!),
		enabled: !!prototypeDataFileUrl,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	useEffect(() => {
		if (prototypeDataFileUrl && getPrototypeDataQuery.data) {
			setNativePrototypes((current: any) => ({
				...current,
				[prototypeDataFileUrl]: getPrototypeDataQuery.data
			}));
		}
	}, [prototypeDataFileUrl, getPrototypeDataQuery.data, setNativePrototypes]);

	return {
		prototypeData: getPrototypeDataQuery
	}
}