import { useState, useEffect, RefObject } from 'react';

import useDrawingArea from './useDrawingArea';
import useFigmaNativeClickAreaStats from './useFigmaNativeClickAreaStats';

import { IArea } from '../../models';

export default function useClickareas(scaleRatio: number, clickmapRef: RefObject<HTMLDivElement>, screenId: string, showClickAreas: boolean, showOnlyFirstClicks: boolean, respondentsTotal: number) {
	const [areas, setAreas] = useState<IArea[]>([]);
	const {drawnAreas, setDrawnAreas, removeArea, clickAreaRef} = useDrawingArea(scaleRatio, respondentsTotal);
	const {calcClickareasStats} = useFigmaNativeClickAreaStats(scaleRatio, clickmapRef, respondentsTotal);

	useEffect(() => {
		setTimeout(() => {
			setAreas(current => calcClickareasStats([...current]));
		}, 50);
	}, [showOnlyFirstClicks]);

	useEffect(() => {
		setAreas(calcClickareasStats([...drawnAreas]));
	}, [drawnAreas]);

	useEffect(() => {
		setAreas([]);
		setDrawnAreas([]);
	}, [screenId, showClickAreas]);


	return {
		clickAreaRef,
		areas,
		removeArea,
	}
}