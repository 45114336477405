import { useState } from "react";
import { makeAuthorizedRequest } from "../../../http/Client";

interface UseSummarizeTextRepliesArgs {
	testId: string;
	blockId: string;
	question: string;
	responses: string[];
};

export const useSummarize = (options: UseSummarizeTextRepliesArgs) => {
	const [summary, setSummary] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const getSummary = async () => {
		setIsLoading(true);
		setSummary(null);
		setError(null);
		try {
			const response = await makeAuthorizedRequest(
				`/api/v1/report/${options.testId}/${options.blockId}/ai/summary`, // `/api/v1/reporting/summarizeTextReplies`,
				"POST",
				{ question: options.question, responses: options.responses }
			);

			if (!response.ok) {
				setError("UNABLE_TO_SUMMARIZE_TEXT_REPLIES");
				const errorData = await response.json();
				console.error(errorData);
				return;
			}

			const data = await response.json();
			setSummary(data.content);
		} catch (error) {
			console.error(error);
			setError("UNABLE_TO_SUMMARIZE_TEXT_REPLIES");
		}
		setIsLoading(false);
	};

	return { summary, getSummary, error, isLoading };
};
