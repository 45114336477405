import firebase from "firebase/compat";
import { Logic } from "../Logic/Logic";
import { BlockType } from "./BlockType";
import { canBeOptional } from "./Helpers";
import { IFormattedPrototype } from '../../utils/figma';

export type Block = (
  | OpenQuestionBlock
  | ChoiceBlock
  | PreferenceBlock
  | FirstClickBlock
  | FiveSecondsBlock
  | FigmaBlock
  | ContextBlock
  | CardSortBlock
  | MatrixBlock
  | ScaleBlock
  | AIBlock
) &
  BaseBlock;

export interface IOptionReply {
  id: string;
  replyValue: string;
  fixedPosition?: number;
}

export interface IPreferenceOptionReply extends IOptionReply {
  image: string;
  density?: "1x" | "2x";
}

export interface BaseBlock {
  name?: string;
  blockId: string;
  createdAt: firebase.firestore.Timestamp;
  logic?: Logic;
}

export interface IBlockWithMedia {
  image?: string;
  video?: string;
  mediaType?: MediaType;
  autoplay?: boolean;
  loop?: boolean;
}

export enum MediaType {
  image = "image",
  video = "video",
}

export type IOptionalBlock = {
  isOptional: boolean;
};

export type OpenQuestionBlock = {
  image: string;
  text: string;
  description?: string;
  type: BlockType.openquestion;
  isOptional: boolean;
  isShort: boolean;
  questionHtml: string;
} & IOptionalBlock & IBlockWithMedia & BaseBlock;

export type ChoiceBlock = {
  text: string;
  description?: string;
  image: string;
  type: BlockType.choice;
  questionHtml?: string;
  replies: IOptionReply[];
  other: boolean;
  randomized: boolean;
  isOptional: boolean;
  replyType: "multi" | "single";
  screening?: boolean;
  doNotShowZoomedImage: boolean;

  hasChoiceLimit?: boolean;
  choiceLimit?: number;
} & IOptionalBlock & BaseBlock & IBlockWithMedia;

export type PreferenceBlock = {
  text: string;
  questionHtml?: string;
  replies: IPreferenceOptionReply[];
  type: BlockType.preference;
  randomized: boolean;
  other: boolean;
  isOptional: boolean;
  replyType: "single";
  doNotShowZoomedImage: boolean;
} & IOptionalBlock;

export type FirstClickBlock = {
  text: string;
  questionHtml?: string;
  type: BlockType.firstclick;
  image: string;
  scaling: "scale-down-width" | "scale-down" | undefined;
} & BaseBlock;

export type FiveSecondsBlock = {
  image: string;
  type: BlockType.fiveseconds;
  time: number;
  text: string;
  questionHtml?: string;
  withCustomInstruction?: boolean;
  scaling: "scale-down-width" | "scale-down" | undefined;
} & BaseBlock & IBlockWithMedia;

export type FigmaBlock = {
  type: BlockType.figma;
  fileId?: string;
  text: string;
  questionHtml?: string;
  description?: string;
  nodeImages: { [key: string]: string };
  nodeNames: { [key: string]: string };
  goalNode: Array<string> | [];
  prototypeId?: string;
  prototypeData?: any;

  withVideo?: boolean;
  devicePixelRatio?: number;

  startNodeId?: string;
  flowName?: string;
  fileVersion?: any;
  prototypeLink?: string;
  prototypeDataUrl?: string;
  prototypeScreens?: IPrototypeScreen[];
  importedAt?: firebase.firestore.Timestamp;

  /** Показывать только курсор-стрелку во всем прототипе */
  showDefaultCursor?: boolean;
  scaling?: "scale-down-width" | "scale-down";
  //legacy
  /** @deprecated remove */
  nodesForHtml?: any;
} & BaseBlock;

export type AIBlock = {
  image?: string;
  type: BlockType.ai;
  instruction: string;
  text: string;
  questionHtml: string;
  isOptional: boolean;
} & BaseBlock & IBlockWithMedia;

export interface INativePrototypeData {
  nodeImages: Record<string, string>;
  prototypeScreens: IPrototypeScreen[];
  nodeNames: Record<string, string>;
}

export interface IPrototypeScreen {
  id: string;
  type: string;
  name: string;
  absoluteBoundingBox: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  absoluteRenderBounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  }
}

export type ContextBlock = {
  type: BlockType.context;
  text: string;
  description?: string;
  questionHtml?: string;
  screening?: boolean;
  image: string;
} & IOptionalBlock &
  BaseBlock;

export type CardSortBlock = {
  text: string;
  questionHtml?: string;
  cards: CardSortCard[];
  categories: CardSortCategory[];
  shuffleCards: boolean;
  shuffleCategories: boolean;
  doNotRequireToSortAll: boolean;
  withImages: boolean;
  withDescription: boolean;
  type: BlockType.cardsort;
  isOpenSort: boolean | undefined;
} & BaseBlock;

export type CardSortCategory = { id: string, value: string | undefined, isCustom?: boolean };
export type CardSortCard = { id: string, value: string, description: string | undefined, image: string | undefined | null };

export type MatrixBlock = {
  type: BlockType.matrix;
  text: string;
  description?: string;
  questionHtml?: string;
  rows: IMatrixOption[];
  columns: IMatrixOption[];
  replyType: "single" | "multi";
} & IOptionalBlock & IBlockWithMedia & BaseBlock;

export interface IMatrixOption {
  id: string;
  value: string;
}

export type ScaleBlock = {
  type: BlockType.scale;
  replyType: "numeric" | "emoji";
  text: string;
  description?: string;
  questionHtml?: string;
  from: "1" | "0";
  to: "5" | "10";
  selections: "5" | "3";
  startLabel: string;
  endLabel: string;
  isOptional: boolean;
  image: string;
} & IOptionalBlock & IBlockWithMedia & BaseBlock;

export interface IFigmaBlockWithPrototypeData extends FigmaBlock {
  prototypeData: IFormattedPrototype;
}

export function isFigmaBlock(block: Block): block is FigmaBlock & BaseBlock {
  return block.type === BlockType.figma;
}

export function isCardSortBlock(block: Block): block is CardSortBlock & BaseBlock {
  return block.type === BlockType.cardsort;
}

export function isMatrixBlock(block: Block): block is MatrixBlock & BaseBlock {
  return block.type === BlockType.matrix;
}

export function isOptionalBlock(block: Block): block is IOptionalBlock & Block {
  return canBeOptional(block.type);
}

export function blockHasText(block: Block): block is Block & { text: string } {
  return block.type !== BlockType.cardsort;
}
