import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface TranscriptionResponse {
  transcription: string;
}

const transcribeAudio = async (formData: FormData): Promise<string> => {

  try {
    const response = await axios.post<TranscriptionResponse>(
      '/api/v1/transcription',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    console.log('Transcription response:', response.data);

    return response.data.transcription;
  } catch (error) {
    console.error('Error in transcribeAudio:', error);
    throw error;
  }
};

export const useTranscription = () => {

  return useMutation({
    mutationFn: transcribeAudio,
    onError: (error) => {
      console.error('Transcription error:', error);
    }
  });
};