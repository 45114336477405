import React from "react";
import { IconShow } from "../../../../../../icons";
import { useTranslation } from "react-i18next";


export function Preview() {
  const { t } = useTranslation();
  return (
    <div className="flex w-full items-center justify-center bg-blue-200 px-2 h-10 flex-shrink-0 z-[1000]">
      <IconShow className="fill-current text-gray-800 mr-2 w-5 h-5" />
      <span className="text-xs">{t("This is a test preview, answers won't be recorded")}</span>
    </div>
  );
}
